import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';

export function CompanyItem({ attrs }) {
    const { isManagingCompany, isEditable, company } = attrs;

    const showCompany = (e, companyItem, editable) => {
        e.preventDefault();
        e.stopPropagation();
        company(companyItem);
        isEditable(editable);
        isManagingCompany(true);
    };

    const toggleCompanyStatus = (e, companyId) => {
        e.stopPropagation();
        Bus.secure('ToggleCompanyActiveStatus', companyId);
    };

    return {
        view({ attrs }) {
            const { companyItem, isActive } = attrs;
            return (
                <div key={attrs.i} class={`manage-list manage-list-item ${isActive ? 'remove' : 'restore'}`}
                    onclick={e => showCompany(e, companyItem, false)}>
                    <p>{companyItem.name}</p>
                    <p>{companyItem.organisationNumber}</p>
                    <p class="hide-sm">{companyItem.address.city}</p>
                    <p class="hide-lg">{companyItem.address.country}</p>
                    <button class={`list-button tooltip ${isActive ? 'remove' : 'restore'}`} data-tooltip={`${_`Edit`} ${companyItem.name}`}
                        onclick={e => showCompany(e, companyItem, true)} >
                        <i class="icon icon-edit" />
                    </button>
                    {Auth.isAccountType('admin') ? (
                        <button class={`list-button tooltip ${isActive ? 'remove' : 'restore'}`}
                            onclick={e => toggleCompanyStatus(e, companyItem.id)}
                            data-tooltip={isActive ? _`Deactivate` : _`Restore`}>
                            <i class={`icon icon-${isActive ? 'stop' : 'refresh'}`} />
                        </button>
                    ) : null}
                </div>
            );
        }
    };
}
