import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Table, Chart, Editor, Articles, QA, Article, Accordion, AnchortagMenu, iFrame } from './component-types';
import { Diagram as DiagramComponent } from '../diagram';
import { Table as TableComponent } from '../table';
import { TextEditor } from './text-editor';
import { IFrameComponent } from './iframe-component';
import { ArticlesComponent } from './articles-component';
import { QAComponent } from './qa-component';
import { ArticleComponent } from './article-component';
import { AccordionComponent } from './accordion-component';
import { AnchortagMenuComponent } from './anchortag-menu-component';

export function setComponent(component, index, inEditModeValue, saveComponent, elements) {
    switch (component.type) {
        case Table:
            return <TableComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} />;

        case Chart:
            return <DiagramComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} />;

        case Editor:
            return <TextEditor component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index} />;

        case Articles:
            return <ArticlesComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index} />;

        case QA:
            return <QAComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index} />;

        case Article:
            return <ArticleComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index} />;

        case Accordion:
            return <AccordionComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index} />;

        case AnchortagMenu:
            return <AnchortagMenuComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index}
                elements={elements} />;

        case iFrame:
            return <IFrameComponent component={component} inEditMode={inEditModeValue} saveComponent={saveComponent} index={index} />;

        default:
            return _`An error has occurred, please contact an administrator`;
    }
}
