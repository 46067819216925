/* eslint-disable object-property-newline */
import m from 'mithril';
import { _ } from 'common/utils/localization';
import { DisplayUserDataField } from './user-data-field';

export function DisplayExistingUser({ attrs }) {
    const { modalAction } = attrs;

    const editButton = (e) => {
        e.preventDefault();
        modalAction('Edit');
    };

    return {
        view({ attrs }) {
            const { accountTypes, account } = attrs;

            const displayDataField = [
                { label: _`Firstname`, data: account.firstName },
                { label: _`Lastname`, data: account.lastName },
                { label: _`Telephone`, data: account.telephone },
                { label: _`Email`, data: account.email },
                { label: _`Account Type`, data: accountTypes.find(type => type.id === account.accountType).name },
                { label: _`Company`, data: account.companyAccount.map((company, i) => (account.companyAccount.length !== (i + 1)
                        ? `${company.company.name}, ` : company.company.name)) }
            ];

            return (
                <div class="display-user-block">
                    <div>
                        {displayDataField.map((field, i) => <DisplayUserDataField key={i} {...field} />)}
                        <hr class="admin-validation-block"></hr>
                        <div class="manage-buttons">
                            <button class="edit-button" onclick={e => editButton(e)}>
                                <i class="icon icon-edit" />
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    };
}
