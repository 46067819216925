import m from 'mithril';

export function IFrameComponent({ attrs }) {
    return {
        view({ attrs }) {
            return (
                <iframe class="iframe-container" src="//exceed.nextlvl.se" samesite="none" secure="true"></iframe>
                // <link rel="import" href="//exceed.nextlvl.se"></link>
                // <object data="//exceed.nextlvl.se" width="600" height="400">
                //     <embed src="//exceed.nextlvl.se" width="600" height="400"> </embed>
                //     Error: Embedded data could not be displayed.
                // </object>
            );
        }
    };
}
