import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Modal } from 'common/components/modal';
import { Observable } from 'common/utils/observable';
import { cloneObject } from 'common/utils/object-utils';
import { ConfirmDeletion } from 'common/components/confirm-deletion-modal';
import { setComponent } from './set-component';
import { setComponentClasses, setComponentCardStyle, setComponentBodyStyle, borderOptions } from './extras';

export function Toolbar({ attrs }) {
    const { saveComponent } = attrs;
    const isPromptOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const inEditComponent = new Observable()
        .each(m.redraw);
    const isModalOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(value => (value === false ? inEditComponent(null) : null));
    const openModal = (component) => {
        inEditComponent(component);
        isModalOpen(true);
    };
    const saveEdit = (e, property) => {
        const tempComponent = cloneObject(inEditComponent.value);
        const value = e.target ? e.target.value : e;
        const parsedValue = parseInt(value, 10);
        tempComponent[property] = Number.isNaN(parsedValue) ? value : parsedValue;
        inEditComponent(tempComponent);
    };

    const saveChanges = () => {
        saveComponent(inEditComponent.value.backgroundColor || '', inEditComponent.value.id, 'backgroundColor');
        saveComponent(inEditComponent.value.showBorder || true, inEditComponent.value.id, 'showBorder');
        saveComponent(inEditComponent.value.roundedBorders || false, inEditComponent.value.id, 'roundedBorders');
        saveComponent(inEditComponent.value.connectingBorders || false, inEditComponent.value.id, 'connectingBorders');
        saveComponent(inEditComponent.value.borderColor || '', inEditComponent.value.id, 'borderColor');
        saveComponent(inEditComponent.value.borderSize || 0, inEditComponent.value.id, 'borderSize');
        saveComponent(inEditComponent.value.borderType || 0, inEditComponent.value.id, 'borderType');
        saveComponent(inEditComponent.value.paddingTop || 0, inEditComponent.value.id, 'paddingTop');
        saveComponent(inEditComponent.value.paddingBottom || 0, inEditComponent.value.id, 'paddingBottom');
        saveComponent(inEditComponent.value.paddingLeft || 0, inEditComponent.value.id, 'paddingLeft');
        saveComponent(inEditComponent.value.paddingRight || 0, inEditComponent.value.id, 'paddingRight');
        inEditComponent(null);
        isModalOpen(false);
    };
    return {
        view({ attrs }) {
            const { component, moveComponent, view, removeComponent, index } = attrs;
            if (!attrs.inEditMode) {
                return null;
            }
            return [
                <div key={`toolbar-${component.id}`} class="component-toolbar">
                    <div class="form-group form-group-margin">
                        <label class="form-label toolbar-title">
                            {/* {_`Handle Component`}({component.id}) */}
                            {_`Component`} #{component.id}
                        </label>
                    </div>

                    <div class="divider-vert"></div>

                    <div class="form-group form-group-margin">
                        <label class="form-label tooltip" data-tooltip={_`The amount of width that the component\noccupies out of 12 columns`}
                            for="input-col-lg">
                            {_`Columns`}
                        </label>
                        <input class="form-input input-sm form-input-small" type="number" id="input-col-lg"
                            value={component.columnLg} min={1} max={12} onchange={e => saveComponent(e, component.id, 'columnLg')} />
                    </div>

                    <div class="divider-vert"></div>

                    <button class="edit-view-button tooltip" data-tooltip={_`Move Component Left`}
                        disabled={component.order === 1} onclick={e => moveComponent(e, component.order, component.order - 1)}>
                        <i class="icon icon-arrow-left"></i>
                    </button>
                    <input class="form-input input-sm form-input-small" value={component.order} disabled />
                    <button class="edit-view-button tooltip" data-tooltip={_`Move Component Right`}
                        disabled={component.order === view.components.length} onclick={e => moveComponent(e, component.order, component.order + 1)}>
                        <i class="icon icon-arrow-right"></i>
                    </button>

                    <div class="divider-vert"></div>

                    <button class="edit-view-button tooltip" data-tooltip={_`Edit Component`} onclick={() => openModal(component)}>
                        <i class="icon icon-edit"></i>
                    </button>

                    <div class="divider-vert"></div>

                    <button class="edit-view-button tooltip" data-tooltip={_`Delete Component`} onclick={() => isPromptOpen(true)}>
                        <i class="icon icon-delete"></i>
                    </button>
                </div>,
                <Modal key={`toolbar-modal-${component.id}`} active={isModalOpen} title="Style Component">
                    {inEditComponent.value ? (
                        <div>
                            <div class="text-right">
                                <button class="btn btn-success mr-2" onclick={saveChanges}>SAVE</button>
                                <button class="btn btn-error ml-2" onclick={() => isModalOpen(false)}>CANCEL</button>
                            </div>

                            <div class="form-horizontal columns">
                                <InputFormGroup title="Background Color (Hex Code)" value={inEditComponent.value.backgroundColor}
                                    save={e => saveEdit(e.target.value, 'backgroundColor')} />

                                <ButtonFormGroup title={inEditComponent.value.showBorder ? _`Hide Borders` : _`Show Borders`} icon="icon icon-square"
                                    save={() => saveEdit(!inEditComponent.value.showBorder, 'showBorder')} />

                                <ButtonFormGroup title={inEditComponent.value.roundedBorders ? _`Straight Borders` : _`Round Borders`}
                                    icon="icon icon-rounded-square"
                                    save={() => saveEdit(!inEditComponent.value.roundedBorders, 'roundedBorders')} />

                                <ButtonFormGroup title={inEditComponent.value.connectingBorders ? _`Normal Borders` : _`Connecting Borders`}
                                    icon="icon icon-half-square"
                                    save={() => saveEdit(!inEditComponent.value.connectingBorders, 'connectingBorders')} />
                            </div>

                            <div class="form-horizontal columns">
                                <InputFormGroup title="Border Color (Hex Code)" value={inEditComponent.value.borderColor}
                                    save={e => saveEdit(e.target.value, 'borderColor')} />

                                <InputIntFormGroup title="Border Size" value={inEditComponent.value.borderSize}
                                    save={e => saveEdit(e.target.value, 'borderSize')} />

                                <SelectFormGroup title="Border Type" value={inEditComponent.value.borderType}
                                    save={e => saveEdit(e.target.value, 'borderType')} />
                            </div>

                            <div class="form-horizontal columns">
                                <InputIntFormGroup title="Padding Top (px)" value={inEditComponent.value.paddingTop}
                                    save={e => saveEdit(e.target.value, 'paddingTop')} />

                                <InputIntFormGroup title="Padding Bottom (px)" value={inEditComponent.value.paddingBottom}
                                    save={e => saveEdit(e.target.value, 'paddingBottom')} />

                                <InputIntFormGroup title="Padding Left (px)" value={inEditComponent.value.paddingLeft}
                                    save={e => saveEdit(e.target.value, 'paddingLeft')} />

                                <InputIntFormGroup title="Padding Right (px)" value={inEditComponent.value.paddingRight}
                                    save={e => saveEdit(e.target.value, 'paddingRight')} />
                            </div>

                            <div class="card card-component" style={setComponentCardStyle(inEditComponent.value)} >
                                <div class={`card-body${setComponentClasses(inEditComponent.value)}`}
                                    style={setComponentBodyStyle(inEditComponent.value, index, view)}>
                                    {setComponent(inEditComponent.value, index, false, null, null)}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Modal>,
                <ConfirmDeletion key={`toolbar-del-${component.id}`} modalIsOpen={isPromptOpen} confirmEvent={() => removeComponent(component.id)} />
            ];
        }
    };
}

function InputFormGroup() {
    return {
        view({ attrs }) {
            const { title, save, value } = attrs;
            return (
                <div class="form-group column col-6">
                    <div class="col-6 col-sm-12">
                        <label class="form-label">
                            {title}
                        </label>
                    </div>
                    <div class="col-6 col-sm-12">
                        <input class="form-input input-sm form-input-medium" value={value} type="text" oninput={save} />
                    </div>
                </div>
            );
        }
    };
}

function InputIntFormGroup() {
    return {
        view({ attrs }) {
            const { title, save, value } = attrs;
            return (
                <div class="form-group column col-6">
                    <div class="col-6 col-sm-12">
                        <label class="form-label">
                            {title}
                        </label>
                    </div>
                    <div class="col-6 col-sm-12">
                        <input class="form-input input-sm form-input-medium" value={value} type="text" oninput={save} min={0} />
                    </div>
                </div>
            );
        }
    };
}

function ButtonFormGroup() {
    return {
        view({ attrs }) {
            const { title, save, icon } = attrs;
            return (
                <div class="form-group column col-6">
                    <div class="col-6 col-sm-12">
                        <label class="form-label">
                            {title}
                        </label>
                    </div>
                    <div class="col-6 col-sm-12">
                        <button class="edit-view-button" onclick={save}>
                            <i class={icon}></i>
                        </button>
                    </div>
                </div>
            );
        }
    };
}

function SelectFormGroup() {
    return {
        view({ attrs }) {
            const { title, save, value } = attrs;
            return (
                <div class="form-group column col-6">
                    <div class="col-6 col-sm-12">
                        <label class="form-label">
                            {title}
                        </label>
                    </div>
                    <div class="col-6 col-sm-12">
                        <select class="form-select" value={value} onchange={save}>
                            {borderOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
                        </select>
                    </div>
                </div>
            );
        }
    };
}
