import m from 'mithril';
import { MultiSelect } from 'common/components/multi-select';

export function MultiOptions() {
    const getColor = (options, data) => {
        const option = options.find(option => option.option.toUpperCase() === data.toUpperCase());
        return option && option.colorType !== undefined ? option.colorType : '';
    };
    return {
        view({ attrs }) {
            const { options, data } = attrs;
            const list = data.split(', ');
            return list.map((item, index) => (
                <div key={index} class={`main-table-chip color-option option-${getColor(options, item)}`}>
                    <div class="main-table-chip transbox" >
                        <p>{item}</p>
                    </div>
                </div>
            ));
        }
    };
}

export function MultiOptionsInput({ attrs }) {
    const { save, cellId } = attrs;
    const saveCell = (e) => {
        const list = [];
        e.forEach((item) => {
            if (item.checked) {
                list.push(item.name);
            }
        });
        const string = list.join(', ');
        save(string, cellId);
    };
    return {
        view({ attrs }) {
            const { data, options, disabled } = attrs;
            const newList = data.split(', ');
            const optionList = [];
            options.forEach((item) => {
                if (newList.includes(item.option)) {
                    optionList.push({ id: item.id, name: item.option, checked: true });
                } else {
                    optionList.push({ id: item.id, name: item.option, checked: false });
                }
            });
            return (
                <MultiSelect options={optionList} save={saveCell} disabled={disabled} />
            );
        }
    };
}
