import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { Coordinator } from './coordinator';
import { MediaOwner } from './media-owner';
import { Analyst } from './analyst';
import { DataPage } from './data-page';

import './main.scss';

export function ProcessHandling() {
    const listeners = [];

    const start = new Date();
    start.setDate(start.getDate() - 15);
    console.log('start', start);
    const toolbarFilters = {
        from: new Observable(new Date(start).toISOString().split('T')[0])
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        to: new Observable(new Date().toISOString().split('T')[0])
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        search: new Observable('')
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        highPrio: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        mediumPrio: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        lowPrio: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        docType: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        webType: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        nsStatus: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        ogStatus: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
        tStatus: new Observable(true)
            .filter((value, oldValue) => value !== oldValue)
            .each(m.redraw),
    };

    const currentRole = new Observable(1)
        .map(e => (e.target ? e.target.value : e))
        .map(number => (Number.isNaN(parseInt(number, 10)) ? number : (parseInt(number, 10))))
        .filter((value, oldValue) => value !== oldValue)
        .each(m.redraw);

    const data = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const singleIssue = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    listeners.push(Bus.observe('ProcessHandlingData', data));

    return {
        oncreate() {
            Bus.secure('GetProcessHandlingData');
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            if (!data.value) {
                return <Loading class="loading loading-lg" />;
            }
            if (singleIssue.value) {
                return <DataPage data={singleIssue.value} back={() => singleIssue(null)} currentRole={currentRole.value} />;
            }
            let content = <MediaOwner data={data.value} currentRole={currentRole.value} setCurrentRole={currentRole}
                toolbarFilters={toolbarFilters} openProcess={singleIssue} />;
            if (currentRole.value === 2) {
                content = <Coordinator data={data.value} currentRole={currentRole.value} setCurrentRole={currentRole}
                    toolbarFilters={toolbarFilters} openProcess={singleIssue} />;
            }
            if (currentRole.value === 3) {
                content = <Analyst data={data.value} currentRole={currentRole.value} setCurrentRole={currentRole}
                    toolbarFilters={toolbarFilters} openProcess={singleIssue} />;
            }
            return (
                <div class="content">
                    <h4>{_`Process Handling`}</h4>
                    {content}
                </div>
            );
        }
    };
}
