import m from 'mithril';
import { isArray } from 'underscore';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';

export function QAComponent({ attrs }) {
    const listeners = [];

    const opened = new Observable(-1)
        .map((value, oldValue) => (value === oldValue ? -1 : value));

    const questionAnswers = [
        {
            id: 1,
            question: 'Hur ofta ska tillgänglighetsredogörelsen uppdateras?',
            answer: [
                'Svaret nedan kommer från tillsynsmyndigheten DIGG:',
                `Det är 6 § i Myndigheten för digital förvaltnings föreskrifter om tillgänglighet till digital
                offentlig service som reglerar vad som gäller för tillgänglighetsredogörelser. I första stycket
                framgår det vilken information som måste finnas med i redogörelsen. I andra stycket står det att
                redogörelsen ska ”spegla webbplatsens eller den mobila applikationens aktuella tillgänglighet”.`,
                `För en e-tjänst som utvecklas och levereras X gånger per år är detta ganska enkelt: då uppdaterar
                man bara tillgänglighetsredogörelsen till varje leverans.`,
                `För en redaktionell webbplats där man löpande publicerar innehåll är det inte så enkelt. Där får man
                göra en granskning och uppdatera redogörelsen med vissa intervaller. Hur ofta man behöver göra det
                beror på hur mycket man publicerar. En webbplats där man lägger ut en nyhet i månaden behöver förstås
                inte granskas lika ofta som en webbplats där 20 redaktörer publicerar flera artiklar om dagen.`,
                `Vår tumregel är att en tillgänglighetsredogörelse ska uppdateras åtminstone en gång per år. Det
                gäller alltså för webbplatser som förändras sällan. Riktigt aktiva webbplatser kanske behöver ses över
                uppemot 4 gånger per år. Det beror lite på i vilken grad publiceringsverktyget kan garantera tillgängligheten.`,
                `Om man har kända brister i tillgängligheten ska de redovisas i tillgänglighetsredogörelsen. Man får gärna
                skriva att man håller på att åtgärda dem, och även ange ett planerat datum för färdigställande. Däremot
                behöver man inte redovisa sin åtgärdsplan i detalj.`,
                `Över huvud taget rekommenderar vi att man försöker tänka användarcentrerat när man skriver sin
                tillgänglighetsredogörelse. Det är bättre att skriva ”det finns bilder som saknar textalternativ och
                därför inte går att uppfatta för den som inte kan se” än att skriva ”vi uppfyller inte WCAG 2.1 kriterium
                1.1.1 på nivå A”.`
            ]
        },
        {
            id: 2,
            question: 'Omfattas en video som jag publicerar på LU:s sociala medier av tillgänglighetslagen?',
            answer: [
                `Ja. Enligt webbriktlinjer.se ska de aktörer som omfattas av lagen “utforma sina webbplatser,
                appar och dokument som sprids via dessa, och så långt det är möjligt även innehåll som sprids
                via tredje part (exempelvis sociala medier), så att de är möjliga att uppfatta, hanterbara,
                begripliga och robusta.” Så om du lägger upp en video på sociala medier så omfattas den.`
            ]
        },
        {
            id: 3,
            question: 'Finns det någon skillnad i tillämpning av lagen på internt respektive externt riktad video/podd?',
            answer: [
                `Det finns ingen skillnad på tillämpningen när det gäller olika målgrupper. Så en öppen webbplats med
                interna målgrupper omfattas av lagkraven. När det gäller video är det bara nyproducerat (och inte direktsänt)
                material från och med den 23/9-2020 som omfattas.`
            ]
        },
    ];

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            if (questionAnswers === null || !isArray(questionAnswers)) {
                return <Loading />;
            }
            return (
                <div>
                    {questionAnswers.map(qa => [
                        <div key={qa.id} class="accordion">
                            <input id={`accordion-${qa.id}`} type="radio" name="accordion-radio" hidden
                                checked={opened.value === qa.id} onclick={() => opened(qa.id)}/>
                            <label class="accordion-header c-hand article-title" for={`accordion-${qa.id}`}>
                                <i class="icon icon-arrow-right mr-1"></i>{qa.question}
                            </label>
                            <div class="accordion-body columns">
                                <div class="article-body column col-10">{qa.answer.map((a, index) => <p key={index}>{a}</p>)}</div>
                            </div>
                        </div>
                    ])}
                </div>
            );
        }
    };
}
