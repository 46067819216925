import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Auth } from 'common/utils/authenticate';
import { Modal } from 'common/components/modal';
import { Loading } from 'common/components/loading';
import { compareObjects } from 'common/utils/object-utils';
import { ModuleList } from './module-list';
import { EditAddOrToggleStatus } from './edit-add-or-toggle-status';

import './main.scss';

export function ModuleOverview() {
    const listeners = [];

    const modalAction = new Observable(null)
        .filter((value, oldValue) => (oldValue !== value));

    const isModalOpen = new Observable(false)
        .filter((value, oldValue) => (typeof value === 'boolean' && oldValue !== value))
        .each(value => (!value ? modalAction(null) : null))
        .each(m.redraw);

    const chosenModule = new Observable({})
        .map((value, oldValue) => ({ ...oldValue, ...value }))
        .each(() => isModalOpen(true));

    const chosenView = new Observable({})
        .map((value, oldValue) => ({ ...oldValue, ...value }))
        .each(() => isModalOpen(true))
        .each(m.redraw);

    const moduleList = new Observable({})
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const filter = {
        isActive: new Observable(true).filter((value, oldValue) => typeof value === 'boolean' && value !== oldValue).each(() => filter.getModules()),
        getModules: () => Bus.secure('GetModulesFromCompanyId', Auth.currentCompanyId, filter.isActive.value)
    };

    const moduleAmount = new Observable(0);
    listeners.push(Bus.observe('ModuleAmount', moduleAmount));

    const companyId = new Observable(-1)
        .filter((value, oldValue) => (oldValue !== value))
        .each(value => Bus.secure('GetModulesFromCompanyId', value, filter.isActive.value));

    listeners.push(Bus.observe('EmptyModule', (data) => {
        modalAction('AddModule');
        chosenModule(data);
    }));

    listeners.push(Bus.observe('EmptyView', (data) => {
        chosenView(data);
    }));

    listeners.push(Bus.observe('Modules', (modules) => {
        moduleList(modules);
        Bus.secure('GetMenu', Auth.currentCompanyId);
    }));

    listeners.push(Bus.observe('RefreshModuleList', () => {
        isModalOpen(false);
        filter.getModules();
    }));

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            companyId(Auth.currentCompanyId);
            const ModalAction = {
                EditModule: `${_`Edit Module`}`,
                AddModule: `${_`Add Module`}`,
                EditView: `${_`Edit View`}`,
                AddView: `${_`Add View`}`,
            };
            if (moduleList.value === null || moduleList.value === undefined) {
                return (<Loading class="loading loading-lg" />);
            }
            return (
                <div class="content">
                    <div class="columns module-header">
                        <h4 class="column col-8">{_`Manage Modules`}</h4>
                        <div class="column col-4">
                            <label class="form-switch">
                                <input type="checkbox" checked={filter.isActive.value}
                                    onclick={() => filter.isActive(!filter.isActive.value)}
                                />
                                <i class="form-icon"></i>{filter.isActive.value ? _`Active` : _`Inactive`}
                            </label>
                            <button class="add-button tooltip" data-tooltip={_`Add New Module`}
                                onclick={() => Bus.secure('GetEmptyModule')}><i class="icon icon-plus" />
                            </button>
                        </div>
                    </div>

                    <ModuleList moduleList={moduleList.value} chosenModule={chosenModule} chosenView={chosenView}
                        modalAction={modalAction} isActive={filter.isActive} moduleAmount={moduleAmount.value} />

                    <Modal active={isModalOpen} title={ModalAction.hasOwnProperty(modalAction.value) ? ModalAction[modalAction.value] : _`Status`}>
                        <EditAddOrToggleStatus chosenModule={chosenModule} chosenView={chosenView}
                            modalAction={modalAction} isModalOpen={isModalOpen} />
                    </Modal>
                </div>
            );
        }
    };
}
