import { Options, OptionsInput } from './options';
import { FreeText, TextInput } from './free-text';
import { DateField, DateInput } from './date-field';
import { File, FileInput } from './file';
import { Link, LinkInput } from './link';
import { MultiOptions, MultiOptionsInput } from './multi-options';
import { WYSIWYG, WYSIWYGInput } from './wysiwyg';
import { Checkbox, CheckboxInput } from './checkbox';

export const TypeHash = [
    { text: 'Options', class: 'options', component: Options, input: OptionsInput },
    { text: 'Freetext', class: 'freetext', component: FreeText, input: TextInput },
    { text: 'Multi-Options', class: 'multi-options', component: MultiOptions, input: MultiOptionsInput },
    { text: 'Date', class: 'date', component: DateField, input: DateInput },
    { text: 'File', class: 'file', component: File, input: FileInput },
    { text: 'Link', class: 'link', component: Link, input: LinkInput },
    { text: 'Editor', class: 'wysiwyg', component: WYSIWYG, input: WYSIWYGInput },
    { text: 'Checkbox', class: 'checkbox', component: Checkbox, input: CheckboxInput },
];
