import m from 'mithril';
import { Quill } from 'common/components/quill';
import { negativeRandomNumber } from 'common/utils/uuid';

export function AccordionComponent({ attrs }) {
    const { component, saveComponent } = attrs;
    const listeners = [];

    const addAccordion = (e, accordions) => {
        e.preventDefault();
        accordions.push({
            id: negativeRandomNumber(),
            title: '',
            body: ''
        });
        saveComponent(accordions, component.id, 'accordions');
    };

    const saveTitle = (e, accordions, accordionId) => {
        e.preventDefault();
        accordions.find(x => x.id === accordionId).title = e.target.value;
        saveComponent(accordions, component.id, 'accordions');
    };

    const saveBody = (value, accordions, accordionId) => {
        accordions.find(x => x.id === accordionId).body = value;
        saveComponent(accordions, component.id, 'accordions');
    };

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { component, inEditMode } = attrs;
            if (!inEditMode) {
                return component.accordions.map(item => (
                    <div key={`accordion-${item.id}`} class="accordion">
                        <input type="checkbox" id={`accordion-${item.id}`} name="accordion-checkbox" hidden />
                        <label class="accordion-header h5" for={`accordion-${item.id}`}>
                            <i class="icon icon-arrow-right mr-1"></i>
                            {item.title}
                        </label>
                        <div class="accordion-body">
                            <Quill data={item.body} disabled={true} index={item.id} />
                        </div>
                    </div>
                ));
            }
            return (
                <div class="accordion-editor-body">
                    <div class="text-right">
                        <button class="btn btn-primary" onclick={e => addAccordion(e, component.accordions)}>
                            <i class="icon icon-plus"></i>
                        </button>
                    </div>
                    {
                        component.accordions.map((item, index) => (
                            <div key={item.id}>
                                <div class="form-group">
                                    <label class="form-label" for={`input-${item.id}`}>Title</label>
                                    <input class="form-input" type="text" id={`input-${item.id}`} placeholder="Title"
                                        value={item.title} oninput={e => saveTitle(e, component.accordions, item.id)} />
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Body</label>
                                    <Quill data={item.body} disabled={false} index={item.id}
                                        onchange={value => saveBody(value, component.accordions, item.id)} />
                                </div>
                                {component.accordions.length > (index + 1) ? (
                                    <div class="divider"></div>
                                ) : null}
                            </div>
                        ))
                    }
                </div>
            );
        }
    };
}
