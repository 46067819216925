import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { Observable } from 'common/utils/observable';
import { negativeRandomNumber } from 'common/utils/uuid';
import { cloneObject, compareObjects } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Modal } from 'common/components/modal';
import { ChartTypes } from './extra';
import { EditTable } from './edit-table';

export function Edit({ attrs }) {
    const { close, save } = attrs;
    const listeners = [];

    const diagram = new Observable(attrs.diagram);

    const tempDiagram = new Observable(cloneObject(diagram.value))
        .filter((value, oldValue) => !compareObjects(value, oldValue));

    const update = (e, property) => {
        let value = e.target ? e.target.value : e;
        const parsedValue = parseInt(value, 10);
        value = Number.isNaN(parsedValue) ? value : parsedValue;
        tempDiagram({ ...tempDiagram.value, ...{ [property]: value } });
    };

    const tables = new Observable([])
        .filter((value, oldValue) => !compareObjects(value, oldValue));
    listeners.push(Bus.observe('Tables', tables));

    const saveDiagram = () => {
        save(tempDiagram.value);
        close();
    };

    const cancel = () => {
        tempDiagram(cloneObject(diagram.value));
        close();
    };

    const addReference = () => {
        const references = cloneObject(tempDiagram.value.references);
        references.push({
            id: negativeRandomNumber(),
            referenceId: 0,
            type: 0
        });
        update(references, 'references');
    };

    const setDataPoints = (clonedtempDiagram) => {
        const refIdList = clonedtempDiagram.references.filter(x => x.type === 0).map(x => x.referenceId);
        const headerList = tables.value.map(x => x.headers).reduce((acc, curVal) => acc.concat(curVal), []).filter(x => refIdList.includes(x.id));

        clonedtempDiagram.dataPoints.forEach((element) => {
            element.data = 0;
        });

        headerList.forEach((header) => {
            let table = null;
            tables.value.forEach((t) => {
                if (t.headers.map(x => x.id).includes(header.id)) {
                    table = t;
                }
            });

            header.options.forEach((option) => {
                if (clonedtempDiagram.dataPoints.map(dp => dp.label.toLowerCase()).includes(option.option.toLowerCase())) {
                    clonedtempDiagram.dataPoints.find(x => x.label.toLowerCase() === option.option.toLowerCase()).data += table.cells
                        .filter(x => x.data.toLowerCase().split(', ').includes(option.option.toLowerCase()) && x.column === header.column).length;
                } else {
                    clonedtempDiagram.dataPoints.push({
                        id: negativeRandomNumber(),
                        label: option.option,
                        data: table.cells.filter(x => x.data.toLowerCase().split(', ').includes(option.option.toLowerCase())
                            && x.column === header.column).length,
                        color: option.colorType
                    });
                }
            });
        });

        clonedtempDiagram.dataPoints = clonedtempDiagram.dataPoints.filter(x => x.data !== 0);

        return clonedtempDiagram;
    };

    const updateReference = (reference) => {
        let clonedtempDiagram = cloneObject(tempDiagram.value);
        clonedtempDiagram.references.find(x => x.id === reference.id).referenceId = reference.referenceId;

        clonedtempDiagram = setDataPoints(clonedtempDiagram);
        tempDiagram(clonedtempDiagram);
    };

    const removeReference = (referenceId) => {
        let clonedtempDiagram = cloneObject(tempDiagram.value);
        clonedtempDiagram.references.splice(clonedtempDiagram.references.findIndex(ref => ref.id === referenceId), 1);

        clonedtempDiagram = setDataPoints(clonedtempDiagram);
        tempDiagram(clonedtempDiagram);
    };

    return {
        oncreate() {
            Bus.secure('GetTablesForChartFromCompany', Auth.currentCompanyId);
        },
        view({ attrs }) {
            const { isModalOpen } = attrs;
            diagram(attrs.diagram);
            return (
                <Modal class="modal-lg" contentclass="mx-2" active={isModalOpen} close={cancel} title={_`EDIT DIAGRAM`}>
                    <div>
                        <div class="form-group">
                            <label class="form-label" for="input-title">{_`Title`}</label>
                            <input class="form-input" type="text" id="input-title" placeholder="Title" value={tempDiagram.value.name}
                                oninput={e => update(e, 'name')} />
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="select-type">{_`Chart Type`}</label>
                            <select id="select-type" class="form-select" onchange={e => update(e, 'type')}>
                                {ChartTypes.map((type, index) => (index === tempDiagram.value.type
                                    ? <option key={`type-${index}`} value={index} selected>{type.chart}</option>
                                    : <option key={`type-${index}`} value={index}>{type.chart}</option>
                                ))}
                            </select>
                        </div>

                        <div class="form-group text-right">
                            <button class="btn-icon tooltip tooltip-left" data-tooltip={_`Add data`} onclick={addReference}
                                disabled={tempDiagram.value.references.length
                                    >= tables.value.map(table => table.headers).reduce((acc, curVal) => acc.concat(curVal), []).length}>
                                <i class="icon icon-plus"></i>
                            </button>
                        </div>
                    </div>

                    {tempDiagram.value.references.map((reference) => {
                        let tempTables = cloneObject(tables.value);
                        tempTables.map((table) => {
                            const referenceIds = tempDiagram.value.references.filter(x => x.id !== reference.id).map(x => x.referenceId);
                            for (let i = 0; i < table.headers.length; i++) {
                                const header = table.headers[i];
                                if (referenceIds.includes(header.id)) {
                                    table.headers.splice(i, 1);
                                }
                            }
                            return table;
                        });
                        tempTables = tempTables.filter(x => x.headers.length > 0);
                        return (
                            <EditTable key={`reference-${reference.id}`}
                                tables={tempTables}
                                reference={reference} update={updateReference}
                                removeReference={removeReference} diagram={tempDiagram} />
                        );
                    })}

                    <div class="mt-2">
                        <button class="cancel-button tooltip" data-tooltip={_`Cancel changes`} onclick={cancel}>
                            <i class="icon icon-cross" />
                        </button>
                        <button class={'save-button tooltip'} data-tooltip={_`Save changes`} onclick={saveDiagram}>
                            <i class="icon icon-check" />
                        </button>
                    </div>
                </Modal>
            );
        }
    };
}
