import m from 'mithril';
import { Auth } from 'common/utils/authenticate';

export function Message() {
    return {
        view({ attrs }) {
            const { data } = attrs;
            const sent = data.sent.split('T');
            sent[1] = sent[1].substr(0, 5);
            const read = data.messagesRead.map(mr => mr.userId).includes(Auth.id) ? 'read' : 'unread';
            return [
                <div key="sender" class={`tile-subtitle sender ${read}`}>
                    <i class="icon icon-people"></i> {data.sender.firstName} {data.sender.lastName}, {sent[1]} {sent[0]}
                </div>,
                <p key="content" class={`tile-subtitle content ${read}`}>{data.content}</p>,
            ];
        }
    };
}
