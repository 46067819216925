import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';
// eslint-disable-next-line import/no-cycle
import { Topic } from './topic';
// eslint-disable-next-line import/no-cycle
import { Thread } from './thread';

// Demo not working as intended
export function Board() {
    const listeners = [];

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { changeContent } = attrs;
            return (
                <div class="columns forum-board-block">
                    <div class="column col-12 board-header">
                        <button class="btn btn-sm btn-action" onclick={() => changeContent(Topic)}><i class="icon icon-back"></i></button>
                        <h6>Webbtillgänglighet</h6>
                    </div>
                    <div>
                        <input />
                        <button class="btn btn-primary">{_`SEARCH`}</button>
                    </div>
                    <table class="column col-12 table board-table">
                        <thead>
                            <tr>
                                <th>{_`Topic`}</th>
                                <th>{_`Answers`}</th>
                                <th>{_`Views`}</th>
                                <th>{_`Latest Message`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr onclick={() => changeContent(Thread)}>
                                <td>Skapa en bra användarupplevelse för synskadade</td>
                                <td>4</td>
                                <td>21</td>
                                <td>Jakob Olsson - 2021-12-01 14:34</td>
                            </tr>
                            <tr>
                                <td>Vilken bra utbildning!</td>
                                <td>173</td>
                                <td>1337</td>
                                <td>Elliot Månsson  - 2021-11-29 8:42</td>
                            </tr>
                            <tr>
                                <td>Tips på utvecklarfirmor?</td>
                                <td>12</td>
                                <td>53</td>
                                <td>Anette Gunnarsson - 2021-11-25 19:21</td>
                            </tr>
                            <tr>
                                <td>WCAG 3.0?</td>
                                <td>81</td>
                                <td>673</td>
                                <td>Jakob Olsson - 2021-11-25 13:42</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    };
}
