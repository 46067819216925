import m from 'mithril';
import crypto from 'crypto';

const apiKey = '3f8f34214d66b1b7ba39cb0b801e74db2c75899f';
const createToken = email => crypto.createHmac('sha256', apiKey).update(email).digest().toString('base64');

export function Tawk({ attrs }) {
    const { name, email } = attrs;
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const isTestRunning = localStorage.getItem('isTestRunning') || false;
    if (!isTestRunning && name && email && typeof window.Tawk_API.setAttributes === 'function') {
        window.Tawk_API.setAttributes({
            name,
            email,
            hash: createToken(email)
        });
    }

    return {
        view() {
            return (
                <script src="https://embed.tawk.to/6076e35ff7ce1827093a560e/1f386gbn3" />
            );
        }
    };
}
