import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';
import { negativeRandomNumber } from 'common/utils/uuid';
import { cloneObject, compareObjects } from 'common/utils/object-utils';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';
import { Card } from 'common/components/card';
import { ConditionChecker } from 'common/components/condition-checker';
import { Table, Chart, Editor, Articles, QA, Article, Accordion, AnchortagMenu, iFrame } from './component-types';
import { Toolbar } from './toolbar';
import { AddComponentToolbar } from './add-component-toolbar';
import { setComponent } from './set-component';
import { setComponentClasses, setComponentCardStyle, setComponentBodyStyle } from './extras';
import './main.scss';

export function PageBuilder({ attrs }) {
    const listeners = [];

    const inEditMode = new Observable(false)
        .filter((value, oldValue) => typeof value === 'boolean' && value !== oldValue)
        .each(value => window.inEdit = value)
        .each(m.redraw);

    const id = new Observable(attrs.id)
        .filter((value, oldValue) => value !== oldValue)
        .each(value => Bus.secure('GetView', value))
        .each(() => inEditMode(false));

    const name = new Observable(attrs.name)
        .filter((value, oldValue) => value !== oldValue);

    const hardrefresh = new Observable(false)
        .filter((value, oldValue) => value !== oldValue)
        .each(m.redraw);

    const view = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const createdTracking = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);
    const updatedTracking = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    listeners.push(Bus.observe('View', (viewData, createdData, updatedData) => {
        createdTracking(createdData);
        updatedTracking(updatedData);
        view(viewData);
        hardrefresh(true);
    }));

    listeners.push(Bus.observe('RefreshView', () => {
        Bus.secure('GetView', id.value);
        Bus.secure('GetTablesForChartFromCompany', Auth.currentCompanyId);
    }));

    const moveComponent = (e, oldPostion, newPosition) => {
        e.preventDefault();
        const tempView = cloneObject(view.value);
        tempView.components.forEach((component) => {
            if (component.order === oldPostion) {
                component.order = newPosition;
            } else if (component.order === newPosition) {
                component.order = oldPostion;
            }
        });
        tempView.components.sort((a, b) => (a.order - b.order));
        view(tempView);
    };

    const saveComponent = (e, componentId, property) => {
        const tempView = cloneObject(view.value);
        const componentIndex = tempView.components.findIndex(component => component.id === componentId);
        const tempComponent = tempView.components[componentIndex];
        const value = e.target ? e.target.value : e;
        const parsedValue = parseInt(value, 10);
        tempComponent[property] = Number.isNaN(parsedValue) ? value : parsedValue;
        tempView.components[componentIndex] = tempComponent;
        view(tempView);
    };

    const saveView = () => {
        inEditMode(false);
        Bus.secure('SaveView', view.value);
    };

    const cancelView = () => {
        inEditMode(false);
        Bus.secure('GetView', id.value);
    };

    const createComponent = (viewType) => {
        const comp = {
            id: negativeRandomNumber(),
            order: view.value.components.length + 1,
            columnLg: 12,
            columnSm: 12,
            type: viewType
        };
        switch (viewType) {
            case Table:
                comp.table = {
                    id: negativeRandomNumber(),
                    headers: [],
                    cells: []
                };
                break;
            case Chart:
                comp.chart = {
                    id: negativeRandomNumber(),
                    name: '',
                    references: [],
                    type: 0,
                    dataPoints: []
                };
                break;
            case Editor:
                comp.editor = '';
                break;
            case Accordion:
                comp.accordions = [];
                break;
            case AnchortagMenu:
                comp.anchortags = [];
                break;
            default:
                break;
        }
        const tempView = cloneObject(view.value);
        tempView.components.push(comp);
        tempView.components.sort((a, b) => (a.order - b.order));
        view(tempView);
    };

    const removeComponent = (componentId) => {
        const tempView = cloneObject(view.value);
        const componentIndex = tempView.components.findIndex(component => component.id === componentId);
        if (componentIndex !== -1) {
            tempView.components.splice(componentIndex, 1);
            for (let index = componentIndex; index < tempView.components.length; index++) {
                tempView.components[index].order -= 1;
            }
            tempView.components.sort((a, b) => (a.order - b.order));
            view(tempView);
        }
    };

    const elements = new Observable([]);
    return {
        oncreate() {
            Bus.secure('GetView', id.value);
        },
        onupdate({ dom }) {
            if (view.value && view.value.components) {
                const arr = [];
                view.value.components.forEach((component) => {
                    const element = dom.querySelector(`#component-${component.id}`);
                    arr.push({ id: component.id, element });
                });
                elements(arr);
            }
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            id(attrs.id);
            name(attrs.name);
            if (!view.value) {
                return <Loading class="loading loading-lg" />;
            }
            if (hardrefresh.value) {
                hardrefresh(false);
                return <Loading class="loading loading-lg" />;
            }
            return (
                <div data-test="page-builder-main" class="content">

                    <div class="align-title">
                        <h2>{name.value}</h2>
                        <h4 class="align-h4">
                            <ConditionChecker check={Auth.includesAccountType(['admin', 'superuser', 'write'])}>
                                {inEditMode.value ? [
                                    <button key="save-all-changes"
                                        class="edit-view-button bottom-margin tooltip"
                                        data-tooltip={_`Save All Changes`}
                                        data-test="save-all-changes"
                                        onclick={saveView}>
                                        <i class="icon icon-check" />
                                    </button>,
                                    <button key="cancel-all-changes"
                                        class="edit-view-button bottom-margin tooltip"
                                        data-tooltip={_`Cancel All Changes`}
                                        data-test="cancel-all-changes"
                                        onclick={cancelView}>
                                        <i class="icon icon-cross" />
                                    </button>
                                ] : (
                                    <button
                                        class="edit-view-button bottom-margin tooltip"
                                        data-tooltip={_`Edit`}
                                        onclick={() => inEditMode(true)}>
                                        <i class="icon icon-edit" />
                                    </button>
                                )}
                            </ConditionChecker>
                        </h4>

                        {inEditMode.value ? (
                            <ConditionChecker check={Auth.includesAccountType(['admin', 'superuser'])}>
                                <AddComponentToolbar createComponent={createComponent} />
                            </ConditionChecker>
                        ) : null}
                    </div>

                    <div data-test="page-builder-components" class="columns">
                        {view.value.components.map((component, index) => (
                            <div key={`component-${component.id}`} id={`component-${component.id}`}
                                class={`card card-component no-padding column col-${component.columnLg}`} style={setComponentCardStyle(component)}>
                                <div class="card-header">
                                    <ConditionChecker check={Auth.includesAccountType(['admin', 'superuser'])}>
                                        <Toolbar inEditMode={inEditMode.value} component={component} view={view.value} index={index}
                                            moveComponent={moveComponent} saveComponent={saveComponent} removeComponent={removeComponent} />
                                    </ConditionChecker>
                                </div>
                                <div class={`card-body${setComponentClasses(component)}`} style={setComponentBodyStyle(component, index, view.value)}>
                                    {setComponent(component, index, inEditMode.value, saveComponent, elements)}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div class="bottom-tracking">
                        <p>
                            {_`Created`}:
                            {
                                createdTracking.value
                                    ? ` ${createdTracking.value.userName} - ${new Date(`${createdTracking.value.dateStamp.substring(0, 23)}Z`)
                                        .toLocaleString()}`
                                    : ' - '
                            }
                        </p>
                        <p>
                            {_`Updated`}:
                            {
                                updatedTracking.value
                                    ? ` ${updatedTracking.value.userName} - ${new Date(`${updatedTracking.value.dateStamp.substring(0, 23)}Z`)
                                        .toLocaleString()}`
                                    : ' - '
                            }
                        </p>
                    </div>
                </div>
            );
        }
    };
}
