import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { Countryflags } from 'common/utils/country-flags';
import { _ } from 'common/utils/localization';
import { Bus } from 'common/utils/bus';

export function ChangeLanguage() {
    const flag = new Observable(Countryflags[_.locale])
        .filter((value, Oldvalue) => Countryflags[value] === Oldvalue)
        .map(lang => (lang === 'sv' ? 'en' : 'sv'))
        .map(lang => Countryflags[lang])
        .each(() => Bus.emitLocal('ChangeLanguage'));
    return {
        view({ attrs }) {
            flag(attrs.flag ? attrs.flag : _.locale);
            return (
                <button class="btn btn-link btn-flag menu-button" onclick={() => (_.locale === 'sv' ? _.register('en') : _.register('sv'))}>
                    {flag.value}
                </button>
            );
        }
    };
}
