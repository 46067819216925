import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';
import { Topic } from './topic';

import './main.scss';

// Demo not working as intended
export function Forum() {
    const listeners = [];

    const content = new Observable(Topic);
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <div class="content">
                    <h4>{_`Forum`}</h4>
                    <content.value changeContent={content} />
                </div>
            );
        }
    };
}

export function Board() {
    const listeners = [];

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <div class="content">
                    <h4>{_`Forum`}</h4>

                    <div class="columns forum-topic-block">
                        <Card class="column col-12">
                            <div slot="header"><h6>Webbtillgänglighet</h6></div>
                            <div slot="content">
                                <p class="body-text">Aktiva Trådar: 25</p>
                                <p class="body-text">Inlägg senaste månaden: 372</p>
                                <p class="body-text last">Senaste Tråd: Skapa en bra användarupplevelse för synskadade</p>
                                <div class="footer-text">
                                    Senaste inlägg: 2021-12-01 14:34
                                </div>
                            </div>
                        </Card>
                        <Card class="column col-12">
                            <div slot="header"><h6>Go Sustainable</h6></div>
                            <div slot="content">
                                <p class="body-text">Aktiva Trådar: 42</p>
                                <p class="body-text">Inlägg senaste månaden: 631</p>
                                <p class="body-text last">Senaste Tråd: Att arbeta hållbart på en lokal nivå</p>
                                <div class="footer-text">
                                    Senaste inlägg: 2021-12-01 11:21
                                </div>
                            </div>
                        </Card>
                        <Card class="column col-12">
                            <div slot="header"><h6>Ductum</h6></div>
                            <div slot="content">
                                <p class="body-text">Aktiva Trådar: 4</p>
                                <p class="body-text">Inlägg senaste månaden: 42</p>
                                <p class="body-text last">Senaste Tråd: Förbättring's förslag till Ductum</p>
                                <div class="footer-text">
                                    Senaste inlägg: 2021-11-29 10:02
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            );
        }
    };
}
