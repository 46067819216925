import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { _ } from 'common/utils/localization';
import { EditDataField, SetViewType } from './data-field';

import './main.scss';

const contentChooser = (key, chosenModule, chosenView) => {
    switch (key) {
        case 'AddModule':
        case 'EditModule':
            return <EditDataField label={_`Module Name*`} property='name' data={chosenModule.value.name} required={true} save={chosenModule} />;
        case 'AddView':
            return [
                <EditDataField key="name" label={_`View name*`} property='name' data={chosenView.value.name} required={true} save={chosenView} />,
                <SetViewType key="type" label={_`View Type`} property='viewType' data={chosenView.value.viewType} save={chosenView} />
            ];
        case 'EditView':
            return <EditDataField label={_`View name*`} property='name' data={chosenView.value.name} required={true} save={chosenView} />;
        case 'StatusModule':
            return (
                <p>
                    {_`Do you want to`} {chosenModule.value.active ? _`deactivate` : _`restore`} {_` the following module:`}
                    {chosenModule.value.name}?
                </p>
            );
        case 'LockModule':
            return (
                <p>
                    {_`Do you want to`} {chosenModule.value.locked ? _`unlock` : _`lock`} {_` the following module:`}
                    {chosenModule.value.name}?
                </p>
            );
        default:
            return (
                <p>
                    {_`Do you want to`} {chosenView.value.active ? _`deactivate` : _`restore`} {_` the following view:`} {chosenView.value.name}?
                </p>
            );
    }
};

export function EditAddOrToggleStatus({ attrs }) {
    const { modalAction, chosenModule, chosenView, isModalOpen } = attrs;

    const save = (e) => {
        e.preventDefault();
        if (modalAction.value === 'AddModule') {
            Bus.secure('CreateModule', chosenModule.value, Auth.currentCompanyId);
        } else if (modalAction.value === 'EditModule') {
            Bus.secure('UpdateModule', chosenModule.value);
        } else if (modalAction.value === 'StatusModule') {
            Bus.secure('ToggleModuleStatus', chosenModule.value);
        } else if (modalAction.value === 'LockModule') {
            Bus.secure('ToggleModuleLock', chosenModule.value);
        } else if (modalAction.value === 'AddView') {
            Bus.secure('CreateView', chosenView.value, chosenModule.value.id);
        } else if (modalAction.value === 'StatusView') {
            Bus.secure('ToggleViewStatus', chosenView.value);
        } else if (modalAction.value === 'EditView') {
            Bus.secure('UpdateView', chosenView.value);
        }
    };

    const cancel = (e) => {
        e.preventDefault();
        isModalOpen(false);
    };

    return {
        view() {
            const content = contentChooser(modalAction.value, chosenModule, chosenView);
            return (
                <form onsubmit={save}>

                    {content}

                    <div class="buttons-right">
                        {modalAction.value === 'EditModule' || modalAction.value === 'EditView'
                            ? (
                                <button class="tooltip cancel-button" data-tooltip={_`Cancel`} onclick={cancel}>
                                    <i class="icon icon-cross" />
                                </button>
                            ) : null}
                        <button type="submit" class="tooltip save-button" data-tooltip={_`Save`}>
                            <i class="icon icon-check" />
                        </button>
                    </div>
                </form>
            );
        }
    };
}
