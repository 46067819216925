import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { cloneObject } from 'common/utils/object-utils';
import { ConfirmDeletion } from 'common/components/confirm-deletion-modal';
import { tableSorting } from '../extra';

export function ColumnHeader({ attrs }) {
    const { save } = attrs;

    const table = new Observable(attrs.table)
        .filter((value, oldValue) => (oldValue !== value));

    const confirmIsOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const moveColumn = (e, oldPostion, newPosition) => {
        e.preventDefault();
        const tempTable = cloneObject(table.value);
        tempTable.headers.forEach((header) => {
            if (header.column === oldPostion) {
                header.column = newPosition;
            } else if (header.column === newPosition) {
                header.column = oldPostion;
            }
        });
        tempTable.headers.sort((a, b) => ((a.column > b.column) ? 1 : -1));

        tempTable.cells.forEach((cell) => {
            if (cell.column === oldPostion) {
                cell.column = newPosition;
            } else if (cell.column === newPosition) {
                cell.column = oldPostion;
            }
        });
        tempTable.cells.sort(tableSorting);

        save(tempTable);
    };

    const deleteColumn = (column) => {
        const tempTable = cloneObject(table.value);
        for (let i = tempTable.headers.length - 1; i >= 0; i--) {
            if (tempTable.headers[i].column === column) {
                tempTable.headers.splice(i, 1);
            } else if (tempTable.headers[i].column > column) {
                tempTable.headers[i].column -= 1;
            }
        }
        tempTable.headers.sort((a, b) => ((a.column > b.column) ? 1 : -1));

        for (let i = tempTable.cells.length - 1; i >= 0; i--) {
            if (tempTable.cells[i].column === column) {
                tempTable.cells.splice(i, 1);
            } else if (tempTable.cells[i].column > column) {
                tempTable.cells[i].column -= 1;
            }
        }
        tempTable.cells.sort(tableSorting);

        save(tempTable);
    };

    return {
        view({ attrs }) {
            const { column } = attrs;
            table(attrs.table);
            const columns = table.value.cells.map(item => item.column).filter((value, index, self) => self.indexOf(value) === index);
            return [
                <button key={`move-column-left-${column}`} class='btn btn-sm' disabled={column === 0}
                    onclick={e => moveColumn(e, column, column - 1)}>
                    <i class="icon icon-arrow-left" />
                </button>,
                <button key={`remove-column-${column}`} class="btn btn-sm delete-column" onclick={() => confirmIsOpen(true)}>
                    <i class="icon icon-delete delete-column" />
                </button>,
                <button key={`move-column-right-${column}`} class='btn btn-sm' disabled={column === Math.max(...columns)}
                    onclick={e => moveColumn(e, column, column + 1)} >
                    <i class="icon icon-arrow-right" />
                </button>,
                <ConfirmDeletion key={`remove-column-modal-${column}`} modalIsOpen={confirmIsOpen}
                    confirmEvent={() => deleteColumn(column)} />
            ];
        }
    };
}
