import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { Observable } from 'common/utils/observable';
import { _ } from 'common/utils/localization';
import { Menu } from 'common/components/menu';
import { Toast } from 'common/components/toast';
import { Tawk } from 'common/components/tawk';
import { SideMenu } from 'common/components/side-menu';
import { Agora } from 'agora/index';
import { Archon } from 'archon/index';
import { Home } from 'agora/home';
import { Login } from './login';

export function App() {
    const listeners = [];
    let error = false;
    let hasCompany = true;
    listeners.push(Bus.observe('NoAccount', () => hasCompany = false));
    const loading = new Observable(false)
        .each((value, Oldvalue) => error = (value === false && Oldvalue === true))
        .each(() => m.redraw());
    listeners.push(Bus.listen('Login', () => loading(true)));
    listeners.push(Bus.observe('Logout', () => {
        Auth.logout();
        error = false;
        m.redraw();
    }));
    listeners.push(Bus.observe('LoginSuccess', (userAuth) => {
        Auth.signIn(userAuth);
        loading(false);
        m.redraw();
        Bus.secure('GetMenuByAccount');
    }));
    listeners.push(Bus.observe('LoginFailed', () => loading(false)));

    const article = new Observable(null)
        .filter((value, oldValue) => (oldValue !== value))
        .each(console.log)
        .each(() => m.redraw());
    listeners.push(Bus.listen('ShowArticle', article));

    const isSideMenuShown = new Observable(true)
        .map((e, oldBool) => {
            e.preventDefault();
            e.stopPropagation();
            return !oldBool;
        });
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            let View = (<Login error={error} loading={loading.value} />);
            let toast = null;
            let menu = null;
            let sideMenu = null;
            if (Auth.isLoggedIn) {
                if (!Auth.active) {
                    return (
                        <div class="text-center">{_`Your account has been deactivated, please contact your closest administrator`}</div>
                    );
                }
                if (!hasCompany) {
                    return (
                        <div class="text-center">
                            {_`Your account does not have an active company attached to it, please contact your closest administrator`}
                        </div>
                    );
                }
                menu = (<Menu homeComponent={Home} />);
                sideMenu = (<SideMenu />);
                toast = (<Toast />);
                View = (<Agora article={article.value} setArticle={article} />);
                if (Auth.isAccountType('admin') || Auth.isAccountType('superuser')) {
                    View = (<Archon article={article.value} setArticle={article} />);
                }
                return (
                    <div class="layout">
                        <Tawk name={Auth.name} email={Auth.email} />
                        {menu}
                        <div class="overview">
                            {isSideMenuShown.value ? (
                                <div class="side-menu-block">
                                    {sideMenu}
                                    <button class="menu-btn btn btn-icon" onclick={isSideMenuShown}>
                                        <i class="icon icon-menu"></i>
                                    </button>
                                </div>
                            ) : null}
                            {isSideMenuShown.value ? null : (
                                <div class="menu-btn-block">
                                    <button class="btn btn-icon" onclick={isSideMenuShown}>
                                        <i class="icon icon-menu"></i>
                                    </button>
                                </div>
                            )}
                            <div class="content-block">
                                <div class="main-panel-block">
                                    {toast}
                                    {Auth.companyBanner
                                        ? <img class="banner-img" src={`${window.location.href}file/downloadcompanylogofile/${Auth.companyBanner}`} />
                                        : null
                                    }
                                    {View}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div class="app-div">
                    {View}
                </div>
            );
        }
    };
}
