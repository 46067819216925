export const ConvertToBase64 = (string) => {
    const base64String = Buffer.from(string).toString('base64');
    return base64String;
};

export const TruncateString = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return `${str.slice(0, num)}...`;
};

export const Capitalize = text => text.charAt(0).toUpperCase() + text.slice(1);
