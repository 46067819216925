import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { TruncateString } from 'common/utils/string-utils';
import { TypeHash } from '../types';

export function ModalCellList({ attrs }) {
    const { cellList } = attrs;

    const saveCell = (e, id) => {
        const newList = cellList.value;
        newList.find(cell => cell.id === id).data = e.target ? e.target.value : e;
        cellList(newList);
    };
    return {
        view({ attrs }) {
            const { table, inEditMode, canAddDelete } = attrs;
            return table.headers.map(header =>
                cellList.value.filter(cell => cell.column === header.column).map((cell) => {
                    const typeInput = new Observable(inEditMode ? TypeHash[header.type].input : TypeHash[header.type].component);
                    return (
                        <div class="form-group" key={header.id}>
                            <div class={` ${inEditMode ? 'pt-2' : ''} col-2 col-sm-12`}>
                                <label data-tooltip={header.data} for={`cell-${cell.id}`}
                                    class={`form-label tooltip ${inEditMode ? 'in-modal-edit' : ''}`} >
                                    <p class="modal-cell-label">{TruncateString(header.data, 20)}</p>
                                </label>
                            </div>
                            <div class="col-10 col-sm-12">
                                <typeInput.value data={cell.data} save={saveCell} options={header.options} cellId={cell.id} tableId={table.id}
                                    disabled={header.locked && !canAddDelete} />
                            </div>
                        </div>
                    );
                }));
        }
    };
}
