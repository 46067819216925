import m from 'mithril';
import { Card } from 'common/components/card';
import { Observable } from 'common/utils/observable';
import { compareObjects, cloneObject } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Bus } from 'common/utils/bus';

export function ProfileSettings({ attrs }) {
    const { save } = attrs;
    const isEditable = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(m.redraw);

    const account = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const updateAccountSettings = (e, target, property) => {
        const value = e.target[target];
        const tempAccount = cloneObject(account.value);
        tempAccount.settings[property] = value;
        save(tempAccount);
    };

    const updateSettings = (e) => {
        isEditable(false);
        Bus.secure('UpdateAccountSettings', account.value.settings);
    };

    const cancel = (e) => {
        e.preventDefault();
        isEditable(false);
        Bus.secure('GetAccount');
    };
    return {
        view({ attrs }) {
            account(attrs.account);
            return (
                <Card class={attrs.class}>
                    <div class="profile-header">
                        <h4 class="profile-header-text">{_`Profile Settings`}</h4>
                        <div class="position-relative">
                            <div class="card-icon tooltip" data-tooltip={_`Edit`}>
                                {!isEditable.value
                                    ? <i class="icon icon-edit" onclick={() => isEditable(true)} />
                                    : null}
                            </div>
                        </div>
                    </div>

                    <form id="profile-information" class="account-property" slot="content">
                        <div class="form-group">
                            <label class="form-switch">
                                <input type="checkbox" disabled={!isEditable.value} checked={account.value.settings.newMessageEmail}
                                    onchange={e => updateAccountSettings(e, 'checked', 'newMessageEmail')} />
                                <i class="form-icon"></i> {_`Receive email notifications when I get new messages in ductum`}
                            </label>
                        </div>

                        {isEditable.value
                            ? (
                                <div class="manage-buttons">
                                    <button class="cancel-button tooltip" data-tooltip={_`Cancel`} onclick={cancel}>
                                        <i class="icon icon-cross"></i>
                                    </button>
                                    <button type="submit" class="save-button tooltip" data-tooltip={_`Save changes`} form="profile-information"
                                        onclick={updateSettings}>
                                        <i class="icon icon-check"></i>
                                    </button>
                                </div>
                            )
                            : null}
                    </form>
                </Card>
            );
        }
    };
}
