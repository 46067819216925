import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { negativeRandomNumber } from 'common/utils/uuid';
import { cloneObject } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Modal } from 'common/components/modal';
import { TypeHash } from '../types';

export function AddColumnModal({ attrs }) {
    const { save } = attrs;

    const table = new Observable(attrs.table)
        .filter((value, oldValue) => (oldValue !== value));

    const newColumnType = new Observable(null)
        .filter((value, oldValue) => (oldValue !== value));

    const modalIsOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(value => (!value ? newColumnType(null) : null));

    const addColumn = (e) => {
        e.preventDefault();

        const tempTable = cloneObject(table.value);
        tempTable.headers.push({
            id: negativeRandomNumber(),
            column: table.value.headers.length,
            locked: false,
            options: [],
            data: TypeHash[newColumnType.value].text,
            type: newColumnType.value
        });

        const rows = tempTable.cells.map(item => item.row).filter((value, index, self) => self.indexOf(value) === index);
        rows.forEach((row) => {
            tempTable.cells.push({
                id: negativeRandomNumber(),
                data: '',
                row,
                column: table.value.headers.length
            });
        });

        save(tempTable);
        modalIsOpen(false);
    };

    const cancel = () => {
        modalIsOpen(false);
    };

    return {
        view({ attrs }) {
            table(attrs.table);
            return (
                <div>
                    <button class="btn table-admin-button" onclick={() => modalIsOpen(true)}>{_`Add Column`}</button>
                    <Modal class="modal-sm" active={modalIsOpen} close={cancel}
                        title={(<div class="table-view header">{_`Choose column type`}</div>)}>
                        <div class="form-group">
                            <div class="column-options col-12 col-mx-auto columns">
                                {TypeHash.map((type, i) => (
                                    <label class="form-radio col-6" key={type.id} onclick={() => newColumnType(i)}>
                                        <input type="radio" name="type" />
                                        <i class="form-icon" /> {type.text}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div slot="footer">
                            <button class="cancel-button tooltip" data-tooltip={_`Close`} onclick={cancel} >
                                <i class="icon icon-cross" />
                            </button>
                            <button class={`save-button tooltip ${newColumnType.value === null ? 'disabled' : ''}`}
                                data-tooltip={`${newColumnType.value !== null ? _`Save & close` : _`Must choose a column type`}`}
                                onclick={addColumn} disabled={newColumnType.value === null} >
                                <i class="icon icon-check" />
                            </button>
                        </div>
                    </Modal>
                </div>
            );
        }
    };
}
