import m from 'mithril';

export const Tile = {
    view({ attrs, children }) {
        const { logger, onclick } = attrs;
        const icon = children.filter(child => child && child.attrs && child.attrs.slot === 'icon')
            .map(child => setLogger(child, logger));
        const action = children.filter(child => child && child.attrs && child.attrs.slot === 'action')
            .map(child => setLogger(child, logger));
        const content = children.filter(child => child && (!child.attrs || !['icon', 'action']
            .includes(child.attrs.slot))).map(child => setLogger(child, logger));
        return (
            <div class={`tile ${attrs.class || ''}`} data-badge={attrs['data-badge']} onclick={onclick}>
                {icon.length ? <div class="tile-icon">{icon}</div> : null}
                {content.length ? <div class="tile-content">{content}</div> : null}
                {action.length ? <div class="tile-action">{action}</div> : null}
            </div>
        );
    }
};

function setLogger(child, logger) {
    if (child.attrs) {
        child.attrs.logger = logger || child.attrs.logger;
    }
    return child;
}
