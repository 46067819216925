import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { Auth } from 'common/utils/authenticate';
import { Loading } from 'common/components/loading';
import { cloneObject, compareObjects } from 'common/utils/object-utils';
import { RowModal } from './Row/row-modal';
import { TableHeader } from './Header';
import { Row } from './Row';
import { EditHeaderModal } from './Header/edit';

import './main.scss';
import 'common/scss/utils/color-options.scss';

export function Table({ attrs }) {
    const { component, saveComponent } = attrs;
    const listeners = [];

    const selectedRow = new Observable(0);
    const selectedColumn = new Observable(0);

    const table = new Observable(component.table)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(value => saveComponent(value, component.id, 'table'))
        .each(m.redraw);

    const canAddDelete = Auth.isAccountType('admin') || Auth.isAccountType('superuser');

    const changeName = (e) => {
        const value = e.target ? e.target.value : e;
        const tempTable = cloneObject(table.value);
        tempTable.name = value;
        table(tempTable);
    };

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { inEditMode, component } = attrs;
            table(component.table);
            if (!table.value) {
                return <Loading class="loading loading-lg" />;
            }
            return (
                <div class="table-container">
                    <header class="table-view header">
                        {inEditMode ? (
                            <input class="form-input" type="text" oninput={changeName} value={table.value.name} />
                        ) : table.value.name}
                    </header>

                    <table class={`table table-scroll table-striped table-hover ${inEditMode ? 'in-edit' : ''}`}>
                        <TableHeader table={table.value} inEditMode={inEditMode} save={table}
                            selectedColumn={selectedColumn} canAddDelete={canAddDelete} />
                        <tbody>
                            <Row canAddDelete={canAddDelete} inEditMode={inEditMode} table={table.value} selectedRow={selectedRow}
                                save={table} />
                        </tbody>
                    </table>

                    <RowModal inEditMode={inEditMode} table={table.value} save={table} canAddDelete={canAddDelete}
                        selectedRow={selectedRow} />
                    <EditHeaderModal table={table.value} save={table} selectedColumn={selectedColumn} />
                </div>
            );
        }
    };
}
