import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { Tile } from 'common/components/tile';

export function Thread() {
    return {
        view({ attrs }) {
            const { data, selected } = attrs;
            const recipients = data.recipients.filter(item => item.userId !== Auth.id);
            const message = getLastMessage(data.messages);
            const icon = recipients.length > 1 ? <i class="icon">{recipients.length}</i> : <i class="icon icon-people"></i>;
            const unread = data.messages.filter(m => !m.messagesRead.map(mr => mr.userId).includes(Auth.id)).length;
            const badge = unread > 0 ? 'badge' : '';
            const active = selected === data.id ? <i slot="action" class="icon icon-arrow-right"></i> : null;
            return (
                <Tile class={`thread tile-centered ${badge}`} data-badge={unread} onclick={attrs.onclick}>
                    <h5 class="tile-title">{data.subject}</h5>
                    <div class="thread-icon">{icon}</div>
                    <div class="tile-title">{recipients.map(user => `${user.user.firstName} ${user.user.lastName}`).join(', ')}</div>
                    {message}
                    {active}
                </Tile>
            );
        }
    };
}

function getLastMessage(data) {
    const message = data[data.length - 1];
    if (message) {
        const sent = message.sent.split('T');
        sent[1] = sent[1].substr(0, 5);
        return [
            <p key="content" class="tile-subtitle">{message.content}</p>,
            <p key="sender" class="tile-subtitle text-gray">
                -
                <span class="user">{message.sender.firstName} {message.sender.lastName}</span>,
                <span class="expand"></span>
                <span class="date">{sent[1]} {sent[0]}</span>
            </p>,
        ];
    }
    return null;
}
