/* eslint-disable max-len */
import m from 'mithril';
import { Card } from 'common/components/card';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { ConvertToBase64 } from 'common/utils/string-utils';
import { ProfileDataField } from './profile-data-field';

export function ProfilePassword({ attrs }) {
    const isEditable = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const passwords = new Observable({ oldPassword: null, newPassword: null, newPasswordConfirm: null })
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .map((value, oldValue) => ({ ...oldValue, ...value }))
        .each(m.redraw);

    const updatePassword = (e) => {
        e.preventDefault();
        Bus.secure('UpdateOwnPassword', ConvertToBase64(passwords.value.oldPassword), ConvertToBase64(passwords.value.newPassword),
            ConvertToBase64(passwords.value.newPasswordConfirm));
        isEditable(false);
        passwords({ oldPassword: null, newPassword: null, newPasswordConfirm: null });
    };
    const cancel = (e) => {
        e.preventDefault();
        isEditable(false);
        passwords({ oldPassword: null, newPassword: null, newPasswordConfirm: null });
    };
    return {
        view({ attrs }) {
            const passwordDataFields = [
                { label: _`Current password*`, property: 'oldPassword', data: passwords.value.oldPassword, required: true, type: 'password', autocomplete: 'off' },
                { label: _`New password*`, property: 'newPassword', data: passwords.value.newPassword, required: true, type: 'password', autocomplete: 'off' },
                { label: _`Confirm new password*`, property: 'newPasswordConfirm', data: passwords.value.newPasswordConfirm, required: true, type: 'password', autocomplete: 'off' },
            ];
            return (
                <Card class={`${attrs.class}`}>
                    <div class="profile-header">
                        <h4 class="profile-header-text">{_`Change Password`}</h4>
                        <div class="position-relative">
                            <div class="card-icon tooltip" data-tooltip={_`Edit`}>
                                {!isEditable.value
                                    ? <i class="icon icon-edit" onclick={() => isEditable(true)} />
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div class="profile-property" slot="content">
                        {isEditable.value
                            ? (
                                <form id="profile-password" onsubmit={updatePassword}>
                                    {passwordDataFields.map((dataField, i) => <ProfileDataField key={i} {...dataField} save={passwords} />)}
                                    <div class="manage-buttons">
                                        <button class="cancel-button tooltip" data-tooltip={_`Cancel`} onclick={cancel}>
                                            <i class="icon icon-cross"></i>
                                        </button>
                                        <button type="submit" class="save-button tooltip" data-tooltip={_`Save new password`} form="profile-password">
                                            <i class="icon icon-check"></i>
                                        </button>
                                    </div>
                                </form>
                            )
                            : null}
                    </div>
                </Card>
            );
        }
    };
}
