import m from 'mithril';
import Chart from 'chart.js';
import { Observable } from 'common/utils/observable';
import { Auth } from 'common/utils/authenticate';
import { compareObjects } from 'common/utils/object-utils';
import { Loading } from 'common/components/loading';
import { ConditionChecker } from 'common/components/condition-checker';
import { ChartTypes, ColorOptions } from './extra';
import { Edit } from './edit';
import './main.scss';

export function Diagram({ attrs }) {
    const { component, saveComponent } = attrs;
    const listeners = [];
    let chart = null;

    const diagram = new Observable(component.chart)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(value => saveComponent(value, component.id, 'chart'));

    const renderChart = (dom) => {
        const ctx = dom.querySelector(`#diagram-${diagram.value.id}`);
        if (chart !== null) {
            chart.destroy();
        }

        const chartType = ChartTypes[diagram.value.type];

        const colors = chartType.chart === 'line'
            ? () => {
                const highestValueDP = diagram.value.dataPoints.filter(x => x.data === Math.max(...diagram.value.dataPoints.map(o => o.data), 0))[0];
                return ColorOptions[highestValueDP.color] ? ColorOptions[highestValueDP.color].rgb : '#81EFC3';
            }
            : diagram.value.dataPoints.map(dataPoint => ColorOptions[dataPoint.color].rgb);

        chart = new Chart(ctx, {
            type: chartType.chart,
            data: {
                datasets: [{
                    data: diagram.value.dataPoints.map(dataPoint => dataPoint.data),
                    backgroundColor: colors,
                    borderColor: colors,
                    fill: chartType.fill,
                    tension: 0.1
                }],
                labels: diagram.value.dataPoints.map(dataPoint => dataPoint.label),
            },
            options: {
                legend: {
                    display: chartType.displayLegend,
                    position: 'bottom',
                },
                title: {
                    display: false,
                    text: diagram.value.name
                },
                animation: {
                    duration: 0
                },
                scales: {
                    ticks: { min: 0 },
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                }
            }
        });
    };

    const isModalOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const canEdit = Auth.isAccountType('admin') || Auth.isAccountType('superuser');

    return {
        oncreate({ dom }) {
            renderChart(dom);
        },
        onupdate({ dom }) {
            renderChart(dom);
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { inEditMode, component } = attrs;
            diagram(component.chart);
            if (!diagram.value) {
                return <Loading class="loading loading-lg" />;
            }
            return (
                <div class="diagram-div">
                    <header class="table-view header">{diagram.value.name}</header>
                    <canvas onclick={() => (inEditMode && canEdit ? isModalOpen(true) : null)} id={`diagram-${diagram.value.id}`} />
                    <ConditionChecker check={Auth.includesAccountType(['admin', 'superuser'])}>
                        <Edit isModalOpen={isModalOpen} close={() => isModalOpen(false)} diagram={diagram.value} save={diagram} />
                    </ConditionChecker>
                </div>
            );
        }
    };
}
