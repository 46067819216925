import debug from 'debug';
import { sv } from 'date-fns/locale';
import { _ } from '../utils/localization';

const Log = debug('{locale}');
Log('Localized sv');

/* eslint-disable no-unused-expressions */
_.addLocale({ sv });

// MANAGE COMPANIES index.js
_.set('en')`Active`
    .for('sv')`Aktiv`;
_.set('en')`Inactive`
    .for('sv')`Inaktiv`;

// MANAGE COMPANIES index.js
_.set('en')`Add New Company`
    .for('sv')`Lägg till nytt företag`;
_.set('en')`Manage Companies`
    .for('sv')`Hantera Företag`;
_.set('en')`Edit Company`
    .for('sv')`Redigera företag`;

// MANAGE COMPANIES company-list.js
_.set('en')`Company Name`
    .for('sv')`Företagsnamn`;
_.set('en')`Org. Number`
    .for('sv')`Organisationsnummer`;
_.set('en')`City`
    .for('sv')`Ort`;
_.set('en')`Country`
    .for('sv')`Land`;
_.set('en')`0 companies found.`
    .for('sv')`0 företag hittades.`;

// MANAGE COMPANIES company-item.js
_.set('en')`Edit`
    .for('sv')`Redigera`;
_.set('en')`Deactivate`
    .for('sv')`Avaktivera`;
_.set('en')`Restore`
    .for('sv')`Återställ`;

// MANAGE COMPANIES company-form.js
_.set('en')`Name*`
    .for('sv')`Namn*`;
_.set('en')`Org. Number*`
    .for('sv')`Org. Nummer*`;
_.set('en')`Firstname*`
    .for('sv')`Förnamn*`;
_.set('en')`Lastname*`
    .for('sv')`Efternamn*`;
_.set('en')`Telephone`
    .for('sv')`Telefon`;
_.set('en')`Email*`
    .for('sv')`E-post*`;
_.set('en')`Address Line 1*`
    .for('sv')`Adress Linje 1*`;
_.set('en')`Address Line 1`
    .for('sv')`Adress Linje 1`;
_.set('en')`Address Line 2`
    .for('sv')`Adress Linje 2`;
_.set('en')`Postcode*`
    .for('sv')`Postnummer*`;
_.set('en')`Postcode`
    .for('sv')`Postnummer`;
_.set('en')`City*`
    .for('sv')`Ort*`;
_.set('en')`Country*`
    .for('sv')`Land*`;
_.set('en')`Enter your 10 digit organization number`
    .for('sv')`Ange ditt 10-siffriga organisationsnummer`;
_.set('en')`Enter your 10 digit phone number`
    .for('sv')`Ange ditt 10-siffriga telefonnummer`;
_.set('en')`Module(s)`
    .for('sv')`Modul(er)`;
_.set('en')`Cancel`
    .for('sv')`Avbryt`;
_.set('en')`Save`
    .for('sv')`Spara`;
_.set('en')`Different delivery address?`
    .for('sv')`Annan leveransadress?`;
_.set('en')`Invoice & Delivery Address`
    .for('sv')`Faktura & leveransadress`;
_.set('en')`Contact Person`
    .for('sv')`Kontaktperson`;
_.set('en')`Company Information`
    .for('sv')`Företagsinformation`;
_.set('en')`Add Column`
    .for('sv')`Lägg till kolumn`;
_.set('en')`Close edit view`

    // TABLE
    .for('sv')`Stäng redigeringsvyn`;
_.set('en')`Edit columns and rows \n Edit row data`
    .for('sv')`Redigera kolumner och rader \n Redigera raduppgifter`;
_.set('en')`Remove Option`
    .for('sv')`Ta bort Option`;
_.set('en')`Add Option`
    .for('sv')`Lägg till Option`;
_.set('en')`Header Column`
    .for('sv')`Rubrik kolumn`;
_.set('en')`Column is locked`
    .for('sv')`Kolumn är låst`;
_.set('en')`Column is not locked`
    .for('sv')`Kolumn är inte låst`;
_.set('en')`Edit header data`
    .for('sv')`Redigera rubrikdata`;
_.set('en')`Close`
    .for('sv')`Stäng`;
_.set('en')`Save & close`
    .for('sv')`Spara och stäng`;
_.set('en')`Add Row`
    .for('sv')`Lägg till rad`;
_.set('en')`Row`
    .for('sv')`Rad`;
_.set('en')`Edit`
    .for('sv')`Redigera`;
_.set('en')`Download`
    .for('sv')`Ladda ner`;
_.set('en')`Upload a file:`
    .for('sv')`Ladda upp en fil:`;
_.set('en')`No valid link`
    .for('sv')`Ingen giltig länk`;
_.set('en')`Choose column type`
    .for('sv')`Välj kolumn typ`;
_.set('en')`Column`
    .for('sv')`Kolumn`;
_.set('en')`Must choose a column type`
    .for('sv')`Måste välja en kolumn typ`;

/* TABLE-TYPES */
_.set('en')`Options`
    .for('sv')`Alternativ`;
_.set('en')`Multi-Options`
    .for('sv')`Multi-alternativ`;
_.set('en')`File`
    .for('sv')`Fil`;
_.set('en')`Freetext`
    .for('sv')`Fritext`;
_.set('en')`Date`
    .for('sv')`Datum`;
_.set('en')`Link`
    .for('sv')`Länk`;
_.set('en')`WYSIWYG`
    .for('sv')`Media text`;

// Home
_.set('en')`Add diagram`
    .for('sv')`Lägg till diagram`;
_.set('en')`No Accounts`
    .for('sv')`Inga konton`;
_.set('en')`Edit diagram`
    .for('sv')`Redigera diagram`;
_.set('en')`Save company message`
    .for('sv')`Spara företagsmeddelande`;
_.set('en')`Cancel`
    .for('sv')`Avbryt`;
_.set('en')`Close`
    .for('sv')`Stäng`;
_.set('en')`Edit`
    .for('sv')`Redigera`;
_.set('en')`Please select values in all dropdowns to be able to create a diagram`
    .for('sv')`Lägg till värden i alla fält för att skapa diagram`;
_.set('en')`EDIT DIAGRAM`
    .for('sv')`Redigera diagram`;
_.set('en')`ADD NEW DIAGRAM`
    .for('sv')`Lägg till nytt diagram`;
_.set('en')`Empty Column`
    .for('sv')`Tom kolumn`;
_.set('en')`Delete Diagram`
    .for('sv')`Radera diagram`;
_.set('en')`No valid column chosen`
    .for('sv')`Ingen giltig kolum vald`;
_.set('en')`Save`
    .for('sv')`Spara`;
_.set('en')`Select diagram`
    .for('sv')`Välj diagram`;
_.set('en')`pie`
    .for('sv')`Paj`;
_.set('en')`doughnut`
    .for('sv')`Munk`;
_.set('en')`polarArea`
    .for('sv')`Polar area`;
_.set('en')`Select company`
    .for('sv')`Välj företag`;
_.set('en')`Active accounts color`
    .for('sv')`Färg för aktiva konton`;
_.set('en')`Inactive accounts color`
    .for('sv')`Färg för inaktiva konton`;
_.set('en')`Select diagram...`
    .for('sv')`Välj diagram...`;
_.set('en')`Select table`
    .for('sv')`Välj tabell`;
_.set('en')`No views available`
    .for('sv')`Inga vyer tillgängliga`;
_.set('en')`Select data column`
    .for('sv')`Välj data kolumn`;
_.set('en')`No valid columns`
    .for('sv')`Inga giltiga kolumner`;
_.set('en')`Is diagram company chart?`
    .for('sv')`Är diagram företagsdiagram?`;
_.set('en')`Empty Data`
    .for('sv')`Tom Data`;
_.set('en')`Active Accounts`
    .for('sv')`Aktiva Konton`;
_.set('en')`Inactive Accounts`
    .for('sv')`Inaktiva Konton`;

/* LOGIN */
_.set('en')`Your account has been deactivated,  please contact your closest administrator.`
    .for('sv')`Ditt konto har inaktiverats, kontakta din närmaste administratör.`;
_.set('en')`Your account does not have an active company attached to it, please contact your closest administrator. `
    .for('sv')`Ditt konto har inte ett aktivt företag fäst vid den, kontakta din närmaste administratör.`;
_.set('en')`The name or password you entered is not correct.`
    .for('sv')`Namnet eller lösenord du angav är inte korrekt.`;
_.set('en')`LOGIN`
    .for('sv')`LOGGA IN`;
_.set('en')`Forgot password?`
    .for('sv')`Glömt ditt lösenord?`;
_.set('en')`Please contact your closest administrator`
    .for('sv')`Kontakta systemadministratör`;
_.set('en')`Name`
    .for('sv')`Namn`;
_.set('en')`Password`
    .for('sv')`Lösenord`;

// MANAGE MODULES index.js
_.set('en')`Manage Modules`
    .for('sv')`Hantera Moduler`;
_.set('en')`Add New Module`
    .for('sv')`Lägg till ny modul`;
_.set('en')`Edit Module`
    .for('sv')`Redigera Modul`;
_.set('en')`Add Module`
    .for('sv')`Lägg till Modul`;
_.set('en')`Edit View`
    .for('sv')`Redigera vy`;
_.set('en')`Add View`
    .for('sv')`Lägg till vy`;
_.set('en')`Status`
    .for('sv')`Status`;

// MANAGE MODULES module-list.js
_.set('en')`Module Name`
    .for('sv')`Modulnamn`;
_.set('en')`Add new view to`
    .for('sv')`Lägg till ny vy till`;

// MANAGE MODULES edit-add-or-toggle-status.js
_.set('en')`Module Name*`
    .for('sv')`Modulnamn*`;
_.set('en')`View name*`
    .for('sv')`Vy namn*`;
_.set('en')`Do you want to set module as active`
    .for('sv')`Vill du aktivera modulen?`;
_.set('en')`Do you want to set view as active `
    .for('sv')`Vill du aktivera vyn?`;
_.set('en')`deactivate`
    .for('sv')`avaktivera`;
_.set('en')`restore`
    .for('sv')`återställa`;
_.set('en')` the following module:`
    .for('sv')` följande modulen:`;
_.set('en')` the following view:`
    .for('sv')` följande vyn:`;
_.set('en')`Do you want to`
    .for('sv')`Vill du`;
_.set('en')`lock`
    .for('sv')`låsa`;
_.set('en')`unlock`
    .for('sv')`låsa upp`;

// MANAGE MODULES empty-list.js
_.set('en')`No modules found.`
    .for('sv')`Inga moduler hittades.`;
_.set('en')`Activate an inactive module or create a new one`
    .for('sv')`Aktivera en inaktiv modul eller skapa en ny`;
_.set('en')`View active modules or create a new one`
    .for('sv')`Se aktiva moduler eller skapa en ny`;
_.set('en')`Create a module`
    .for('sv')`Lägg till en ny modul`;

// Manage Users
_.set('en')`Manage Users`
    .for('sv')`Hantera Användare`;
_.set('en')`Add user`
    .for('sv')`Lägg till användare`;
_.set('en')`Display`
    .for('sv')`Visa`;
_.set('en')`user`
    .for('sv')`användare`;
_.set('en')`Add`
    .for('sv')`Lägg till`;
_.set('en')`Edit`
    .for('sv')`Redigera`;
_.set('en')`Email`
    .for('sv')`E-post`;
_.set('en')`Full name`
    .for('sv')`Fullständigt namn`;
_.set('en')`Companies`
    .for('sv')`Företag`;
_.set('en')`Account Type`
    .for('sv')`Kontotyp`;
_.set('en')`Edit User`
    .for('sv')`Redigera användare`;
_.set('en')`Deactivate User`
    .for('sv')`Inaktivera användare`;
_.set('en')`Activate user`
    .for('sv')`Aktivera användare`;
_.set('en')`0 accounts found.`
    .for('sv')`0 konton hittades`;
_.set('en')`Do you want to set user`
    .for('sv')`Vill du sätta användaren`;
_.set('en')`as`
    .for('sv')`som`;
_.set('en')`inactive`
    .for('sv')`inaktiv`;
_.set('en')`active`
    .for('sv')`aktiv`;
_.set('en')`Admin Password*`
    .for('sv')`Admin lösenord*`;
_.set('en')`Confirm`
    .for('sv')`Bekräfta`;
_.set('en')`Firstname`
    .for('sv')`Förnamn`;
_.set('en')`Lastname`
    .for('sv')`Efternamn`;
_.set('en')`Telephone`
    .for('sv')`Telefon`;
_.set('en')`Email`
    .for('sv')`E-post`;
_.set('en')`Company`
    .for('sv')`Företag`;
_.set('en')`Enter your 10 digit phone number`
    .for('sv')`Ange ditt 10-siffriga telefonnummer`;
_.set('en')`Email must include a @ and end with a domain name`
    .for('sv')`E-post måste innehålla ett @ och sluta domännamn`;
_.set('en')`New Password`
    .for('sv')`Nytt lösenord`;
_.set('en')`Confirm Password`
    .for('sv')`Bekräfta lösenord`;
_.set('en')`Admin Password`
    .for('sv')`Admin lösenord`;

/* MY PROFILE */
_.set('en')`Account Overview`
    .for('sv')`Kontoöversikt`;
_.set('en')`Firstname`
    .for('sv')`Förnamn`;
_.set('en')`Lastname`
    .for('sv')`Efternamn`;
_.set('en')`Telephone`
    .for('sv')`Telefon`;
_.set('en')`Save changes`
    .for('sv')`Spara ändringar`;
_.set('en')`Enter your 10 digit phone number`
    .for('sv')`Ange ditt 10-siffriga telefonnummer`;
_.set('en')`Email`
    .for('sv')`E-post`;
_.set('en')`Email must include a @ and end with a domain name`
    .for('sv')`E-post måste innehålla ett @ och slutar med ett domännamn`;
_.set('en')`Profile Information`
    .for('sv')`Profilinformation`;
_.set('en')`Current password*`
    .for('sv')`Nuvarande lösenord*`;
_.set('en')`New password*`
    .for('sv')`Nytt lösenord*`;
_.set('en')`Confirm new password*`
    .for('sv')`Bekräfta nytt lösenord*`;
_.set('en')`Change Password`
    .for('sv')`Ändra lösenord`;
_.set('en')`Save new password`
    .for('sv')`Spara nytt lösenord`;
_.set('en')`Profile Settings`
    .for('sv')`Profilinställningar `;
_.set('en')`Receive email notifications when I get new messages in ductum`
    .for('sv')`Få e-postmeddelanden när jag får nya meddelanden i ductum`;

/* ADMIN MENU */
_.set('en')`Home`
    .for('sv')`Hem`;
_.set('en')`Front Page`
    .for('sv')`Startsida`;
_.set('en')`My Account`
    .for('sv')`Mitt Konto`;
_.set('en')`Manage Users`
    .for('sv')`Hantera användare`;
_.set('en')`Manage Companies`
    .for('sv')`Hantera företag`;
_.set('en')`Manage Modules`
    .for('sv')`Hantera moduler`;
_.set('en')`Logout`
    .for('sv')`Logga ut`;

/* CONFIRM DELETE MODAL */
_.set('en')`Confirm Delete`
    .for('sv')`Bekräfta radering`;
_.set('en')`Confirm`
    .for('sv')`Bekräfta`;

/* PAGINATION */
_.set('en')`Previous`
    .for('sv')`Föregående`;
_.set('en')`Next`
    .for('sv')`Nästa`;

/* QUILL */
_.set('en')`Write a message...`
    .for('sv')`Skriv ett meddelande...`;

/* MODAL */
_.set('en')`Switch Company`
    .for('sv')`Byt företag`;
_.set('en')`Search customer by name or org. number...`
    .for('sv')`Sök kund efter namn eller organisationsnummer`;
_.set('en')`Company Name`
    .for('sv')`Företagsnamn`;
_.set('en')`Org. Number `
    .for('sv')`Organisationsnummer`;
_.set('en')`0 companies found`
    .for('sv')`0 företag hittades`;

/* TABLE-TYPES */
_.set('en')`Options`
    .for('sv')`Alternativ`;
_.set('en')`Multi-Options`
    .for('sv')`Multi-alternativ`;
_.set('en')`File`
    .for('sv')`Fil`;
_.set('en')`Freetext`
    .for('sv')`Fritext`;
_.set('en')`Date`
    .for('sv')`Datum`;
_.set('en')`Link`
    .for('sv')`Länk`;
_.set('en')`WYSIWYG`
    .for('sv')`Media text`;

/* TOAST-MESSAGES */
_.set('en')`Account Created`
    .for('sv')`Konto skapat`;
_.set('en')`Wrong admin password`
    .for('sv')`Fel admin lösenord`;
_.set('en')`Email is already taken`
    .for('sv')`Epost är redan tagen`;
_.set('en')`You must choose a company if the account is not admin`
    .for('sv')`Du måste välja ett företag om kontot inte är admin`;
_.set('en')`Wrong confirmation`
    .for('sv')`Fel bekräftelse`;
_.set('en')`Firstname can not be empty`
    .for('sv')`Du måste ange ett förnamn`;
_.set('en')`Lastname can not be empty`
    .for('sv')`Du måste ange ett efternamn`;
_.set('en')`Account updated correctly`
    .for('sv')`Kontot uppdaterades korrekt`;
_.set('en')`Account was updated`
    .for('sv')`Kontot uppdaterades`;
_.set('en')`Password updated correctly`
    .for('sv')`Lösenordet uppdaterades korrekt`;
_.set('en')`Invalid current password`
    .for('sv')`Ogiltigt nuvarande lösenord`;
_.set('en')`Invalid confirmation`
    .for('sv')`Ogiltigt bekräftelse`;
_.set('en')`New password is same as current password`
    .for('sv')`Nya lösenordet är samma som nuvarande lösenord`;
_.set('en')`The account has been set as inactive`
    .for('sv')`Kontot har ställts som inaktiv`;
_.set('en')`The account has been set as active`
    .for('sv')`Kontot har ställts som aktiv`;
_.set('en')`Company name can not be empty.`
    .for('sv')`Företagsnamn kan inte vara tomt`;
_.set('en')`Company creation successful!`
    .for('sv')`Företagsskapandet lyckades!`;
_.set('en')`A company with that organisation number already exists.`
    .for('sv')`Ett företag med det angivna organisationsnummer finns redan.`;
_.set('en')`Company Update Successful`
    .for('sv')`Företagsuppdatering lyckades`;
_.set('en')`The company is now inactive`
    .for('sv')`Företaget är nu inaktivt`;
_.set('en')`The company is now active`
    .for('sv')`Företaget är nu aktivt`;
_.set('en')`Company message updated`
    .for('sv')`Företagsmeddelandet uppdaterades`;
_.set('en')`Changes saved`
    .for('sv')`Ändringarna sparades`;
_.set('en')`An error occured while saving your changes`
    .for('sv')`Ett fel inträffade medans ändringarna sparades`;
_.set('en')`You have a new message...`
    .for('sv')`Du har ett nytt meddelande...`;
_.set('en')`Messages`
    .for('sv')`Meddelande`;

// PAGE BUILDER index.js
_.set('en')`An error has occurred, please contact an administrator`
    .for('sv')`Ett fel har inträffat. Kontakta en administratör`;
_.set('en')`Save All Changes`
    .for('sv')`Spara Alla Ändringar`;
_.set('en')`Cancel All Changes`
    .for('sv')`Kasta Alla Ändringar`;
_.set('en')`Add Component`
    .for('sv')`Lägg Till Komponent`;
_.set('en')`Table`
    .for('sv')`Tabell`;
_.set('en')`Chart`
    .for('sv')`Diagram`;
_.set('en')`Table`
    .for('sv')`Tabel`;
_.set('en')`Created`
    .for('sv')`Skapad`;
_.set('en')`Updated`
    .for('sv')`Uppdaterad`;

// PAGE BUILDER toolbar.js
_.set('en')`Delete Component`
    .for('sv')`Ta bort komponent`;
_.set('en')`Move Component Left`
    .for('sv')`Flytta Komponent Åt Vänster`;
_.set('en')`Move Component Right`
    .for('sv')`Flytta Komponent Åt Höger`;
_.set('en')`The amount of width that the component occupies out of 12.`
    .for('sv')`Mängden bredd som komponenten\nupptar utav 12 kolumner.`;
_.set('en')`Columns`
    .for('sv')`Kolumner`;

// DIAGRAM edit.js
_.set('en')`Cancel changes`
    .for('sv')`Kasta ändringarna`;
_.set('en')`Handle Component`
    .for('sv')`Hantera Komponent`;
_.set('en')`Title`
    .for('sv')`Titel`;
_.set('en')`Chart Type`
    .for('sv')`Diagramm Typ`;
_.set('en')`Table`
    .for('sv')`Tabell`;
_.set('en')`Column`
    .for('sv')`Kolumn`;
_.set('en')`Remove data`
    .for('sv')`Ta bort data`;
_.set('en')`Add data`
    .for('sv')`Lägg till data`;

// PAGE BUILDER add-component-toolbar.js
_.set('en')`Add a table component`
    .for('sv')`Lägg till en tabel komponent`;
_.set('en')`Add a chart component`
    .for('sv')`Lägg till ett diagramm komponent`;
_.set('en')`Add an editor component`
    .for('sv')`Lägg till text redigerings komponent`;

/* COPY MODULES AND VIEWS */
_.set('en')`Copy Modules and Views`
    .for('sv')`Kopiera Moduler och Vyer`;
_.set('en')`You need to choose two\ndifferent companies`
    .for('sv')`Du måste välja två olika\nföretag`;
_.set('en')`You need to choose a value\nfrom the left hand side`
    .for('sv')`Du måste välja ett värde\nfrån den vänstra sidan`;
_.set('en')`You need to choose a destination\nmodule from the right hand side`
    .for('sv')`Du måste välja till vilken modul\ndin vy ska på den högra sidan`;
_.set('en')`Clone view`
    .for('sv')`Klona vy`;
_.set('en')`Clone module`
    .for('sv')`Klona modul`;

/* NEWS */
_.set('en')`Open`
    .for('sv')`Öppna`;

/* Forum */
_.set('en')`Forum`
    .for('sv')`Forum`;
_.set('en')`SEARCH`
    .for('sv')`SÖK`;
_.set('en')`Topic`
    .for('sv')`Ämne`;
_.set('en')`Answers`
    .for('sv')`Svar`;
_.set('en')`Views`
    .for('sv')`Visningar`;
_.set('en')`Latest Message`
    .for('sv')`Senaste Inlägg`;
_.set('en')`Active threads`
    .for('sv')`Aktiva trådar`;
_.set('en')`Posts last month`
    .for('sv')`Inlägg senaste månaden`;
_.set('en')`Latest thread`
    .for('sv')`Senaste tråd`;
_.set('en')`Latest post`
    .for('sv')`Senaste inlägg`;
_.set('en')`Send`
    .for('sv')`Skicka`;

/* SUPPORT */
_.set('en')`If you have any problems and want to talk to us`
    .for('sv')`Om du har några problem och vill prata med oss`;
_.set('en')`CLICK ME`
    .for('sv')`TRYCK PÅ MIG`;

/* Process Handling */
_.set('en')`Process Handling`
    .for('sv')`Ärendehantering`;
_.set('en')`From`
    .for('sv')`Från`;
_.set('en')`To`
    .for('sv')`Till`;
_.set('en')`Priority`
    .for('sv')`Prioritet`;
_.set('en')`High`
    .for('sv')`Hög`;
_.set('en')`Medium`
    .for('sv')`Medium`;
_.set('en')`Low`
    .for('sv')`Låg`;
_.set('en')`Type`
    .for('sv')`Typ`;
_.set('en')`Document`
    .for('sv')`Dokument`;
_.set('en')`Website`
    .for('sv')`Webbsida`;
_.set('en')`Status`
    .for('sv')`Status`;
_.set('en')`Not Started`
    .for('sv')`Ej Startat`;
_.set('en')`NotStarted`
    .for('sv')`Ej Startat`;
_.set('en')`Ongoing`
    .for('sv')`Pågående`;
_.set('en')`Terminated`
    .for('sv')`Avslutat`;
_.set('en')`Media Owner`
    .for('sv')`Mediaägare`;
_.set('en')`Coordinator`
    .for('sv')`Ärendekoordinator`;
_.set('en')`Analyst`
    .for('sv')`Analytiker`;
_.set('en')`en`
    .for('sv')`sv`;