import m from 'mithril';

export function Checkbox() {
    return {
        view({ attrs }) {
            const { data } = attrs;
            return (
                <div>
                    <label class="checkbox-data">
                        <input type="checkbox" disabled={true} checked={data === 'true'}/>
                        <i class="form-icon"></i>
                    </label>
                </div>
            );
        }
    };
}

export function CheckboxInput() {
    return {
        view({ attrs }) {
            const { cellId, data, save, disabled } = attrs;
            return (
                <div id={`cell-${cellId}`} class="form-group">
                    <label class="form-checkbox">
                        <input type="checkbox" disabled={disabled} onclick={e => save(e.target.checked.toString(), cellId)}
                            checked={data === 'true'} class="form-input"/>
                        <i class="form-icon"></i>
                    </label>
                </div>
            );
        }
    };
}
