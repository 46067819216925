import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import './main.scss';

export function MultiSelect({ attrs }) {
    const { save } = attrs;

    const isShowingOptions = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(() => Bus.emitLocal('Redraw'));

    const saveChosenOption = (e, option, options) => {
        const list = options.slice();
        const index = options.findIndex(choice => choice.id === option.id);
        const choice = { ...option, checked: e.target.checked };
        if (index > -1) {
            list.splice(index, 1, choice);
        }
        save(list);
    };

    return {
        view({ attrs }) {
            const { options, disabled } = attrs;
            return (
                <div class="form-group">
                    <div class="multiselect-block">
                        <button id="btn-toggle" disabled={disabled}
                            onclick= {(e) => { e.preventDefault(); isShowingOptions(!isShowingOptions.value); }}class="btn">
                            <label>{options.filter(item => item.checked).map((item, i) =>
                                (<span class="chosen-item" key={i}>{item.name}</span>))}
                            </label>
                            <i class="icon icon-caret float-right"></i>
                        </button>
                        {isShowingOptions.value
                            ? (
                                <div id="multiselect-options">
                                    {options.map(option => (
                                        <div key={option.id}>
                                            <input type="checkbox" onchange={e => saveChosenOption(e, option, options)} checked={option.checked} />
                                            <p>{option.name}</p>
                                        </div>
                                    ))}
                                </div>)
                            : null
                        }
                    </div>
                </div>
            );
        }
    };
}
