import m from 'mithril';
import { _ } from 'common/utils/localization';

import './main.scss';

export function DataPage() {
    return {
        view({ attrs }) {
            const { data, back, currentRole } = attrs;
            return (
                <div class="content">
                    <h4 class="mb-2 pb-2">
                        <button class="btn btn-sm btn-primary btn-action mr-2" onclick={back} style="position: relative;">
                            <i class="icon icon-back"></i>
                        </button>
                        {data.name} #{data.id} ({new Date(data.creationDate).toISOString().split('T')[0]})
                    </h4>

                    <div class="columns">
                        {data.status === 2 ? (
                            <div class="column col-12 mb-2 pb-2">
                                <h5 class="text-error">{_`Declined`}</h5>
                                <div>You document has been declined due to several reasons which can be found in the provided report</div>
                                <button class="btn btn-primary">Download report</button>
                            </div>
                        ) : null}

                        <div class="column col-6">
                            <h5>{_`Information`}</h5>
                            <div class="columns">
                                <div class="form-group column col-4">
                                    <label class="form-label">{_`Priority`}</label>
                                    <select class="form-select" value={data.priority} disabled={currentRole === 1}>
                                        <option value={0}>{_`Low`}</option>
                                        <option value={1}>{_`Medium`}</option>
                                        <option value={2}>{_`High`}</option>
                                    </select>
                                </div>
                                <div class="form-group column col-4">
                                    <label class="form-label">{_`Status`}</label>
                                    <select class="form-select" value={data.status} disabled={currentRole === 1}>
                                        <option value={0}>{_`NotStarted`}</option>
                                        <option value={1}>{_`Ongoing`}</option>
                                        <option value={2}>{_`Terminated`}</option>
                                    </select>
                                </div>
                                <div class="form-group column col-4">
                                    <label class="form-label">{_`Type`}</label>
                                    <select class="form-select" value={data.type} disabled>
                                        <option value={0}>{_`Document`}</option>
                                        <option value={1}>{_`Website`}</option>
                                    </select>
                                </div>

                                {currentRole !== 1 ? [
                                    <div key="mo" class="form-group column col-6">
                                        <label class="form-label">{_`Media Owner`}</label>
                                        <select class="form-select" disabled>
                                            <option value={0}>{data.mediaOwner.name}</option>
                                        </select>
                                    </div>,
                                    <div key="a" class="form-group column col-6">
                                        <label class="form-label">{_`Analyst`}</label>
                                        <select class="form-select">
                                            <option value={0}>{data.analyst.name}</option>
                                        </select>
                                    </div>
                                ] : null}

                                <div class="form-group column col-12">
                                    <label class="form-label">{_`Description`}</label>
                                    <textarea class="form-input" placeholder="Textarea" rows="5" value={data.description}></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="column col-6">
                            <h5>{_`Messages`}</h5>
                            <div class="card message-thread">
                                <div class="card-body">
                                    <div class="card thread-list">
                                        <div class="card-body">
                                            <div class="tile-subtitle sender read">
                                                <i class="icon icon-people"></i> Exceed Consulting, 11:49 2022-02-22
                                            </div>
                                            <p class="tile-subtitle content read">Here you can write messages</p>
                                        </div>
                                        <div class="card-footer">
                                            <form class="tile-subtitle input">
                                                <input placeholder="..." class="message-input focus" />
                                                <button type="submit" class="btn icon icon-2x">
                                                    <i class="icon icon-arrow-right"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
}
