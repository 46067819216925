export const tableSorting = (a, b) => {
    if (a.row > b.row) {
        return 1;
    }
    if (a.row < b.row) {
        return -1;
    }
    if (a.column > b.column) {
        return 1;
    }
    return (a.column < b.column ? -1 : 0);
};
