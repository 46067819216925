/* eslint-disable max-len */
import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Bus } from 'common/utils/bus';
import { Patterns } from 'common/utils/patterns';
import { InputCompanyDataField, InputCompanyColorDataField, InputCompanyImageDataField } from './input-company-data-field';

export function CompanyForm({ attrs }) {
    let list = [];
    const { isEditable, isCheckedDeliveryAddress, isAddingNewCompany } = attrs;
    const company = new Observable(attrs.company)
        .map((value, oldValue) => ({ ...oldValue, ...value }))
        .each(m.redraw);

    const cancel = (e) => {
        e.preventDefault();
        isEditable(false);
        company(attrs.company);
    };

    const edit = (e) => {
        e.preventDefault();
        isEditable(true);
    };

    const checkForDeliveryAddress = () => {
        if (!isCheckedDeliveryAddress.value) {
            company({ deliveryAddress: null });
        }
    };

    const saveChanges = (e) => {
        e.preventDefault();
        checkForDeliveryAddress();
        Bus.secure(`${isAddingNewCompany ? 'Create' : 'Update'}Company`, company.value);
    };
    return {
        view({ attrs }) {
            const { isEditable, isCheckedDeliveryAddress, options, isManagingCompany } = attrs;
            const companyInformationFields = [
                { label: _`Name*`, property: 'name', data: company.value.name, required: true },
                { label: _`Org. Number*`, property: 'organisationNumber', data: company.value.organisationNumber, required: true, pattern: Patterns.organisationNumber, title: _`Enter your 10 digit organization number` }
            ];
            const companyContactFields = [
                { label: _`Firstname*`, subproperty: 'firstName', required: true, data: company.value.contact.firstName },
                { label: _`Lastname*`, subproperty: 'lastName', required: true, data: company.value.contact.lastName },
                { label: _`Telephone`, type: 'tel', subproperty: 'telephone', data: company.value.contact.telephone, pattern: Patterns.phoneNumber, title: _`Enter your 10 digit phone number` },
                { label: _`Email*`, type: 'email', required: true, subproperty: 'email', data: company.value.contact.email, pattern: Patterns.email },
            ];
            const companyInvoiceAddressFields = [
                { label: _`Address Line 1*`, subproperty: 'line1', required: true, data: company.value.address.line1 },
                { label: _`Address Line 2`, subproperty: 'line2', data: company.value.address.line2 },
                { label: _`Postcode*`, subproperty: 'postcode', required: true, data: company.value.address.postcode },
                { label: _`City*`, subproperty: 'city', required: true, data: company.value.address.city ? company.value.address.city : null },
                { label: _`Country*`, subproperty: 'country', required: true, data: company.value.address.country },
            ];
            const companyDeliveryAddressFields = [
                { label: _`Address Line 1`, subproperty: 'line1', data: company.value.deliveryAddress ? company.value.deliveryAddress.line1 : null },
                { label: _`Address Line 2`, subproperty: 'line2', data: company.value.deliveryAddress ? company.value.deliveryAddress.line2 : null },
                { label: _`Postcode`, subproperty: 'postcode', data: company.value.deliveryAddress ? company.value.deliveryAddress.postcode : null },
                { label: _`City`, subproperty: 'city', data: company.value.deliveryAddress ? company.value.deliveryAddress.city : null },
                { label: _`Country`, subproperty: 'country', data: company.value.deliveryAddress ? company.value.deliveryAddress.country : null },
            ];
            const companyColorCustomizations = [
                { label: _`Navbar Color`, subproperty: 'navBarColor', data: company.value.customization ? company.value.customization.navBarColor : null },
                { label: _`Navbar Text Color`, subproperty: 'navbarTextColor', data: company.value.customization ? company.value.customization.navbarTextColor : null },
                { label: _`Sidebar Color`, subproperty: 'sidebarColor', data: company.value.customization ? company.value.customization.sidebarColor : null },
                { label: _`Sidebar Text Color`, subproperty: 'sidebarTextColor', data: company.value.customization ? company.value.customization.sidebarTextColor : null },
            ];
            list = options.value;
            return (
                <form class="manage-object-block" slot="content" onsubmit={saveChanges}>
                    <div>
                        <h5>{_`Company Information`}</h5>
                        {companyInformationFields.map((field, i) =>
                            <InputCompanyDataField key={i} {...field} save={company} isEditable={company.value.id !== 1 ? isEditable : false} company={company} />)}
                    </div>
                    <div class="contact-person-block">
                        <h5>{_`Contact Person`}</h5>
                        {companyContactFields.map((field, i) =>
                            <InputCompanyDataField key={i} {...field} save={company} isEditable={isEditable} property="contact" company={company} />)}
                    </div>
                    <div class="invoice-address-block">
                        <h5>{_`Invoice & Delivery Address`}</h5>
                        {companyInvoiceAddressFields.map((field, i) =>
                            <InputCompanyDataField key={i} {...field} save={company} isEditable={isEditable} property="address" company={company} />)}
                    </div>
                    <div class="delivery-address-block">
                        <div class="form-check">
                            <input id="flexCheckDefault" class="form-check-input" type="checkbox" checked={isCheckedDeliveryAddress.value}
                                onclick={() => isCheckedDeliveryAddress(!isCheckedDeliveryAddress.value)} />
                            <label class="form-check-label" for="flexCheckDefault">{_`Different delivery address?`}</label>
                        </div>
                        <div class={`deliveryData ${!isCheckedDeliveryAddress.value ? 'delivery-opacity' : ''}`}>
                            {companyDeliveryAddressFields.map((field, i) =>
                                <InputCompanyDataField key={i} {...field} save={company} isEditable={isEditable} company={company}
                                    property="deliveryAddress" class={!isCheckedDeliveryAddress.value ? 'disabled' : ''} />)}
                        </div>
                    </div>
                    <div class="company-customizing-block">
                        <h5>{_`Custom Colors`}</h5>
                        {companyColorCustomizations.map((field, i) =>
                            <InputCompanyColorDataField key={i} {...field} save={company} isEditable={isEditable} property="customization" company={company} />)}
                    </div>
                    <div class="company-customizing-block">
                        <h5>{_`Custom Logo`}</h5>
                        <InputCompanyImageDataField save={company} isEditable={isEditable} property="customization" company={company} label={_`Logo`} width={150} height={150}
                            subproperty="logoURL" data={company.value.customization && company.value.customization.logoURL ? company.value.customization.logoURL : ''} />
                    </div>
                    <div class="company-customizing-block">
                        <h5>{_`Custom Banner`}</h5>
                        <InputCompanyImageDataField save={company} isEditable={isEditable} property="customization" company={company} label={_`Banner`} width={750} height={100}
                            subproperty="banner" data={company.value.customization && company.value.customization.banner ? company.value.customization.banner : ''} />
                    </div>
                    <div class="company-form-buttons">
                        {isEditable.value
                            ? (
                                <div>
                                    {isManagingCompany
                                        ? (
                                            <button class="cancel-button tooltip" data-tooltip={_`Cancel`} onclick={e => cancel(e)}>
                                                <i class="icon icon-cross" />
                                            </button>
                                        ) : null}
                                    <button type="submit" class='save-button tooltip' data-tooltip={_`Save`}>
                                        <i class="icon icon-check" />
                                    </button>
                                </div>
                            ) : isManagingCompany
                                ? (
                                    <div>
                                        <button class="edit-button tooltip" data-tooltip={_`Edit`} onclick={e => edit(e)}>
                                            <i class="icon icon-edit" />
                                        </button>
                                    </div>
                                ) : null
                        }
                    </div>
                </form>
            );
        }
    };
}
