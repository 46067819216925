import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { Modal } from 'common/components/modal';

import '../main.scss';

export function AddIssue() {
    const isModal = new Observable(true)
        .each(m.redraw);
    return {
        oncreate() {
            isModal(true);
        },
        view({ attrs }) {
            return (
                <Modal active={isModal}>
                    <div class="form-group">
                        <label class="form-label">{_`Issue Type`}</label>
                        <select class="form-select">
                            <option>{_`Document Audit`}</option>
                            <option>{_`Website Audit`}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="form-label">{_`Upload File`}</label>
                        <input type="file" />
                    </div>
                    <div class="form-group">
                        <label class="form-label">{_`Description`}</label>
                        <textarea class="form-input" rows="3"></textarea>
                    </div>

                    <div class="form-group text-right">
                        <button class="btn btn-action btn-success mx-2" onclick={() => attrs.addIssue(false)}>
                            <i class="icon icon-check"></i>
                        </button>
                        <button class="btn btn-action btn-error mx-2" onclick={() => attrs.addIssue(false)}>
                            <i class="icon icon-cross"></i>
                        </button>
                    </div>
                </Modal>
            );
        }
    };
}
