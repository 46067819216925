import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';
import { Modal } from 'common/components/modal';
import { _ } from 'common/utils/localization';
import { compareObjects } from 'common/utils/object-utils';
import { ManageLayout } from '../manage-layout';
import { AccountList } from './account-list';
import { ToggleUserStatus } from './toggle-user-status';
import { EditExistingOrAddNewUser } from './edit-existing-or-add-new-user';
import { DisplayExistingUser } from './display-user';

export function ManageUsers() {
    const listeners = [];
    let companies;
    let accountTypes;

    const modalAction = new Observable(null)
        .filter((value, oldValue) => (oldValue !== value));

    const isModalOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value))
        .map(value => !!value)
        .each(value => (!value ? modalAction(null) : null))
        .each(m.redraw);

    const chosenAccount = new Observable({})
        .each(() => isModalOpen(true));

    const accountList = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const pagination = new Observable({})
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .map((value, oldValue) => ({ ...oldValue, ...value }))
        .each(m.redraw);

    const displayNewUserFields = (e) => {
        e.preventDefault();
        modalAction(_`Add`);
        Bus.secure('GetEmptyAccount');
    };
    const filter = {
        searchText: new Observable('').filter((value, oldValue) => value !== oldValue).each(() => filter.getUsers()),
        isActive: new Observable(true).filter((value, oldValue) => typeof value === 'boolean' && value !== oldValue).each(() => filter.getUsers()),
        getUsers: () => Bus.secure('GetAllAccounts', 1, 1, pagination.value.topic, filter.searchText.value, filter.isActive.value)
    };

    listeners.push(Bus.observe('AccountTypes', (data) => {
        accountTypes = data;
        m.redraw();
    }));

    listeners.push(Bus.observe('GetAllAccounts', (accounts) => {
        pagination(accounts.meta);
        accountList(accounts.results);
    }));

    listeners.push(Bus.observe('GetCompanies', (data) => {
        companies = data;
        m.redraw();
    }));

    listeners.push(Bus.observe('EmptyAccount', (data) => {
        data.accountType = 1;
        chosenAccount(data);
    }));

    listeners.push(Bus.observe('RefreshManageUserList', () => {
            isModalOpen(false);
            filter.getUsers();
    }));

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        oncreate() {
            Bus.secure('GetAccountTypes');
            Bus.secure('GetAllAccounts', 1, 1, 'AccountList', filter.searchText.value, filter.isActive.value);
            Bus.secure('GetCompanies');
        },
        view() {
            if (accountTypes === undefined || companies === undefined) {
                return <Loading class="loading loading-lg" />;
            }
            return [
                <ManageLayout
                    key="manage-layout"
                    filter={filter}
                    pagination={pagination.value}
                    newObject={displayNewUserFields}
                    title={_`Manage Users`}
                    addTooltip={_`Add user`}
                    getMethod="GetAllAccounts"
                    permissions={Auth.isAccountType('admin') || Auth.isAccountType('superuser')}
                >
                    {accountList.value != null
                        ? <AccountList chosenAccount={chosenAccount} accountList={accountList.value} modalAction={modalAction}
                            searchText={filter.searchText} accountTypes={accountTypes} pagination={pagination.value} isActive={filter.isActive} />
                        : <Loading class="loading loading-lg" />
                    }
                </ManageLayout>,
                <Modal key="Modal" class="modal-lg" active={isModalOpen} title={`${modalAction.value || _`Display`} ${_`user`}`}>
                    {modalAction.value === 'Status'
                        ? <ToggleUserStatus account={chosenAccount.value} />
                        : modalAction.value === _`Edit` || modalAction.value === _`Add`
                            ? <EditExistingOrAddNewUser account={chosenAccount.value} accountTypes={accountTypes} modalAction={modalAction}
                                companies={companies} />
                            : <DisplayExistingUser account={chosenAccount.value} accountTypes={accountTypes} modalAction={modalAction} />
                    }
                </Modal>
            ];
        }
    };
}
