import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _, t } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { Toolbar, CurrentRole } from '../toolbar';

export function Analyst({ attrs }) {
    const data = new Observable(attrs.data)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);
    return {
        view({ attrs }) {
            data(attrs.data);
            return (
                <div class="main-content">
                    <CurrentRole {...attrs} />
                    <Toolbar {...attrs} />
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{_`Id`}</th>
                                <th>{_`Name`}</th>
                                <th>{_`Type`}</th>
                                <th>{_`Priority`}</th>
                                <th>{_`Status`}</th>
                                <th>{_`Media owner`}</th>
                                <th>{_`Analyst`}</th>
                                <th>{_`Date`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.value.map(d => (
                                <tr key={d.id} onclick={() => attrs.openProcess(d)}>
                                    <td>{d.id}</td>
                                    <td>{d.name}</td>
                                    <td>{t`${d.typeName}`}</td>
                                    <td>{t`${d.priorityName}`}</td>
                                    <td>{t`${d.statusName}`}</td>
                                    <td>{d.mediaOwner.name}</td>
                                    <td>{d.analyst.name}</td>
                                    <td>{new Date(d.creationDate).toISOString().split('T')[0]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };
}
