import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _, t } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';
import { Logo } from 'common/components/logo';
import { Modal } from 'common/components/modal';
import { Card } from 'common/components/card';
import { ChangeLanguage } from 'common/components/menu/change-language';
import { Tawk } from 'common/components/tawk';

export function Login() {
    const listeners = [];
    const username = new Observable('', (obs, res) => {
        Object.defineProperty(res, 'base64', { get: () => Buffer.from(res.value).toString('base64') });
    });
    const credentials = new Observable('', (obs, res) => {
        Object.defineProperty(res, 'base64', { get: () => Buffer.from(res.value).toString('base64') });
    });
    const lostPasswordUserName = new Observable('', (obs, res) => {
        Object.defineProperty(res, 'base64', { get: () => Buffer.from(res.value).toString('base64') });
    });
    const error = new Observable('');
    listeners.push(Bus.observe('LoginFailed', (reason) => {
        credentials('');
        lostPasswordUserName('');
        error(reason);
        // eslint-disable-next-line no-use-before-define
        sending(false);
    }));
    const submit = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-use-before-define
        emailSent(false);
        Bus.emit('Login', username.base64, credentials.base64);
    };
    const recoverPassword = (e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        error(false);
        // eslint-disable-next-line no-use-before-define
        emailSent(false);
        // eslint-disable-next-line no-use-before-define
        sending(true);
        Bus.emit('Recover', lostPasswordUserName.base64);
    };
    const lostPassword = new Observable(false)
        .map(value => !!value);
    const emailSent = new Observable();
    const sending = new Observable(null, (obj, res) => {
        Object.defineProperty(res, 'component', { get: () => (res.value ? <Loading class="requesting-recover" /> : null), enumerable: true, });
    });
    listeners.push(Bus.observe('EmailSent', (name, email) => {
        lostPassword(false);
        lostPasswordUserName('');
        sending(false);
        emailSent(`Email sent to ${name}. Please check your email.`);
        m.redraw();
    }));
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { loading } = attrs;
            if (loading) {
                return (<Loading class="loading-lg pt-2 mt-2" />);
            }
            return (
                <div class="outer-panel">
                    <Tawk />
                    <div class="column login-panel col-md-11 col-lg-11 col-xl-8 col-4">
                        <div class="change-language-flag float-right">
                            <ChangeLanguage />
                        </div>
                        <div class="login-logo col-mx-auto col-xs-3"><Logo location="loginpage"></Logo></div>
                        <form class="column col-6 col-sm-11 col-mx-auto mt-2" onsubmit={submit}>
                            {error.value ? (<label class="text-error">{t`${error.value}`}</label>) : null}
                            {emailSent.value ? (<label class="text-success">{t`${emailSent.value}`}</label>) : null}
                            <div class={`form-group ${error ? 'has-error' : ''}`}>
                                <input
                                    class="form-input"
                                    type="text"
                                    id="input-name"
                                    required
                                    placeholder={_`Email`}
                                    autocomplete="username"
                                    onchange={e => username(e.target.value)}
                                    value={username}
                                />
                            </div>
                            <div class={`form-group ${error ? 'has-error' : ''}`}>
                                <input
                                    class="form-input"
                                    type="password"
                                    id="input-password"
                                    required
                                    placeholder={_`Password`}
                                    autocomplete="current-password"
                                    onchange={e => credentials(e.target.value)}
                                    value={credentials}
                                />
                            </div>
                            <div class="column col-12 form-group login-button">
                                <button type="submit" class="btn"><span class="login-text">{_`LOGIN`}</span></button>
                            </div>
                        </form>
                        <div class="recover-password text-center full-width popover">
                            <button class="forgot-password" onclick={lostPassword}>{_`Forgot password?`}</button>
                            <div class="popover-container">
                                <Card class="recover-message">
                                    <p>
                                        {_`An email will be sent to your registered email with a new random password. You can change
your password later in your profile page`}
                                    </p>
                                </Card>
                            </div>
                        </div>
                        <Modal class="recover-password" active={lostPassword}>
                            <form onsubmit={recoverPassword}>
                                {error.value ? (<label class="text-error">{t`${error.value}`}</label>) : null}
                                <div class="form-group">
                                    <input
                                        class="form-input"
                                        type="text"
                                        required
                                        placeholder={_`Email`}
                                        autocomplete="email"
                                        onchange={e => lostPasswordUserName(e.target.value)}
                                        value={lostPasswordUserName}
                                    />
                                </div>
                                <div class="form-group recover-button">
                                    <button type="submit" class="btn"><span class="login-text">{_`Recover Password`}</span></button>
                                </div>
                            </form>
                            {sending.component}
                        </Modal>
                    </div>
                </div>
            );
        }
    };
}
