import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { Auth } from 'common/utils/authenticate';
import { PageBuilder } from './page-builder';

import './main.scss';

export function Home() {
    const listeners = [];
    let id = -1;
    let name = '';

    const companyId = new Observable(-1)
        .filter((value, oldValue) => value !== oldValue)
        .each(value => Bus.secure('GetHomeViewId', value));

    listeners.push(Bus.observe('HomeViewId', (dataId, dataName) => {
        id = dataId;
        name = dataName;
        m.redraw();
    }));

    return {
        remove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            companyId(Auth.currentCompanyId);
            if (id === -1) {
                return <Loading class="loading loading-lg" />;
            }
            return (
                <PageBuilder name={name} id={id} />
            );
        }
    };
}
