import m from 'mithril';
import { _ } from 'common/utils/localization';

export function AccountList({ attrs }) {
    const { chosenAccount, modalAction } = attrs;

    const toggleModal = (e, account, type) => {
        e.preventDefault();
        if (type === _`Edit`) {
            modalAction(type);
        } else if (type === 'Status') {
            modalAction(type);
        }
        chosenAccount(account);
    };

    return {
        view({ attrs }) {
            const { accountTypes, accountList, isActive } = attrs;
            return (
                <div class="list-block">
                    <div class="manage-list manage-list-header">
                        <p>{_`Email`}</p>
                        <p>{_`Full name`}</p>
                        <p class="hide-lg">{_`Companies`}</p>
                        <p class="hide-sm">{_`Account Type`}</p>
                    </div>
                    {accountList.length > 0
                        ? (accountList.map((account, i) => (
                            <div key={i} class={`manage-list manage-list-item ${isActive.value ? 'remove' : 'restore'}`}
                                onclick={e => toggleModal(e, account)}>
                                <p>{account.email}</p>
                                <p>{`${account.firstName} ${account.lastName} `}</p>
                                <p class="hide-lg">
                                    {account.companyAccount.map((companyAccount, i) => (account.companyAccount.length !== (i + 1)
                                        ? `${companyAccount.company.name}, ` : companyAccount.company.name))}
                                </p>
                                <p class="hide-sm">{accountTypes.find(type => type.id === account.accountType).name}</p>
                                <button class={`list-button tooltip ${isActive.value ? 'remove' : 'restore'}`}
                                    data-tooltip={_`Edit User`}
                                    onclick={e => toggleModal(e, account, _`Edit`)}>
                                    <i class="icon icon-edit" />
                                </button>
                                <button class={`list-button tooltip ${isActive.value ? 'remove' : 'restore'}`}
                                    data-tooltip={isActive.value ? _`Deactivate User` : _`Activate user`}
                                    onclick={e => toggleModal(e, account, 'Status')}>
                                    <i class={`icon icon-${isActive.value ? 'stop' : 'refresh'}`} />
                                </button>
                            </div>
                        ))) : (
                            <div class="nothing-found">
                                <p>{_`0 accounts found.`}</p>
                            </div>)}
                </div>
            );
        }
    };
}
