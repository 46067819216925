import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { Card } from 'common/components/card';
import { Message } from './message';
import { NewMessage } from './new-message';

const scrollOptions = {
    behaviour: 'smooth',
};
export function ThreadList() {
    return {
        oncreate({ dom }) {
            const unread = dom.querySelector('.unread');
            if (unread) {
                unread.scrollIntoView(scrollOptions);
            }
        },
        onupdate({ dom }) {
            const unread = dom.querySelector('.unread');
            if (unread) {
                unread.scrollIntoView(scrollOptions);
            }
        },
        view({ attrs }) {
            const { data } = attrs;
            const recipients = data.recipients.filter(item => item.userId !== Auth.id);
            return (
                <Card class="thread-list">
                    <h2 slot="header">{data.subject}</h2>
                    <h5 slot="header">{recipients.map(user => `${user.user.firstName} ${user.user.lastName}`).join(', ')}</h5>
                    {data.messages.map(message => <Message key={message.id} data={message} />)}
                    <NewMessage slot="footer" threadId={data.id} />
                </Card>
            );
        }
    };
}
