import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';
import { Modal } from 'common/components/modal';
import { _ } from 'common/utils/localization';
import { ManageLayout } from '../manage-layout';
import { CompanyForm } from './company-form';
import { CompanyList } from './company-list';

export function ManageCompanies() {
    const listeners = [];

    const companyList = new Observable(null)
        .each(m.redraw);

    const isAddingNewCompany = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(value => (value === true ? Bus.secure('GetEmptyCompany') : null));

    const isManagingCompany = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const isEditable = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const isCheckedDeliveryAddress = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const isShowingModal = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(value => (value === false ? isAddingNewCompany(false) : ''))
        .each(value => (value === false ? isManagingCompany(false) : ''))
        .each(m.redraw);

    const company = new Observable(null)
        .each(() => isShowingModal(true));
    listeners.push(Bus.observe('Company', company));

    const options = new Observable([])
        .each(m.redraw);

    const pagination = new Observable({})
        .map((value, oldValue) => ({ ...oldValue, ...value }));

    const filter = {
        searchText: new Observable('').filter((value, oldValue) => value !== oldValue).each(() => filter.getCompanies()),
        isActive: new Observable(true).filter((value, oldValue) =>
            typeof value === 'boolean' && value !== oldValue).each(() => filter.getCompanies()),
        getCompanies: () => Bus.secure('GetCompaniesPaginated', 1, 1, pagination.value.topic, filter.searchText.value, filter.isActive.value)
    };

    const addNewCompany = (e) => {
        e.preventDefault();
        isManagingCompany(false);
        isAddingNewCompany(true);
        isEditable(true);
        Bus.secure('GetEmptyCompany');
    };

    listeners.push(Bus.observe('CompanyListUpdate', () => {
        isShowingModal(false);
        filter.getCompanies();
        Bus.secure('GetMenu', Auth.currentCompanyId);
    }));

    listeners.push(Bus.observe('GetCompaniesPaginated', (companies) => {
        pagination(companies.meta);
        companyList(companies.results);
    }));

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        oncreate() {
            Bus.secure('GetCompaniesPaginated', 1, 1, 'CompanyList', '', true);
        },
        view() {
            if (!companyList.value) {
                return <Loading class="loading loading-lg" />;
            }
            return [
                <ManageLayout key="manage-companies" filter={filter} getMethod="GetCompaniesPaginated" pagination={pagination.value}
                    newObject={addNewCompany} addTooltip={_`Add New Company`} title={_`Manage Companies`} permissions={Auth.isAccountType('admin')}>
                    <CompanyList company={company} companyList={companyList.value} isManagingCompany={isManagingCompany}
                        isEditable={isEditable} filter={filter} />
                </ManageLayout>,
                <Modal key="company-modal" active={isShowingModal} class='modal-lg'
                    title={isAddingNewCompany.value ? _`Add New Company` : _`Edit Company`}>
                    {company.value
                        ? <CompanyForm company={company.value} isEditable={isEditable} isManagingCompany={isManagingCompany.value}
                            isAddingNewCompany={isAddingNewCompany.value} isCheckedDeliveryAddress={isCheckedDeliveryAddress} options={options} />
                        : null}
                </Modal>
            ];
        }
    };
}
