import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';
import { _ } from 'common/utils/localization';
import './main.scss';

export function CloneContent() {
    const listeners = [];

    const cloneTracking = {
        from: new Observable(null).map(e => (e.target ? parseInt(e.target.value, 10) : parseInt(e, 10)))
            .filter((value, oldValue) => value !== oldValue)
            .each(value => Bus.secure('GetCloneData', 'from', value)),
        fromData: new Observable(null).each(m.redraw),
        fromSelected: new Observable(null)
            .map((value, oldValue) => {
                if (value && oldValue) {
                    if (value.type === oldValue.type && value.id === oldValue.id) {
                        return null;
                    }
                }
                return value;
            })
            .each(m.redraw),

        to: new Observable(null).map(e => (e.target ? parseInt(e.target.value, 10) : parseInt(e, 10)))
            .filter((value, oldValue) => value !== oldValue)
            .each(value => Bus.secure('GetCloneData', 'to', value)),
        toData: new Observable(null).each(m.redraw),
        toSelected: new Observable(null)
            .map((value, oldValue) => {
                if (value && oldValue) {
                    if (value.type === oldValue.type && value.id === oldValue.id) {
                        return null;
                    }
                }
                return value;
            })
            .each(m.redraw),
    };
    listeners.push(Bus.observe('CloneData', (type, data) => {
        if (type === 'from') {
            cloneTracking.fromSelected(null);
            cloneTracking.fromData(data);
        } else if (type === 'to') {
            cloneTracking.toSelected(null);
            cloneTracking.toData(data);
        }
    }));

    const companyList = new Observable([])
        .each(m.redraw);
    listeners.push(Bus.observe('CompanyList', (data) => {
        companyList(data);
        cloneTracking.from(data[0].id);
        cloneTracking.to(data[0].id);
    }));

    listeners.push(Bus.observe('RefreshClone', () => {
        Bus.secure('GetCloneData', 'from', cloneTracking.from.value);
        Bus.secure('GetCloneData', 'to', cloneTracking.to.value);
    }));

    const clone = (e) => {
        e.preventDefault();
        const selected = cloneTracking.fromSelected.value;
        if (selected.type === 'module') {
            Bus.secure('CloneModule', selected.id, cloneTracking.to.value);
        } else if (selected.type === 'view') {
            Bus.secure('CloneView', selected.id, cloneTracking.toSelected.value);
        }
    };

    const checkIfSelected = (id, type, target) => {
        if (target === 'from') {
            if (cloneTracking.fromSelected.value) {
                if (cloneTracking.fromSelected.value.type === 'module') {
                    cloneTracking.toSelected(null);
                }
                if (cloneTracking.fromSelected.value.id === id && cloneTracking.fromSelected.value.type === type) {
                    return ' selected';
                }
            }
        }
        if (target === 'to') {
            if (cloneTracking.toSelected.value) {
                if (cloneTracking.toSelected.value === id) {
                    return ' selected';
                }
            }
        }
        return '';
    };

    const checkCloneDisabled = () => {
        if (!cloneTracking.fromSelected.value) {
            return true;
        }
        if (cloneTracking.fromSelected.value.type === 'view' && !cloneTracking.toSelected.value) {
            return true;
        }
        return false;
    };

    const cloneTooltip = () => {
        if (!cloneTracking.fromSelected.value) {
            return _`You need to choose a value\nfrom the left hand side`;
        }
        if (cloneTracking.fromSelected.value.type === 'view' && !cloneTracking.toSelected.value) {
            return _`You need to choose a destination\nmodule from the right hand side`;
        }
        if (cloneTracking.fromSelected.value.type === 'view') {
            return _`Clone view`;
        }
        return _`Clone module`;
    };

    return {
        oncreate() {
            Bus.secure('GetCompanyList');
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            if (companyList.value.length <= 0) {
                return <Loading />;
            }
            return (
                <div class="content columns">
                    <h4 class="column col-12 margin-bottom-header">{_`Copy Modules and Views`}</h4>

                    <div class="column col-5">
                        <div class="form-group center-select-box">
                            <label class="form-label">{_`Company`}</label>
                            <select class="form-select mb-2" onchange={cloneTracking.from}>
                                {companyList.value.map(company => <option key={company.id} value={company.id}>{company.name}</option>)}
                            </select>

                            <div class="select-box">
                                {cloneTracking.fromData.value ? cloneTracking.fromData.value.modules.map(module => (
                                    <ol key={`module-${module.id}`}>
                                        <li class={`d-block mx-1 upper-list-item selectable${checkIfSelected(module.id, 'module', 'from')}`}
                                            onclick={() => cloneTracking.fromSelected({ id: module.id, type: 'module' })}>
                                            {module.name}
                                        </li>
                                        <ol>
                                            {module.views.map(view => (
                                                <li key={`view-${module.id}`}
                                                    class={`d-block ml-2 mx-1 sub-list-item selectable${checkIfSelected(view.id, 'view', 'from')}`}
                                                    onclick={() => cloneTracking.fromSelected({ id: view.id, type: 'view' })}>
                                                    {view.name}
                                                </li>
                                            ))}
                                        </ol>
                                    </ol>
                                )) : null}
                            </div>
                        </div>
                    </div>

                    <div class="column col-2">
                        <div class="center-button">
                            <button class="btn btn-icon tooltip" disabled={checkCloneDisabled()} onclick={clone}
                                data-tooltip={cloneTooltip()}>
                                <i class="icon icon-forward"></i>
                            </button>
                        </div>
                    </div>

                    <div class="column col-5">
                        <div class="form-group">
                            <label class="form-label">{_`Company`}</label>
                            <select class="form-select mb-2" onchange={cloneTracking.to}>
                                {companyList.value.map(company => <option key={company.id} value={company.id}>{company.name}</option>)}
                            </select>

                            <div class="select-box">
                                {cloneTracking.toData.value ? cloneTracking.toData.value.modules.map(module => (
                                    <ol key={`module-${module.id}`}>
                                        <li class={`d-block mx-1 upper-list-item${checkIfSelected(module.id, 'module', 'to')}
                                            ${cloneTracking.fromSelected.value && cloneTracking.fromSelected.value.type === 'module'
                                                ? null : ' selectable'}`}
                                            onclick={() => cloneTracking.toSelected(module.id)}>
                                            {module.name}
                                        </li>
                                        <ol>
                                            {module.views.map(view => (
                                                <li key={`view-${module.id}`} class="d-block ml-2 mt-1 mb-1 sub-list-item">
                                                    {view.name}
                                                </li>
                                            ))}
                                        </ol>
                                    </ol>
                                )) : null}
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
    };
}
