import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { Observable } from 'common/utils/observable';
import { Lock } from 'common/components/assets/lock';
import { PageBuilder } from 'agora/page-builder';
import { ProcessHandling } from 'agora/process-handling';
import { SwitchCompanyModal } from './switch-company-modal';
import './main.scss';

export function SideMenu() {
    const listeners = [];

    const modalActive = new Observable(false)
        .filter(value => typeof value === 'boolean');

    const menu = new Observable(null)
        .each(value => Bus.emitLocal('CompanyCustomization', value.customization))
        .each(value => Bus.emitLocal('CompanyName', value.name))
        .each(() => Bus.emitLocal('Redraw'));

    listeners.push(Bus.observe('Menu', menu));
    listeners.push(Bus.observe('RefreshMenu', () => Bus.secure('GetMenu', Auth.currentCompanyId)));

    const currentActivePageId = new Observable(-1)
        .filter((value, oldValue) => value !== oldValue);
    listeners.push(Bus.listen('ChangeView', (view, data) => {
        if (data && data.id) {
            currentActivePageId(data.id);
        } else {
            currentActivePageId(-1);
        }
    }));

    return {
        oncreate() {
            if (Auth.currentCompanyId > 0) {
                Bus.secure('GetMenu', Auth.currentCompanyId);
            }
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            if (menu.value === null) {
                return null;
            }
            const { customization } = menu.value;
            return [
                <div key={`${menu.value.name}-${menu.value.id}`} class={`side-menu-company ${attrs.class}`}
                    style={`
                            ${customization && customization.sidebarTextColor && customization.sidebarTextColor.trim() !== ''
                            ? `color: ${customization.sidebarTextColor} !important;` : ''}
                            ${customization && customization.sidebarColor && customization.sidebarColor.trim() !== ''
                            ? `background-color: ${customization.sidebarColor} !important;` : ''}
                        `}
                >
                    <div onclick={e => modalActive(true)} class="side-menu-ctitle">
                        {customization && customization.logoURL && customization.logoURL.trim() !== ''
                            ? (
                                <img src={`${window.location.href}file/downloadcompanylogofile/${menu.value.customization.logoURL}`}
                                    alt="logga">
                                </img>
                            ) : <h4>{menu.value.name}</h4>
                        }
                    </div>
                    <div>
                        {menu.value.modules.map((module, j) => (
                            <SideMenuModuleData
                                key={`${menu.value.name}-${menu.value.id}-${j}`}
                                class="side-menu-module"
                                module={module}
                                currentActivePageId={currentActivePageId.value}
                                customization={customization} />
                        ))}
                    </div>
                    {/* <p class="company-text">Ductum By Exceed</p> */}
                </div>,
                // </div>,
                <SwitchCompanyModal key="menu-modal" modalActive={modalActive} />
            ];
        }
    };
}

function SideMenuModuleData() {
    const isViewsVisible = new Observable(false)
        .each(() => Bus.emitLocal('Redraw'));

    const changeView = (e, view) => {
        e.stopPropagation();
        isViewsVisible(true);
        let viewType = PageBuilder;
        if (view.viewType === 1) {
            viewType = ProcessHandling;
        }
        console.log('view.viewType', view.viewType);
        Bus.emitLocal('ChangeView', viewType, { id: view.id, name: view.name });
    };
    return {
        view({ attrs }) {
            const { module, currentActivePageId, customization } = attrs;
            return (
                <div key={`${module.name}-${module.id}`} class={attrs.class}>
                    {module.locked
                        ? (
                            <div class="side-menu-mtitle disabled">
                                {/* <Lock locked={true} width="18" height="18"
                                    color={customization && customization.sidebarTextColor && customization.sidebarTextColor.trim() !== ''
                                        ? customization.sidebarTextColor : null}
                                /> */}
                                <div class="locked">{module.name}</div>
                            </div>
                        )
                        : (
                            <div class={`side-menu-mtitle${currentActivePageId !== -1 && currentActivePageId === module.id ? ' highlighted' : ''}`}
                                onclick={() => isViewsVisible(!isViewsVisible.value)}>
                                <i class={`mb-1 mr-1 icon icon-arrow-${isViewsVisible.value ? 'up' : 'down'}`}></i>
                                <div onclick={e => changeView(e, module)}>{module.name}</div>
                            </div>
                        )
                    }
                    {isViewsVisible.value
                        ? module.views.map((view, i) => (
                            <li
                                key={view.name + i}
                                class={`side-menu-vtitle${currentActivePageId !== -1 && currentActivePageId === view.id ? ' highlighted' : ''}`}
                                onclick={e => changeView(e, view)} >
                                {view.name}
                            </li>
                        )) : null}
                </div>
            );
        }
    };
}
