import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Bus } from 'common/utils/bus';

export function UserList({ attrs }) {
    const listeners = [];
    const selectedCompany = new Observable()
        .filter((value, oldValue) => value !== oldValue)
        .each(value => value && Bus.secure('GetAllUsersForCompany', value))
        .each(attrs.onchange)
        .each(m.redraw);
    const companies = new Observable([])
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue))
        .each(value => value[0] && selectedCompany(value[0].id))
        .each(m.redraw);
    const users = new Observable([])
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue))
        .each(m.redraw);
    listeners.push(Bus.observe('CompanyListForAccount', companies));
    listeners.push(Bus.observe('UsersForCompany', users));
    Bus.secure('GetAllCompaniesForAccount');
    const filter = new Observable(() => true)
        .map(value => (value.target ? value.target.value : value))
        .map((value) => {
            try {
                return (value ? new RegExp(value, 'i') : null);
            } catch {
                // eslint-disable-next-line no-useless-escape
                return `${value}`.replace(/([\[\(\]\)\.-\]])/g, '\\$1');
            }
        })
        .map(value => (value ? (item => item.name.match(value) || item.email.match(value)) : () => true));
    return {
        view({ attrs }) {
            const data = attrs.data.map(item => item.id);
            return (companies.value.length > 0
                ? (
                    <div class="pane">
                        <div class="tile">
                            <select class="form-select" onchange={ev => selectedCompany(parseInt(ev.target.value, 10))}>
                                {companies.value.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                            </select>
                            <input class="form-input" placeholder={_`Search`} oninput={filter} />
                        </div>

                        <ul class="form-select menu" multiple>
                            {users.value
                                .filter(u => u.id !== Auth.id)
                                .filter(u => !data.includes(u.id))
                                .filter(filter.value)
                                .map(c => <ListItem key={c.id} item={c} right={true} onclick={attrs.onselect} />)
                            }
                        </ul>
                    </div>
                )
                : <div class="loading"></div>
            );
        }
    };
}

export function ListItem({ attrs }) {
    const onclick = (item, ev) => {
        if (typeof attrs.onclick === 'function') {
            attrs.onclick(item, ev);
        }
    };
    return {
        view({ attrs }) {
            const left = (attrs.left ? <i class="icon icon-arrow-left"></i> : null);
            const right = (attrs.right ? <i class="icon icon-arrow-right"></i> : null);
            return (
                <li class="menu-item" data-email={attrs.item.email} data-name={attrs.item.name} onclick={ev => onclick(attrs.item, ev)}>
                    {left}
                    <span class="item">{attrs.item.name} ({attrs.item.email})</span>
                    {right}
                </li>
            );
        }
    };
}
