import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { _ } from 'common/utils/localization';
import { TruncateString } from 'common/utils/string-utils';

export function File() {
    return {
        view({ attrs }) {
            const { cellId, data } = attrs;
            let fileName = '';
            fileName = data.substring(data.lastIndexOf('/') + 1);
            fileName = fileName.substring(fileName.lastIndexOf('\\') + 1);
            return (
                <form id={`download-${cellId}`} method="post" action="/file/downloadtablefile">
                    <input type="hidden" id={`download-filePath-${cellId}`} name='filePath' value={data} />
                    <input type="hidden" id={`download-fileName-${cellId}`} name='fileName' value={fileName} />
                    <label class="mr-2">{TruncateString(fileName, 20)}</label>
                    {fileName !== '' ? (
                        <button class='tooltip download-button' disabled={fileName === ''} data-tooltip={_`Download`} type="submit"
                            form={`download-${cellId}`} onclick={e => e.stopPropagation()}>
                            <i class={`icon ${fileName === '' ? '' : 'icon-download'}`}></i>
                        </button>)
                        : null}
                </form>
            );
        }
    };
}

export function FileInput({ attrs }) {
    const { save, tableId, cellId } = attrs;
    const uploadFiles = async (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        formData.append('tableId', tableId);

        const url = `${window.location.href}file/uploadtablefile`;

        const resp = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        const response = await resp.json();

        save(response[0], cellId);
    };
    const files = new Observable(null)
        .each(value => uploadFiles(value))
        .each(() => Bus.emitLocal('Redraw'));
    const options = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*';
    return {
        view({ attrs }) {
            const { disabled, data } = attrs;
            let fileName = '';
            fileName = data.substring(data.lastIndexOf('/') + 1);
            fileName = fileName.substring(fileName.lastIndexOf('\\') + 1);
            return (
                <div>
                    <label class="input-type-file-label" for={`cell-${cellId}`}>
                        {files.value !== null ? files.value[0].name : fileName.trim() !== '' ? fileName : _`Upload a file:`}
                        <i class="icon icon-upload"></i>
                    </label>
                    <input type="file" id={`cell-${cellId}`} name="files" size="1" accept={options}
                        oninput={e => files([...e.srcElement.files])} disabled={disabled} class="input-type-file" />
                </div>
            );
        }
    };
}
