import m from 'mithril';

export function DateField() {
    return {
        view({ attrs }) {
            const { data } = attrs;
            return (
                <div>
                    {data}
                </div>
            );
        }
    };
}

export function DateInput() {
    return {
        view({ attrs }) {
            const { cellId, data, save, disabled } = attrs;
            return (
                <input class="form-input" type="date" id={`cell-${cellId}`} value={data} oninput={e => save(e, cellId)} disabled={disabled} />
            );
        }
    };
}
