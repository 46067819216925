export const ChartTypes = [
    { chart: 'pie', fill: true, displayLegend: true },
    { chart: 'doughnut', fill: true, displayLegend: true },
    { chart: 'polarArea', fill: true, displayLegend: true },
    { chart: 'bar', fill: true, displayLegend: false },
    { chart: 'line', fill: false, displayLegend: false }
];

export const ColorOptions = [
    { rgb: '#C285FF', class: 'color1' },
    { rgb: '#67C0EF', class: 'color2' },
    { rgb: '#FAABAB', class: 'color3' },
    { rgb: '#F380A0', class: 'color4' },
    { rgb: '#F67D7D', class: 'color5' },
    { rgb: '#FFC490', class: 'color6' },
    { rgb: '#FCEA99', class: 'color7' },
    { rgb: '#B3FFC2', class: 'color8' },
    { rgb: '#81EFC3', class: 'color9' },
];
