import 'babel-polyfill';
import m from 'mithril';
import { mainStory } from 'common/utils/storyboard';
import 'common/setup';
import _ from 'underscore';
import { Bus } from 'common/utils/bus';
import { Auth } from 'common/utils/authenticate';
import { Observable } from 'common/utils/observable';

import { PageBuilder } from 'agora/page-builder';
import { App } from './app';

import 'spectre.css/dist/spectre.min.css';
import 'spectre.css/dist/spectre-icons.min.css';
import './main.scss';

const rootElement = document.getElementById('root');

// Auth.autologout.init();
Bus.Init({
    name: 'thea',
    version: '1.0.0',
    hub: {
        url: '/data'
    }
});

Bus.observe('ReceiveMessage', (channel, message) => mainStory.info(`{${channel}} ${message}`));
Bus.observe('Log', (channel, message) => console.log(`{${channel}} ${message}`));
Bus.observe('Message', (channel, message) => console.log(`{${channel}} ${message}`));

Bus.observe('LoginSuccess', m.redraw);
Bus.listen('Redraw', m.redraw);
Bus.observe('Reload', table => mainStory.info(`{reload} ${table}`));
Bus.observe('CurrentCompany', (id, banner) => {
    console.log('id', id);
    console.log('BANNER', banner);
    Auth.updateCurrentCompany(id, banner);
    m.redraw();
});

Bus.observe('SendEmailNotifications', threadId => Bus.secure('SendEmailNotifications', threadId));

mainStory.info(new Date().toLocaleString());

window.inEdit = false;
const listening = new Observable(window.Page)
    .filter((value, oldValue) => value !== oldValue && value && !window.inEdit) // disableState
    .each(val => console.log('listening', val, window.Page))
    .each((value) => {
        Bus.emitLocal('ChangeView', PageBuilder, { id: value.id, name: value.name });
        window.Page = null;
        window.inEdit = false;
    })
    .each(m.redraw);

Object.defineProperty(window, 'Page', {
    get() {
        return listening.value;
    },
    set(val) {
        listening(val);
    }
});

m.mount(rootElement, App);
