import m from 'mithril';
import { Quill } from 'common/components/quill';
import { Eye } from 'common/components/assets/eye';

export function WYSIWYG() {
    return {
        view({ attrs }) {
            const { data, inTable } = attrs;
            if (inTable) {
                return (
                    <div class="eye">
                        <Eye width="34" height="34" />
                    </div>
                );
            }

            return (
                <Quill data={data} disabled={true} />
            );
        }
    };
}

export function WYSIWYGInput({ attrs }) {
    const { cellId, save } = attrs;
    const saveData = (value) => {
        save(value, cellId);
    };
    return {
        view({ attrs }) {
            const { data, disabled } = attrs;
            return (
                <Quill data={data} onchange={saveData} disabled={disabled} />
            );
        }
    };
}
