/* eslint-disable max-len */
import m from 'mithril';
import { Card } from 'common/components/card';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Patterns } from 'common/utils/patterns';
import { ProfileDataField } from './profile-data-field';

export function ProfileInformation({ attrs }) {
    const { save } = attrs;
    const listeners = [];
    const isEditable = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const account = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    listeners.push(Bus.observe('RefreshProfile', () => {
        Bus.secure('GetAccount');
        isEditable(false);
    }));

    const updateOwnAccount = (e) => {
        e.preventDefault();
        Bus.secure('UpdateOwnAccount', account.value);
        isEditable(false);
    };
    const cancel = (e) => {
        e.preventDefault();
        isEditable(false);
        Bus.secure('GetAccount');
    };
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            account(attrs.account);
            const profileDataFields = [
                { label: _`Firstname`, property: 'firstName', data: account.value.firstName, required: true, type: 'text' },
                { label: _`Lastname`, property: 'lastName', data: account.value.lastName, required: true, type: 'text' },
                { label: _`Telephone`, property: 'telephone', data: account.value.telephone, required: false, pattern: Patterns.phoneNumber, type: 'tel', title: _`Enter your 10 digit phone number` },
                { label: _`Email`, property: 'email', data: account.value.email, required: true, pattern: Patterns.email, type: 'email', title: _`Email must include a @ and end with a domain name` }
            ];
            return (
                <Card class={attrs.class}>
                    <div class="profile-header">
                        <h4 class="profile-header-text">{_`Profile Information`}</h4>
                        <div class="position-relative">
                            <div class="card-icon tooltip" data-tooltip={_`Edit`}>
                                {!isEditable.value
                                    ? <i class="icon icon-edit" onclick={() => isEditable(true)} />
                                    : null}
                            </div>
                        </div>
                    </div>
                    <form id="profile-information" class="account-property" slot="content" onsubmit={updateOwnAccount}>
                        {profileDataFields.map((dataField, i) => <ProfileDataField key={i} {...dataField} save={save} editable={isEditable.value} />)}
                        {isEditable.value
                            ? (
                                <div class="manage-buttons">
                                    <button class="cancel-button tooltip" data-tooltip={_`Cancel`} onclick={cancel}>
                                        <i class="icon icon-cross"></i>
                                    </button>
                                    <button type="submit" class="save-button tooltip" data-tooltip={_`Save changes`} form="profile-information">
                                        <i class="icon icon-check"></i>
                                    </button>
                                </div>
                            )
                            : null}
                    </form>
                </Card>
            );
        }
    };
}
