import m from 'mithril';
import { TruncateString } from 'common/utils/string-utils';
import { ColumnHeader } from './column-header';
import { TypeHash } from '../types';
import { AddColumnModal } from './add-column-modal';

export function TableHeader() {
    return {
        view({ attrs }) {
            const { table, inEditMode, selectedColumn, canAddDelete, save } = attrs;
            return (
                <thead>
                    {inEditMode && canAddDelete ? (
                        <tr>
                            <th class="ghost-td" />
                            {table.headers.map(header => (
                                <th key={header.id} class={`main-table-header border move-remove-icons ${TypeHash[header.type].class}`}>
                                    <ColumnHeader table={table} column={header.column} save={save} />
                                </th>
                            ))}
                            <th class="main-table-header borderless">
                                <AddColumnModal table={table} save={save} />
                            </th>
                        </tr>
                    ) : null}
                    <tr>
                        {inEditMode && canAddDelete ? <th class="ghost-td" /> : null}
                        {table.headers.map(header => (
                            <th class={`main-table-header border ${TypeHash[header.type].class} ${inEditMode && canAddDelete ? 'edit-header' : ''}`}
                                key={header.id}>
                                <div onclick={e => (inEditMode && canAddDelete ? selectedColumn(header.column) : null)}>
                                    {TruncateString(header.data, 20)}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead >
            );
        }
    };
}
