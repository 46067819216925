import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';

import './main.scss';

export function Toolbar() {
    const isToolbarOpen = new Observable(false)
        .filter((value, oldValue) => value !== oldValue)
        .each(m.redraw);
    return {
        view({ attrs }) {
            const { toolbarFilters } = attrs;
            return (
                <div class="process-handling-toolbar columns">
                    <div class="form-group column col-3 columns">
                        <div class="col-3 col-sm-12">
                            <label class="form-label">{_`From`}</label>
                        </div>
                        <div class="input-div col-9 col-sm-12">
                            <input class="form-input input-sm" type="date" value={toolbarFilters.from.value} />
                        </div>
                    </div>
                    <div class="form-group column col-3 columns">
                        <div class="col-3 col-sm-12">
                            <label class="form-label">{_`To`}</label>
                        </div>
                        <div class="input-div col-9 col-sm-12">
                            <input class="form-input input-sm" type="date" value={toolbarFilters.to.value} />
                        </div>
                    </div>
                    <div class="form-group column col-3 columns">
                        <div class="input-div col-9 col-sm-12">
                            <input class="form-input input-sm" type="text" placeholder="..." value={toolbarFilters.search.value} />
                        </div>
                        <div class="col-3 col-sm-12">
                            <button class="btn btn-action btn-primary"><i class="icon icon-search"></i></button>
                        </div>
                    </div>
                    <div class="form-group column col-3 text-right">
                        <button class="btn btn-action btn-primary" onclick={() => isToolbarOpen(!isToolbarOpen.value)}>
                            <i class={`icon icon-arrow-${isToolbarOpen.value ? 'up' : 'down'}`}></i>
                        </button>
                    </div>
                    {isToolbarOpen.value ? [
                        <div key="prio" class="column col-3 form-group">
                            <label>{_`Priority`}</label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.highPrio.value}
                                    onclick={() => toolbarFilters.highPrio(!toolbarFilters.highPrio.value)} />
                                <i class="form-icon"></i> {_`High`}
                            </label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.mediumPrio.value}
                                    onclick={() => toolbarFilters.mediumPrio(!toolbarFilters.mediumPrio.value)} />
                                <i class="form-icon"></i> {_`Medium`}
                            </label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.lowPrio.value}
                                    onclick={() => toolbarFilters.lowPrio(!toolbarFilters.lowPrio.value)} />
                                <i class="form-icon"></i> {_`Low`}
                            </label>
                        </div>,
                        <div key="type" class="column col-3 form-group">
                            <label>{_`Type`}</label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.docType.value}
                                    onclick={() => toolbarFilters.docType(!toolbarFilters.docType.value)} />
                                <i class="form-icon"></i> {_`Document`}
                            </label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.webType.value}
                                    onclick={() => toolbarFilters.webType(!toolbarFilters.webType.value)} />
                                <i class="form-icon"></i> {_`Website`}
                            </label>
                        </div>,
                        <div key="status" class="column col-3 form-group">
                            <label>{_`Status`}</label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.nsStatus.value}
                                    onclick={() => toolbarFilters.nsStatus(!toolbarFilters.nsStatus.value)} />
                                <i class="form-icon"></i> {_`Not Started`}
                            </label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.ogStatus.value}
                                    onclick={() => toolbarFilters.ogStatus(!toolbarFilters.ogStatus.value)} />
                                <i class="form-icon"></i> {_`Ongoing`}
                            </label>
                            <label class="form-checkbox">
                                <input type="checkbox" checked={toolbarFilters.tStatus.value}
                                    onclick={() => toolbarFilters.tStatus(!toolbarFilters.tStatus.value)} />
                                <i class="form-icon"></i> {_`Terminated`}
                            </label>
                        </div>
                    ] : null}
                </div>
            );
        }
    };
}

export function CurrentRole() {
    return {
        view({ attrs }) {
            const { currentRole, setCurrentRole } = attrs;
            return (
                <div class="role-switcher">
                    <select class="form-select" value={currentRole} onchange={setCurrentRole}>
                        <option value={1}>{_`Media Owner`}</option>
                        <option value={2}>{_`Coordinator`}</option>
                        <option value={3}>{_`Analyst`}</option>
                    </select>
                </div>
            );
        }
    };
}
