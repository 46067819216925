import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { _ } from 'common/utils/localization';
import { Bus } from 'common/utils/bus';
import { Lock } from 'common/components/assets/lock';
import { ViewRow } from './view-row';

import './main.scss';

export function ModuleRow({ attrs }) {
    return {
        view({ attrs }) {
            const { module, isActive, moduleAmount, toggleModal } = attrs;
            return [
                <div key={`module-${module.id}`} class="columns module-item">

                    <div class="divider custom-divider column col-12"></div>

                    <div class="column col-2 move-div">
                        <button class="tooltip move-up"
                            data-tooltip={`${_`Move`} ${module.name} ${_`up`}`}
                            disabled={module.order === 1}
                            onclick={() => Bus.secure('MoveModule', Auth.currentCompanyId, module.order, module.order - 1)}>
                            <i class="icon icon-arrow-up" />
                        </button>

                        {module.order}

                        <button class="tooltip move-down"
                            data-tooltip={`${_`Move`} ${module.name} ${_`down`}`}
                            disabled={module.order === moduleAmount}
                            onclick={() => Bus.secure('MoveModule', Auth.currentCompanyId, module.order, module.order + 1)}>
                            <i class="icon icon-arrow-down" />
                        </button>
                    </div>

                    <p class="column col-4">{module.name} ({module.id})</p>

                    <p class="column col-4">{module.active ? _`Active` : _`Inactive`}</p>

                    <div class="column col-2">
                        {module.active ? (
                            <button class="list-button add-view-button tooltip"
                                data-tooltip={`${_`Add new view to`} ${module.name}`}
                                onclick={e => toggleModal(e, module, 'AddView')}>
                                <i class="icon icon-plus" />
                            </button>
                        ) : (
                            <button class="list-button transparent-button">
                                <i class="icon icon-plus" />
                            </button>
                        )}

                        <button class="list-button tooltip"
                            data-tooltip={`${_`Edit`} ${module.name}`}
                            onclick={e => toggleModal(e, module, 'EditModule')}>
                            <i class="icon icon-edit" />
                        </button>

                        <button class={'list-button tooltip'}
                            data-tooltip={isActive ? `${_`Deactivate`} ${module.name}` : `${_`Activate`} ${module.name}`}
                            onclick={e => toggleModal(e, module, 'StatusModule')}>
                            <i class={`icon ${isActive ? 'icon-stop' : 'icon-refresh'}`} />
                        </button>

                        <button class={'list-button tooltip tooltip-left'}
                            data-tooltip={module.locked ? `${_`Unlock`} ${module.name}` : `${_`Lock`} ${module.name}`}
                            onclick={e => toggleModal(e, module, 'LockModule')}>
                            <Lock locked={module.locked} width="18" height="18" color="black"/>
                            {/* <i class={`icon ${module.locked ? 'icon-emoji' : 'icon-people'}`} /> */}
                        </button>
                    </div>
                </div>,
                <div key={`views-${module.id}`}>
                    {module.views.length > 0 && isActive
                        ? module.views.map(view => <ViewRow key={`view-row-${view.id}`} view={view} toggleModal={toggleModal} module={module} />)
                        : null
                    }
                </div>
            ];
        }
    };
}
