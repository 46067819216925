import m from 'mithril';
import { TruncateString } from 'common/utils/string-utils';

export function FreeText() {
    return {
        view({ attrs }) {
            const { data, inTable } = attrs;
            return (
                <div>
                    {inTable ? TruncateString(data, 30) : data}
                </div>
            );
        }
    };
}

export function TextInput() {
    return {
        view({ attrs }) {
            const { cellId, data, save, disabled } = attrs;
            return (
                <input class="form-input" type="text" id={`cell-${cellId}`} value={data} oninput={e => save(e, cellId)} disabled={disabled} />
            );
        }
    };
}
