import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { ListItem, UserList } from './user-list';

export function MultiSelect({ attrs }) {
    const data = new Observable(attrs.data, (obs, res) => {
        Object.defineProperty(res, 'push', {
            value(item) {
                if (Array.isArray(res.value)) {
                    res([...res.value, item]);
                }
            },
            enumerable: true,
        });
        Object.defineProperty(res, 'remove', {
            value(item) {
                if (Array.isArray(res.value)) {
                    const array = JSON.parse(JSON.stringify(res.value));
                    const index = array.findIndex(u => u.id === item.id);
                    if (index > -1) {
                        array.splice(index, 1);
                        res(array);
                    }
                }
            },
            enumerable: true,
        });
        Object.defineProperty(res, 'clear', {
            value() {
                if (Array.isArray(res.value)) {
                    res([]);
                }
            },
            enumerable: true,
        });
    })
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue))
        .each(attrs.save);

    return {
        view: ({ attrs }) => (
            <div class="multi-select">
                <UserList data={data.value} onselect={data.push} onchange={data.clear} />
                <div class="pane">
                    <ul class="form-select menu" multiple>
                        {data.value.map(c => <ListItem key={c.id} item={c} left={true} onclick={data.remove} />)}
                    </ul>
                </div>
            </div>
        )
    };
}
