import m from 'mithril';
import { Lock } from 'common/components/assets/lock';
import { Observable } from 'common/utils/observable';
import { cloneObject, compareObjects } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Modal } from 'common/components/modal';
import { ColorList } from './color-list';
import './main.scss';

export function EditHeaderModal({ attrs }) {
    const listeners = [];
    const { selectedColumn, save } = attrs;

    const table = new Observable(attrs.table)
        .filter((value, oldValue) => (oldValue !== value));

    const header = new Observable(table.value.headers.find(header => header.column === selectedColumn.value))
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .map(value => cloneObject(value))
        .map((value, oldValue) => ({ ...oldValue, ...value }));

    const modalIsOpen = new Observable(false)
        .filter(value => (typeof value === 'boolean'))
        .each(m.redraw);

    selectedColumn
        .each(value => header(table.value.headers.find(header => header.column === value)))
        .each(() => modalIsOpen(true))
        .each(m.redraw);

    const saveHeader = (e) => {
        e.preventDefault();

        if (typeof save === 'function') {
            const tempTable = cloneObject(table.value);
            tempTable.headers = tempTable.headers.map(obj => (header.value.id === obj.id ? header.value : obj));
            tempTable.headers.sort((a, b) => ((a.column > b.column) ? 1 : -1));

            save(tempTable);
            modalIsOpen(false);
        }
    };

    const cancel = (e) => {
        modalIsOpen(false);
    };

    const updateHeader = (e, property) => {
        header({ [property]: e.target ? e.target.value : e });
    };

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            table(attrs.table);
            let content = null;
            let title = null;
            if (header.value) {
                const hasOptions = (header.value.type === 2 || header.value.type === 0);
                content = (
                    <div>
                        <div onclick={() => updateHeader(!header.value.locked, 'locked')} class="float-right tooltip tooltip-left"
                            data-tooltip={header.value.locked ? _`Column is locked` : _`Column is not locked`} >
                            <Lock class="c-hand" width="24" height="24"
                                locked={header.value.locked} color={header.value.locked ? '#EB4040' : '#42D89B'} />
                        </div>
                        <div slot="content">
                            <h6 class="m-0">{_`Edit header data`}:</h6>
                            <input class="form-input header-input"
                                value={header.value.data}
                                oninput={e => updateHeader(e, 'data')} />
                            {hasOptions ? <ColorList header={header.value} /> : null}
                        </div>
                        <div slot="footer">
                            <button class="cancel-button tooltip" data-tooltip={_`Close`} onclick={cancel} >
                                <i class="icon icon-cross" />
                            </button>
                            <button class="save-button tooltip" data-tooltip={_`Save & close`} onclick={saveHeader} >
                                <i class="icon icon-check" />
                            </button>
                        </div>
                    </div>
                );

                title = (<div class="table-view header text-center">{_`Header Column`} - {header.value.column + 1}</div>);
            }
            return (
                <Modal class="modal-lg table-modal" active={modalIsOpen} close={cancel} title={title}>
                    {content}
                </Modal>
            );
        }
    };
}
