import m from 'mithril';
import { Observable } from 'common/utils/observable';

export function Modal({ attrs }) {
    const listeners = [];
    const modalActive = new Observable();

    const { active, close } = attrs;
    if (active && active.isObservable) {
        listeners.push(active.subscribe(modalActive));
    }
    const closeModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (active && active.isObservable) {
            active(false);
        } else {
            modalActive(false);
        }
        if (typeof close === 'function') {
            close();
        }
    };
    return {
        onremove() {
            listeners
                .map(listener => (listener && listener.isObservable ? listener.value : listener))
                .filter(listener => typeof listener === 'function')
                .forEach(dispose => dispose());
        },
        view({ attrs, children }) {
            const { title } = attrs;
            const content = children.filter(child => child && (!child.attrs || !['footer'].includes(child.attrs.slot)));
            const footer = children.filter(child => child && child.attrs && child.attrs.slot === 'footer');
            if (!modalActive.value) {
                return null;
            }
            return (
                <div class={`modal ${modalActive.value ? 'active' : ''} ${attrs.class}`} onclick={e => e.stopPropagation()}>
                    <a class="modal-overlay" aria-label="Close" onclick={closeModal}></a>
                    <div class="modal-container">
                        <div class="modal-header">
                            <a class="btn btn-clear float-right" aria-label="Close" onclick={closeModal}></a>
                            <div class="modal-title h5">{title}</div>
                        </div>
                        {content.length ? <div class="modal-body"><div class={`content ${attrs.contentclass}`}>{content}</div></div> : null}
                        {footer.length ? <div class="modal-footer">{footer}</div> : null}
                    </div>
                </div>
            );
        }
    };
}
