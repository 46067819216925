import m from 'mithril';
import { Bus } from 'common/utils/bus';
import LogoImage from '../img/logo.png';

export function Logo() {
    return {
        view({ attrs }) {
            const { homeComponent } = attrs;
            return (
                <div class={`${attrs.location === 'loginpage' ? 'col-12 ' : 'col-2 '} columns`}>
                    <img class={`company-logo${attrs.location === 'loginpage' ? '-loginpage col-6 ' : ' col-2 '}`}
                        src={LogoImage} onclick={e => Bus.emitLocal('ChangeView', homeComponent)} />
                    <div class={`${attrs.location === 'loginpage' ? 'col-6 ' : 'col-2 '} logo-text`}
                        onclick={e => Bus.emitLocal('ChangeView', homeComponent)}>
                        <p class={`logo-text-primary${attrs.location === 'loginpage' ? '-loginpage' : ''}`}>EXCEED</p>
                        <p class={`logo-text-secondary${attrs.location === 'loginpage' ? '-loginpage' : ''}`}>DUCTUM</p>
                    </div>
                </div>
            );
        }
    };
}
