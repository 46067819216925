import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { _ } from 'common/utils/localization';
import { MessageCenter, MessageIcon } from 'agora/messages/message-center';
import { Profile } from 'agora/profile';
import { Home } from 'agora/home';
import { Forum } from 'agora/forum';
import { Article } from 'agora/article';
import { Support } from 'agora/support';
import { ProcessHandling } from 'agora/process-handling';
import { ModuleOverview } from './module-overview';
import { CloneContent } from './clone-content';
import { ManageCompanies } from './manage/manage-companies';
import { ManageUsers } from './manage/manage-users';

import './main.scss';

export function Archon({ attrs }) {
    const listeners = [];
    const main = new Observable(Home)
        .filter((value, oldValue) => (oldValue !== value))
        .each(m.redraw);

    const attributes = new Observable(null);
    listeners.push(Bus.listen('ChangeView', (view, data) => {
        console.log('ChangeView', data);
        main(view.component || view);
        attributes(data);
        attrs.setArticle(null);
        m.redraw();
    }));
    let languageChanged = false;
    listeners.push(Bus.listen('ChangeLanguage', () => {
        languageChanged = true;
    }));

    const createMenuData = () => {
        const items = [
            { name: _`Front Page`, clickFunction: () => Bus.emitLocal('ChangeView', Home, { name: _`Front Page` }) },
            { name: _`My Account`, clickFunction: () => Bus.emitLocal('ChangeView', Profile, { name: _`My Account` }) },
            // { name: _`Process Handling`, clickFunction: () => Bus.emitLocal('ChangeView', ProcessHandling, { name: _`Process Handling` }) },
            { name: _`Manage Users`, clickFunction: () => Bus.emitLocal('ChangeView', ManageUsers, { name: _`Manage Users` }) },
            { name: _`Manage Companies`, clickFunction: () => Bus.emitLocal('ChangeView', ManageCompanies, { name: _`Manage Companies` }) },
            { name: _`Copy Modules and Views`, clickFunction: () => Bus.emitLocal('ChangeView', CloneContent, { name: _`Copy Modules and Views` }) }
        ];
        if (Auth.isAccountType('admin')) {
            items.push({
                name: _`Manage Modules`,
                clickFunction: () => Bus.emitLocal('ChangeView',
                    ModuleOverview, { name: _`Manage Modules` })
            });
        }
        items.push(
            {
                name: 'Messages',
                display: () => <MessageIcon />,
                clickFunction: () => Bus.emitLocal('ChangeView', MessageCenter, { name: 'Messages' })
            },
            { name: 'Forum', clickFunction: () => Bus.emitLocal('ChangeView', Forum, { name: 'Forum' }) },
            { name: 'Support', clickFunction: () => Bus.emitLocal('ChangeView', Support, { name: 'Support' }) },
        );
        Bus.emitLocal('SetMenuData', items);
    };

    return {
        oncreate() {
            createMenuData();
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            if (languageChanged) {
                createMenuData();
                languageChanged = false;
            }
            if (attrs.article) {
                return <Article {...attrs} />;
            }
            return (
                <main.value class="column col-content m-0 p-0" {...attributes.value} />
            );
        }
    };
}
