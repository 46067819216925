import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { ConvertToBase64 } from 'common/utils/string-utils';
import { _ } from 'common/utils/localization';
import { EditUserPasswordField } from './user-data-field';

export function ToggleUserStatus({ attrs }) {
    const { account } = attrs;
    const password = new Observable('');

    const toggleUserStatus = (e) => {
        e.preventDefault();
        Bus.secure('ToggleActiveStatus', account, ConvertToBase64(password.value));
    };
    return {
        view() {
            return (
                <form onsubmit={toggleUserStatus} class="toggle-user-status-block">
                    <h6>{_`Do you want to set user`} {account.firstName} {account.lastName} {_`as`} {account.active ? _`inactive` : _`active`}?</h6>
                    <hr class="admin-validation-block"></hr>
                    <EditUserPasswordField save={password} data={password.value} required={true} class="admin-password"
                        label={_`Admin Password*`} field="your-password" type="password" />
                    <button type="submit" class="save-button tooltip" data-tooltip={_`Confirm`}>
                        <i class="icon icon-check" />
                    </button>
                </form>
            );
        }
    };
}
