import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Bus } from 'common/utils/bus';
import { Pagination } from 'common/components/pagination';
import './main.scss';

export function ManageLayout({ attrs }) {
    const { filter, getMethod } = attrs;
    const changePage = (e, currentPage, topic) => {
        e.preventDefault();
        let direction = e.target.innerText.trim();
        if (direction === _`Previous`) {
            direction = currentPage - 1;
        } else if (direction === _`Next`) {
            direction = currentPage + 1;
        } else {
            direction = parseInt(direction, 10);
        }
        Bus.secure(getMethod, direction, currentPage, topic, filter.searchText.value, filter.isActive.value);
    };

    return {
        view({ attrs, children }) {
            const { pagination, filter, newObject, addTooltip, title, permissions } = attrs;
            return (
                <div class="content">
                    <div class="manage" slot="header">
                        <div class="manage-header"><h4>{title}</h4></div>
                        {permissions ? [
                            <label key="deactivate" class="form-switch">
                                <input type="checkbox" checked={filter.isActive.value} onclick={() => filter.isActive(!filter.isActive.value)} />
                                <i class="form-icon"></i>{filter.isActive.value ? _`Active` : _`Inactive`}
                            </label>,
                            <button key="add" class="add-object-button tooltip" data-tooltip={addTooltip} onclick={e => newObject(e)}>
                                <i class="icon icon-plus" />
                            </button>
                        ] : null}
                        <div class="search-area">
                            <div class="form-group">
                                <i class="icon icon-search form-control-feedback" />
                                <input type="text" class="form-input" placeholder="Search" oninput={e => filter.searchText(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div slot="content" class="children">
                        {children}
                    </div>
                    <div slot="footer">
                        <Pagination {...pagination} onclick={changePage} />
                    </div>
                </div >
            );
        }
    };
}
