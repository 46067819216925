import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _, t } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { Toolbar, CurrentRole } from '../toolbar';
import { AddIssue } from './add-issue';

export function MediaOwner({ attrs }) {
    const data = new Observable(attrs.data)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const addIssue = new Observable(false)
        .filter((value, oldValue) => value !== oldValue)
        .each(m.redraw);

    return {
        view({ attrs }) {
            return (
                <div class="main-content">
                    <div class="columns">
                        <div class="column col-6">
                            <CurrentRole {...attrs} />
                        </div>
                        <div class="column col-6 text-right">
                            <button class="btn btn-primary add-new-issue-btn" onclick={() => addIssue(true)}>{_`add new issue`}</button>
                        </div>
                    </div>
                    <Toolbar {...attrs} />
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{_`Id`}</th>
                                <th>{_`Name`}</th>
                                <th>{_`Type`}</th>
                                <th>{_`Status`}</th>
                                <th>{_`Date`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.value.map(d => (
                                <tr key={d.id} onclick={() => attrs.openProcess(d)}>
                                    <td>{d.id}</td>
                                    <td>{d.name}</td>
                                    <td>{t`${d.typeName}`}</td>
                                    <td>{t`${d.statusName}`}</td>
                                    <td>{new Date(d.creationDate).toISOString().split('T')[0]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {addIssue.value ? <AddIssue addIssue={addIssue} /> : null}
                </div>
            );
        }
    };
}
