/* eslint-disable object-property-newline */
import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { ConvertToBase64 } from 'common/utils/string-utils';
import { _ } from 'common/utils/localization';
import { compareObjects } from 'common/utils/object-utils';
import { Patterns } from 'common/utils/patterns';
import { EditUserPasswordField, EditUserDataField } from './user-data-field';

export function EditExistingOrAddNewUser({ attrs }) {
    const { accountTypes, modalAction, account } = attrs;

    const chosenAccount = new Observable(account)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .map((value, oldValue) => ({ ...oldValue, ...value }));

    const options = new Observable(null)
        .map(value => JSON.parse(JSON.stringify(value)))
        .each(m.redraw);

    const password = new Observable({ adminPassword: '', password: '', passwordConfirm: '' })
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .map((value, oldValue) => ({ ...oldValue, ...value }));

    const saveAccount = (e) => {
        e.preventDefault();
        const list = [];
        options.value.forEach((company) => {
            if (company.checked) {
                list.push(company.id);
            }
        });
        Bus.secure(`${modalAction.value === _`Edit` ? 'Update' : 'Create'}Account`, chosenAccount.value, ConvertToBase64(password.value.adminPassword),
            ConvertToBase64(password.value.password), ConvertToBase64(password.value.passwordConfirm), list);
    };

    const cancel = (e) => {
        e.preventDefault();
        modalAction(null);
    };

    return {
        oninit({ attrs }) {
            const { companies } = attrs;
            const companyOptions = [];
            companies.forEach((co) => {
                const result = account.companyAccount ? account.companyAccount.filter(x => x.company.id === co.id) : 0;
                if (result.length > 0) {
                    companyOptions.push({ name: co.name, id: co.id, checked: true });
                } else {
                    companyOptions.push({ name: co.name, id: co.id, checked: false });
                }
            });
            options(companyOptions);
        },
        view() {
            const editDataFields = [
                { label: `${_`Firstname`}*`, property: 'firstName', data: chosenAccount.value.firstName, required: true },
                { label: `${_`Lastname`}*`, property: 'lastName', data: chosenAccount.value.lastName, required: true, type: 'text' },
                {
                    label: _`Telephone`, property: 'telephone', data: chosenAccount.value.telephone, required: false, pattern: Patterns.phoneNumber,
                    type: 'tel', title: _`Enter your 10 digit phone number`
                },
                {
                    label: `${_`Email`}*`, property: 'email', data: chosenAccount.value.email, required: true, pattern: Patterns.email, type: 'email',
                    title: _`Email must include a @ and end with a domain name`
                },
                {
                    label: `${_`Account Type`}*`, property: 'accountType', data: chosenAccount.value.accountType,
                    displayValue: accountTypes.find(type => type.id === chosenAccount.accountType), required: true, type: 'select',
                    options: accountTypes
                },

            ];
            return (
                <form onsubmit={e => saveAccount(e)}>
                    <div class="add-new-user-block">
                        <div>
                            {editDataFields.map((field, i) => <EditUserDataField key={i} {...field} save={chosenAccount} />)}
                        </div>
                        <div>
                            {chosenAccount.value.accountType !== 0 ? (
                                <EditUserDataField label={`${_`Company`}*`} property="id" type="select" multiple={true} required={true}
                                    data={chosenAccount.value.companyAccount} options={options} />
                            ) : null}
                            <div>
                                <EditUserPasswordField save={password} data={password.value.password} property='password' label={_`New Password`}
                                    field="new-password" type="password" required={modalAction.value === _`Add`} />
                                <EditUserPasswordField save={password} data={password.value.passwordConfirm} property='passwordConfirm'
                                    label={`${_`Confirm Password`}*`} required={modalAction.value === _`Add`} field="confirm-password" type="password" />
                            </div>
                        </div>
                        <hr class="admin-validation-block"></hr>
                        <div class="admin-password">
                            <EditUserPasswordField save={password} data={password.value.adminPassword} property='adminPassword' required={true}
                                label={`${_`Admin Password`}*`} field="your-password" type="password" />
                        </div>
                        <div class="buttons-right">
                            {modalAction.value === _`Edit`
                                ? (
                                    <button class="tooltip cancel-button" data-tooltip={_`Cancel`} onclick={cancel}>
                                        <i class="icon icon-cross" />
                                    </button>
                                ) : null}
                            <button type="submit" class="tooltip save-button" data-tooltip={_`Save`}>
                                <i class="icon icon-check" />
                            </button>
                        </div>
                    </div>
                </form>
            );
        }
    };
}
