import m from 'mithril';
import { _ } from 'common/utils/localization';

export function Link() {
    function isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (e) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }
    const open = (e, url) => {
        e.stopPropagation();
        window.open(url, '_blank');
    };
    return {
        view({ attrs }) {
            const { data } = attrs;
            return (
                <div>
                    {isValidHttpUrl(data)
                        ? <a onclick={e => open(e, data)}>{data}</a>
                        : _`No valid link`}
                </div>
            );
        }
    };
}

export function LinkInput() {
    return {
        view({ attrs }) {
            const { cellId, data, save, disabled } = attrs;
            return (
                <input class="form-input" type="url" id={`cell-${cellId}`} value={data}
                    oninput={e => save(e, cellId)} disabled={disabled} />
            );
        }
    };
}
