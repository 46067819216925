import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { Bus } from 'common/utils/bus';

export function NewMessage() {
    const threadId = new Observable()
        .filter((value, oldValue) => value !== oldValue);
    const message = new Observable('')
        .filter((value, oldValue) => value !== oldValue);
    const submit = (ev) => {
        ev.preventDefault();
        Bus.secure('SendMessage', threadId.value, message.value);
        message('');
    };
    return {
        oncreate({ dom }) {
            const focus = dom.querySelector('.focus');
            if (focus) {
                focus.focus();
            }
        },
        view({ attrs }) {
            threadId(attrs.threadId);
            return (
                <form class="tile-subtitle input" onsubmit={submit}>
                    <input class="message-input focus" placeholder="..." value={message.value} oninput={ev => message(ev.target.value)} />
                    <button type="submit" class="btn icon icon-2x"><i class="icon icon-arrow-right"></i></button>
                </form>
            );
        }
    };
}
