import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { cloneObject } from 'common/utils/object-utils';
import { ConfirmDeletion } from 'common/components/confirm-deletion-modal';
import { tableSorting } from '../extra';

export function AdminRowButtons({ attrs }) {
    const { save } = attrs;

    const table = new Observable(attrs.table)
        .filter((value, oldValue) => (oldValue !== value));

    const moveRow = (e, oldPostion, newPosition) => {
        e.stopPropagation();
        const tempTable = cloneObject(table.value);

        tempTable.cells.forEach((cell) => {
            if (cell.row === oldPostion) {
                cell.row = newPosition;
            } else if (cell.row === newPosition) {
                cell.row = oldPostion;
            }
        });
        tempTable.cells.sort(tableSorting);

        save(tempTable);
    };

    const confirmIsOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'));

    const confirmDelete = (e) => {
        e.stopPropagation();
        confirmIsOpen(true);
    };

    const deleteRow = (row) => {
        const tempTable = cloneObject(table.value);

        for (let i = tempTable.cells.length - 1; i >= 0; i--) {
            if (tempTable.cells[i].row === row) {
                tempTable.cells.splice(i, 1);
            } else if (tempTable.cells[i].row > row) {
                tempTable.cells[i].row -= 1;
            }
        }
        tempTable.cells.sort(tableSorting);

        save(tempTable);
    };
    return {
        view({ attrs }) {
            const { row, rows } = attrs;
            table(attrs.table);

            return (
                <td class="main-table-data">
                    <div class="move-remove-icons">
                        <button class="btn btn-sm" onclick={confirmDelete}>
                            <i class="icon icon-delete delete-row" />
                        </button>
                        <button class='btn btn-sm' disabled={row === 0} onclick={e => moveRow(e, row, row - 1)}>
                            <i class="icon icon-arrow-up" />
                        </button>
                        <button
                            class='btn btn-sm' disabled={row === Math.max(...rows)} onclick={e => moveRow(e, row, row + 1)}>
                            <i class="icon icon-arrow-down" />
                        </button>
                    </div>
                    <ConfirmDeletion modalIsOpen={confirmIsOpen} confirmEvent={() => deleteRow(row)} />
                </td>
            );
        }
    };
}
