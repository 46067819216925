import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { negativeRandomNumber } from 'common/utils/uuid';
import { cloneObject, compareObjects } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { TypeHash } from '../types';
import { AdminRowButtons } from './admin-row-buttons';
import { tableSorting } from '../extra';

export function Row({ attrs }) {
    const { selectedRow, save } = attrs;

    const table = new Observable(attrs.table)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const selectRow = (e, row) => {
        e.stopPropagation();
        selectedRow(row);
    };

    const addRow = () => {
        const tempTable = cloneObject(table.value);

        const row = tempTable.cells.length > 0
            ? Math.max(...tempTable.cells.map(item => item.row).filter((value, index, self) => self.indexOf(value) === index)) + 1
            : 0;
        tempTable.headers.forEach((header) => {
            tempTable.cells.push({
                id: negativeRandomNumber(),
                data: '',
                row,
                column: header.column
            });
        });
        tempTable.cells.sort(tableSorting);

        save(tempTable);
    };

    return {
        view({ attrs }) {
            const { inEditMode, canAddDelete } = attrs;
            table(attrs.table);
            const rows = table.value.cells.map(item => item.row).filter((value, index, self) => self.indexOf(value) === index);
            return [
                rows.map(row => (
                    <tr key={row} onclick={e => selectRow(e, row)}>
                        {inEditMode && canAddDelete
                            ? <AdminRowButtons table={table.value} row={row} rows={rows} save={save} />
                            : null}
                        {table.value.headers.map(header =>
                            table.value.cells.filter(cell => cell.row === row && cell.column === header.column).map((cell) => {
                                const type = new Observable(TypeHash[header.type].component);
                                return (
                                    <td key={cell.id} class='main-table-data'>
                                        <type.value cellId={cell.id} data={cell.data} options={header.options} inTable={true} />
                                    </td>
                                );
                            }))}
                    </tr>
                )),
                inEditMode && canAddDelete
                    ? (
                        <tr>
                            <td class="ghost-td">
                                <button class="btn table-admin-button m-2 col-11"
                                    onclick={addRow}>
                                    {_`Add Row`}
                                </button>
                            </td>
                        </tr>
                    )
                    : null
            ];
        }
    };
}
