import m from 'mithril';
import { Auth } from 'common/utils/authenticate';
import { _ } from 'common/utils/localization';
import { Bus } from 'common/utils/bus';
import { ModuleRow } from './module-row';
import { EmptyList } from './empty-list';

import './main.scss';

export function ModuleList({ attrs }) {
    const { chosenModule, modalAction, chosenView } = attrs;

    const toggleModal = (e, module, type, view) => {
        e.preventDefault();
        modalAction(type);
        if (type === 'AddView') {
            Bus.secure('GetEmptyView');
        } else if (type === 'EditView' || type === 'StatusView') {
            chosenView(view);
        }
        chosenModule(module);
    };

    return {
        view({ attrs }) {
            const { moduleList, isActive, moduleAmount } = attrs;
            return (
                <div>
                    <div class="columns module-list-header">
                        <p class="column col-4 name">{_`Module Name`}</p>
                        <p class="column col-4 status">{_`Status`}</p>
                    </div>

                    {moduleList.length > 0
                        ? moduleList.map(module =>
                            <ModuleRow key={`module-row-${module.id}`} module={module} isActive={isActive.value}
                                moduleAmount={moduleAmount} toggleModal={toggleModal} />)
                        : <EmptyList isActive={isActive} />
                    }
                </div>
            );
        }
    };
}
