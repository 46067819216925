import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _, t } from 'common/utils/localization';
import { uuidv4 } from 'common/utils/uuid';
import './main.scss';

export const TOAST_DELAY = 3000;

export function Toast({ attrs }) {
    const delay = attrs.delay || TOAST_DELAY;
    const listeners = [];
    const toasts = [];
    let timeOutId = 0;

    listeners.push(Bus.listen('CreateToast', (typeOfToast, toastMessage) => {
        const toast = {
            id: uuidv4(),
            text: _`${toastMessage}`,
            type: typeOfToast,
        };
        toasts.push(toast);
        destroyToast();
    }));

    listeners.push(Bus.observe('CreateToast', (items) => {
        items.forEach((item) => {
            const toast = {
                id: uuidv4(),
                text: item.message,
                type: item.type,
            };
            toasts.push(toast);
            destroyToast();
        });
    }));

    function toastRunner() {
        toasts.shift();
        Bus.emitLocal('Redraw');
        resetTimeOut();
    }

    function destroyToast() {
        if (timeOutId === 0) {
            timeOutId = setTimeout(toastRunner, delay);
        }
        Bus.emitLocal('Redraw');
    }

    function removeToast(i) {
        clearTimeout(timeOutId);
        toasts.splice(i, 1);
        Bus.emitLocal('Redraw');
        resetTimeOut();
    }

    function resetTimeOut() {
        if (toasts.length > 0) {
            timeOutId = setTimeout(toastRunner, delay);
        } else {
            timeOutId = 0;
        }
    }

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <div id="toastsContainer">
                    {(toasts.map((toast, i) => (
                        <div key={toast.id} class={`toast-${toast.type} toast`} data-test="toast">
                            <button onclick={() => removeToast(i)} class="btn btn-clear float-right"></button>
                            <p>{t`${toast.text}`}</p>
                        </div>
                    )))}
                </div>
            );
        }
    };
}
