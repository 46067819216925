import m from 'mithril';

export function ConditionChecker() {
    return {
        view({ attrs, children }) {
            const { check } = attrs;
            const content = children.filter(child => child);
            if (content.length && check) {
                return content;
            }
            return null;
        }
    };
}
