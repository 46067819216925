export function setComponentClasses(component, index, view) {
    let classes = '';
    if (component.roundedBorders) {
        classes += ' rounded-borders';
    }
    return classes;
}

export function setComponentCardStyle(component) {
    let style = '';
    if (component.paddingTop && component.paddingTop !== 0) {
        style += ` padding-top: ${component.paddingTop}px !important;`;
    }
    if (component.paddingBottom && component.paddingBottom !== 0) {
        style += ` padding-bottom: ${component.paddingBottom}px !important;`;
    }
    if (component.paddingLeft && component.paddingLeft !== 0) {
        style += ` padding-left: ${component.paddingLeft}px !important;`;
    }
    if (component.paddingRight && component.paddingRight !== 0) {
        style += ` padding-right: ${component.paddingRight}px !important;`;
    }
    style = style.trimStart();
    return style;
}

export const borderOptions = [
    { id: 1, name: 'solid' },
    { id: 2, name: 'dotted' },
    { id: 3, name: 'dashed' },
    { id: 4, name: 'double' },
    { id: 5, name: 'groove' },
    { id: 6, name: 'ridge' },
];

export function setComponentBodyStyle(component, index, view) {
    let style = '';
    if (component.backgroundColor && component.backgroundColor.trim() !== '') {
        style += ` background-color: ${component.backgroundColor} !important;`;
    }

    let borderSize = '0.05rem';
    if (component.borderSize && component.borderSize !== 0) {
        borderSize = `${component.borderSize}px`;
    }
    let borderType = 'solid';
    if (component.borderType && component.borderType !== 0 && borderOptions.find(x => x.id === component.borderType)) {
        borderType = borderOptions.find(x => x.id === component.borderType).name;
    }
    let borderColor = 'rgb(218, 222, 228)';
    if (component.borderColor && component.borderColor.trim() !== '') {
        borderColor = component.borderColor;
    }

    if (component.showBorder && !component.connectingBorders) {
        style += ` border: ${borderSize} ${borderType} ${borderColor} !important;`;
    } else if (component.showBorder && component.connectingBorders) {
        let totalColumns = 0;
        view.components.forEach((comp, i) => {
            totalColumns += comp.columnLg;
        });
        let columns = 0;
        view.components.forEach((comp, i) => {
            if (i <= index) {
                columns += comp.columnLg;
            }
        });
        if (view.components.length > (index + 1) && columns % 12) {
            style += ` border-right: ${borderSize} ${borderType} ${borderColor} !important;`;
        }
        if ((index + 1) > 0 && ((columns - component.columnLg) % 12)) {
            style += ` border-left: ${borderSize} ${borderType} ${borderColor} !important;`;
        }
        if (columns - 12 > 0) {
            style += ` border-top: ${borderSize} ${borderType} ${borderColor} !important;`;
        }
        if (totalColumns > 12) {
            let closestFullRow = 0;
            for (closestFullRow = columns; closestFullRow % 12; closestFullRow++);
            if (closestFullRow < totalColumns) {
                style += ` border-bottom: ${borderSize} ${borderType} ${borderColor} !important;`;
            }
        }
    }

    style = style.trimStart();
    return style;
}
