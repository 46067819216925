import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { negativeRandomNumber } from 'common/utils/uuid';

import QuillEditor from 'quill';
import { Undo } from '../assets/undo';
import { Redo } from '../assets/redo';
import { AddImage } from '../assets/add-image';
import { Modal } from '../modal';

import './style.scss';

export function Quill({ attrs }) {
    const { onchange } = attrs;
    const listeners = [];

    let quill = null;

    const save = (e) => {
        e.preventDefault();
        onchange(e.target.innerHTML);
    };

    const undo = () => {
        quill.history.undo();
    };

    const redo = () => {
        quill.history.redo();
    };

    const imageHandler = async (e) => {
        const { files } = e.srcElement;

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        const resp = await fetch(`${window.location.href}file/uploadquillfile`, {
            method: 'POST',
            body: formData,
        });

        const response = await resp.json();

        // push image url to rich editor.
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, 'image', `${window.location.href}file/downloadquillfile/${response[0]}`, 'api');
    };

    const isModalOpen = new Observable(false);

    let num = 0;
    const internalLinkHandler = async (id, text) => {
        num = negativeRandomNumber();
        console.log('GetViewForInternalLink', parseInt(id, 10), text, num);
        Bus.secure('GetViewForInternalLink', parseInt(id, 10), text, num);
    };
    listeners.push(Bus.observe('ViewInformationForInternalLink', (viewId, name, text, identification) => {
        console.log('ViewInformationForInternalLink', viewId, name, text, identification);
        if (identification === num) {
            const link = `<p style="cursor:pointer;color:rgb(22, 141, 207);" onclick="window.Page = { id: ${viewId}, name: '${name}' }">${text}</p>`;
            if (quill.root.innerHTML === '<p><br></p>') {
                quill.root.innerHTML = link;
            } else {
                quill.root.innerHTML += link;
            }
        }
    }));

    let disableState = false;
    const toggleQuill = (disabled, data) => {
        if (disabled !== disableState) {
            if (disabled) {
                quill.disable();
            } else {
                quill.enable();
            }
            disableState = disabled;
        }
    };

    return {
        oncreate({ dom, attrs }) {
            const { disabled, data } = attrs;
            const toolbar = dom.querySelector('.toolbar');
            const editor = dom.querySelector('.editor');
            quill = new QuillEditor(editor, {
                modules: {
                    toolbar,
                    history: {
                        delay: 1000,
                        maxStack: 100,
                        userOnly: false
                    },
                    clipboard: { matchVisual: false }
                },
                theme: 'snow',
            });
            quill.root.innerHTML = data || '';

            toggleQuill(disabled);
        },
        onupdate({ attrs }) {
            const { disabled } = attrs;
            toggleQuill(disabled);
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { disabled, index } = attrs;
            return (
                <div>
                    <div class={`${disabled ? 'd-none' : ''}`}>
                        <div class="toolbar">
                            <span className="ql-formats">
                                <select className="ql-header" />
                            </span>
                            <span className="ql-formats">
                                <button className="ql-bold" />
                                <button className="ql-italic" />
                                <button className="ql-underline" />
                                <button className="ql-strike" />
                                <select className="ql-color" />
                            </span>
                            <span className="ql-formats">
                                <button className="ql-list" value="ordered" />
                                <button className="ql-list" value="bullet" />
                                <select className="ql-align" />
                                <button className="ql-blockquote" />
                            </span>
                            <span className="ql-formats">
                                <button className="ql-undo" onclick={() => undo()}>
                                    <Undo />
                                </button>
                                <button className="ql-redo" onclick={() => redo()}>
                                    <Redo />
                                </button>
                                <button className="ql-clean" />
                            </span>
                            <span className="ql-formats">
                                <label for={`file-input${index || ''}`} class="file-input-icon">
                                    <AddImage width="18" height="18" />
                                </label>
                                <input id={`file-input${index || ''}`} class="ql-image" className="ql-image" type="file"
                                    onchange={e => imageHandler(e)} />
                            </span>
                            <span className="ql-formats">
                                <label for={`internal-link-${index || ''}`} onclick={() => isModalOpen(true)}>
                                    IL
                                </label>
                            </span>
                            <span className="ql-formats">
                                <button className="ql-video" />
                                <button className="ql-link" />
                            </span>
                            <span className="ql-formats">
                                <button className="ql-formula" />
                                <button className="ql-code-block" />
                            </span>
                        </div>
                    </div>
                    <div class="editor" onfocusout={e => save(e)}></div>
                    <InternalLinkModal internalLinkHandler={internalLinkHandler} isModalOpen={isModalOpen} />
                </div>
            );
        }
    };
}

function InternalLinkModal({ attrs }) {
    const listeners = [];
    const text = new Observable('');
    const id = new Observable(1);
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { internalLinkHandler, isModalOpen } = attrs;
            return (
                <Modal active={isModalOpen}>
                    text:
                    <input type="text" oninput={e => text(e.target.value)} value={text.value} />
                    view id:
                    <input type="number" oninput={e => id(e.target.value)} value={id.value} />
                    <button onclick={() => {
                        internalLinkHandler(id.value, text.value);
                        isModalOpen(false);
                    }}>
                        insert
                    </button>
                </Modal>
            );
        }
    };
}
