import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { _ } from 'common/utils/localization';
import { Home } from 'agora/home';
import { Modal } from '../modal';

export function SwitchCompanyModal({ attrs }) {
    const { modalActive } = attrs;
    const listeners = [];

    const companySearchString = new Observable('')
        .map(e => (e.target ? e.target.value : e))
        .filter((value, Oldvalue) => value !== Oldvalue)
        .each(value => Bus.secure('SearchAllCompaniesByName', value));

    const companies = new Observable([])
        .each(() => Bus.emitLocal('Redraw'));

    listeners.push(Bus.observe('SwitchCompanyList', companies));
    listeners.push(Bus.observe('RefreshSwitchCompanyList', () => Bus.secure('SearchAllCompaniesByName', companySearchString.value)));

    const changeCompany = (e, id) => {
        e.preventDefault();
        Bus.secure('GetMenu', id);
        modalActive(false);
        companySearchString('');
        Bus.emitLocal('ChangeView', Home);
    };

    return {
        oncreate() {
            Bus.secure('SearchAllCompaniesByName', companySearchString.value);
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <Modal active={modalActive} title={_`Switch Company`}>
                    <input class="form-input mb-2" placeholder={_`Search customer by name or org. number...`}
                        oninput={e => companySearchString(e)} value={companySearchString.value}>
                    </input>
                    <div>
                        <div class="switch-company-list switch-company-list-header">
                            <p>{_`Company Name`}</p>
                            <p>{_`Org. Number`}</p>
                        </div>
                        {companies.value.length > 0
                            ? companies.value.map(company => (
                                <div key={`${company.name}-${company.id}`} onclick={e => changeCompany(e, company.id)}
                                    class={`switch-company-list switch-company-list-item ${company.isActive ? 'remove' : 'restore'}`}>
                                    <p>{company.name}</p>
                                    <p>{company.organisationNumber}</p>
                                </div>
                            )) : (
                                <div class="nothing-found">
                                    <p>{_`0 companies found.`}</p>
                                </div>
                            )}
                    </div>
                </Modal>
            );
        }
    };
}
