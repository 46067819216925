import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Chart as ChartIcon } from 'common/components/assets/chart';
import { Table as TableIcon } from 'common/components/assets/table';
import { EditNote } from 'common/components/assets/edit-note';
import { Table, Chart, Editor, Articles, QA, Article, Accordion, AnchortagMenu, iFrame } from './component-types';

export function AddComponentToolbar() {
    return {
        view({ attrs }) {
            const { createComponent } = attrs;
            return (
                <div class="add component-toolbar">

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip tooltip-right" data-tooltip={_`Add a table component`}
                            onclick={() => createComponent(Table)} data-test="add-table">
                            <TableIcon />
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add a chart component`}
                            onclick={() => createComponent(Chart)} data-test="add-chart">
                            <ChartIcon />
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an editor component`}
                            onclick={() => createComponent(Editor)} data-test="add-editor">
                            <EditNote />
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an articles component`}
                            onclick={() => createComponent(Articles)} data-test="add-articles">
                            <i class="icon icon-emoji"></i>
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add a Q&A component`}
                            onclick={() => createComponent(QA)} data-test="add-qa">
                            <i class="icon icon-message"></i>
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an Article card component`}
                            onclick={() => createComponent(Article)} data-test="add-article">
                            <i class="icon icon-message"></i>
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an Accordion component`}
                            onclick={() => createComponent(Accordion)} data-test="add-accordion">
                            <i class="icon icon-arrow-down"></i>
                        </button>
                    </div>

                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an AnchortagMenu component`}
                            onclick={() => createComponent(AnchortagMenu)} data-test="add-anchortag-menu">
                            <i class="icon-anchor"></i>
                        </button>
                    </div>
                    {/*
                    <div class="divider-vert add-toolbar"></div>

                    <div class="form-group form-group-margin">
                        <button class="edit-view-button btn-icon tooltip" data-tooltip={_`Add an iFrame component`}
                            onclick={() => createComponent(iFrame)} data-test="add-iframe">
                            <i class="icon icon-people"></i>
                        </button>
                    </div>
                    */}

                </div>
            );
        }
    };
}
