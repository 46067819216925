import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Home } from './home';
import { Profile } from './profile';
import { MessageCenter, MessageIcon } from './messages/message-center';
import { ProcessHandling } from './process-handling';
import { Forum } from './forum';
import { Article } from './article';
import { Support } from './support';

import './main.scss';

export function Agora({ attrs }) {
    const listeners = [];
    const main = new Observable(Home)
        .filter((value, oldValue) => (oldValue !== value))
        .each(() => m.redraw());
    const attributes = new Observable(null);
    listeners.push(Bus.listen('ChangeView', (view, data) => {
        console.log('ChangeView', data);
        main(view.component || view);
        attributes(data);
        attrs.setArticle(null);
        m.redraw();
    }));
    return {
        oncreate() {
            const items = [
                { name: _`Front Page`, clickFunction: () => Bus.emitLocal('ChangeView', Home, { name: _`Front Page` }) },
                { name: _`My Account`, clickFunction: () => Bus.emitLocal('ChangeView', Profile, { name: _`My Account` }) },
                // { name: _`Process Handling`, clickFunction: () => Bus.emitLocal('ChangeView', ProcessHandling, { name: _`Process Handling` }) },
                {
                    name: 'Messages',
                    display: () => <MessageIcon />,
                    clickFunction: () => Bus.emitLocal('ChangeView', MessageCenter, { name: 'Messages' })
                },
                { name: 'Forum', clickFunction: () => Bus.emitLocal('ChangeView', Forum, { name: 'Forum' }) },
                { name: 'Support', clickFunction: () => Bus.emitLocal('ChangeView', Support, { name: 'Support' }) },
            ];
            Bus.emitLocal('SetMenuData', items);
            Bus.emitLocal('ChangeView', Home);
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            if (attrs.article) {
                return <Article {...attrs} />;
            }
            return (
                <main.value class="column col-content m-0 p-0" {...attributes.value} />
            );
        }
    };
}
