import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Bus } from '../../utils/bus';
import './main.scss';

export const Pagination = {
    oninit({ attrs }) {
        this.click = attrs.onclick || ((ev, current, topic) => {
            ev.preventDefault();
            const dir = ev.target.innerText.trim();
            Bus.emit('page', dir, current, topic);
        });
    },
    view({ attrs }) {
        const { currentPage, pageCount, topic } = attrs;
        const prev = currentPage === 1 ? 'disabled' : '';
        const next = currentPage === pageCount ? 'disabled' : '';
        const list = Array(5)
            .fill(0)
            .map((_, ind) => currentPage + ind - (currentPage < 4 ? currentPage - 2 : currentPage > pageCount - 3 ? 4 - pageCount + currentPage : 2))
            .filter(num => num > 0 && num <= pageCount);
        let first = 0;
        if (list[first] > 2) {
            list.unshift('...');
            ++first;
        }
        if (list[first] > 1) {
            list.unshift(1);
        }
        let last = list.length - 1;
        if (list[last] < pageCount - 1) {
            list.push('...');
            --last;
        }
        if (list[last] < pageCount) {
            list.push(pageCount);
        }
        if (list.length === 0) {
            return null;
        }
        return (
            <ul class="pagination">
                <li class={`page-item ${prev}`}>
                    <a href="#" tabindex={prev === 'disabled' ? -1 : null} onclick={ev => this.click(ev, currentPage, topic)}>{_`Previous`}</a>
                </li>
                {list.map((num, i) => <PageItem key={i} page={num} active={currentPage} topic={topic} onclick={this.click}></PageItem>)}
                <li class={`page-item ${next}`}>
                    <a href="#" tabindex={prev === 'disabled' ? -1 : null} onclick={ev => this.click(ev, currentPage, topic)}>{_`Next`}</a>
                </li>
            </ul>
        );
    }
};

const PageItem = {
    view({ attrs }) {
        const { page, active, topic } = attrs;
        if (page === '...') {
            return (
                <li class="page-item"><span>{page}</span></li>
            );
        }
        return (
            <li class={`page-item ${active === page ? 'active' : ''}`}>
                <a href={`#page/${page}`} onclick={ev => attrs.onclick(ev, active, topic)}>{page}</a>
            </li>
        );
    }
};
