import m from 'mithril';
import { _ } from 'common/utils/localization';
import { CompanyItem } from './company-item';

export function CompanyList() {
    return {
        view({ attrs }) {
            const { companyList, company, isManagingCompany, isEditable, filter } = attrs;
            return (
                <div>
                    <div class="manage-list manage-list-header">
                        <p>{_`Company Name`}</p>
                        <p>{_`Org. Number`}</p>
                        <p class="hide-sm">{_`City`}</p>
                        <p class="hide-lg">{_`Country`}</p>
                    </div>
                    {companyList.length > 0
                        ? companyList.map((companyItem, i) => (
                            <CompanyItem key={i} companyItem={companyItem} company={company} isEditable={isEditable}
                                isActive={filter.isActive.value} isManagingCompany={isManagingCompany} />
                        )) : (
                            <div class="nothing-found">
                                <p>{_`0 companies found.`}</p>
                            </div>
                        )}
                </div>
            );
        }
    };
}
