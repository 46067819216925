/* eslint-disable import/no-extraneous-dependencies */
import m from 'mithril';
import { debounce } from 'underscore';
import { _ } from 'common/utils/localization';
import { Auth } from 'common/utils/authenticate';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';
import { Modal } from 'common/components/modal';
import { ThreadList } from './thread-list';
import { Thread } from './thread';
import { NewThread } from './new-thread';

import './messages.scss';

const MarkThreadAsRead = debounce(id => Bus.secure('MarkThreadAsRead', id), 500);
export function MessageCenter() {
    const listeners = [];
    const selected = new Observable(null)
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue))
        .each(value => value && MarkThreadAsRead(value.id));
    const threads = new Observable([])
        .filter((value, oldValue) => JSON.stringify(value) !== JSON.stringify(oldValue))
        .each((value) => {
            if (selected.value == null) {
                selected(value[0]);
                return;
            }
            const thread = value.find(item => item.id === selected.value.id);
            if (thread) {
                selected(thread);
            }
        })
        .each(() => m.redraw());
    listeners.push(Bus.observe('MessageThreads', threads));
    listeners.push(Bus.observe('ReloadMessages', () => Bus.secure('GetThreadsForUser')));
    if (Auth.id > 0) {
        Bus.secure('GetThreadsForUser');
    }
    const select = (ev, thread) => selected(thread);
    const modal = new Observable();
    const addThread = () => {
        modal(true);
    };
    return {
        onremove() {
            listeners
                .filter(item => typeof item === 'function')
                .forEach(dispose => dispose());
        },
        view() {
            return (
                <div class="messages">
                    <Card class="message-list">
                        <h5 slot="header">
                            {_`Messages`}
                            <button class="btn icon" onclick={addThread}><i class="message-add icon icon-plus"></i></button>
                        </h5>
                        {threads.value
                            .map(thread => <Thread
                                key={`thread_${thread.id}`}
                                selected={selected.value.id}
                                data={thread} onclick={ev =>
                                    select(ev, thread)}
                                />)
                        }
                    </Card>
                    <Card class="message-thread">
                        {selected.value
                            ? <ThreadList key={selected.value.id} data={selected.value} />
                            : (
                                <div class="empty">
                                    <div class="empty-icon">
                                        <i class="icon icon-4x icon-people"></i>
                                    </div>
                                    <p class="empty-title h5">{_`No messages available`}</p>
                                    <p class="empty-subtitle">{_`Click the button to start a conversation.`}</p>
                                    <div class="empty-action">
                                        <button class="btn btn-primary" onclick={addThread}>{_`Send a message`}</button>
                                    </div>
                                </div>
                            )
                        }
                    </Card>
                    <Modal title={_`New Thread`} class="modal-lg" active={modal}><NewThread close={() => modal(false)} /></Modal>
                </div>
            );
        },
    };
}

export function MessageIcon() {
    const listeners = [];
    const threadCount = new Observable({ total: 0, unread: 0 })
        .each(() => m.redraw());
    listeners.push(Bus.observe('MessageThreadCount', threadCount));
    listeners.push(Bus.observe('ReloadMessages', () => Bus.secure('GetThreadCountForUser')));
    if (Auth.id > 0) {
        Bus.secure('GetThreadCountForUser');
    }
    return {
        onremove() {
            listeners
                .filter(item => typeof item === 'function')
                .forEach(dispose => dispose());
        },
        view() {
            const badge = threadCount.value.unread > 0 ? 'badge' : '';
            return (
                // <i class="icon icon-2x icon-message">
                //     <span class={`count ${badge}`} data-badge={threadCount.value.unread}>{threadCount.value.total}</span>
                // </i>
                <span class={`count ${badge}`} data-badge={threadCount.value.unread}>{_`Messages`}</span>
            );
        },
    };
}
