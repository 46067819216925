import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';
// eslint-disable-next-line import/no-cycle
import { Board } from './board';

// Demo not working as intended
export function Thread() {
    const listeners = [];

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { changeContent } = attrs;
            return (
                <div class="columns forum-thread-block">
                    <div class="column col-12 thread-header">
                        <button class="btn btn-sm btn-action" onclick={() => changeContent(Board)}><i class="icon icon-back"></i></button>
                        <h6>Skapa en bra användarupplevelse för synskadade</h6>
                    </div>
                    <Card class="column col-12 post">
                        <div slot="header" class="post-header">
                            <div class="float-left">Båstad Kommun - Jakob Olsson</div>
                            <div class="float-right">2021-11-29 14:34</div>
                        </div>
                        <div slot="content">
                            Hej! Nu jag gått igenom stora delar av utbildningen och tycker det har varit väldigt lärorikt.
                            Jag har dock fastnat lite i det här med en bra användarupplevelse för synskadade. Hur tänker ni kring detta?
                        </div>
                    </Card>
                    <Card class="column col-12 post">
                        <div slot="header" class="post-header">
                            <div class="float-left">Helsingborg Kommun - Marie Eskilsson</div>
                            <div class="float-right">2021-11-29 14:52</div>
                        </div>
                        <div slot="content">
                            Väldigt bra fråga Båstad! Vi har liknande funderingar i Helsingborg. Jag följer tråden!
                        </div>
                    </Card>
                    <Card class="column col-12 post">
                        <div slot="header" class="post-header">
                            <div class="float-left">Exceed Consulting - Armando Cordero</div>
                            <div class="float-right">2021-11-30 08:21</div>
                        </div>
                        <div slot="content">
                            Hej Jakob! Vad kul att gillar vår utbildning. Det vi brukar säga är att det absolut viktigaste är att göra
                            hemsidan tillgänglig för skärmläsare. På så sätt så kan besökaren ta del av informationen för att audiovisuellt sätt.
                            Vi kommer att uppdatera utbildningen till slutet av nästa vecka med en ny sida om just hörselskada.
                        </div>
                    </Card>
                    <Card class="column col-12 post">
                        <div slot="header" class="post-header">
                            <div class="float-left">Jakob Olsson - Båstad Kommun</div>
                            <div class="float-right">2021-12-01 14:34</div>
                        </div>
                        <div slot="content">
                            Det låter som en väldigt bra idé! Hur blir det dock med bilder och skärmläsare?
                        </div>
                    </Card>
                    <Card class="column col-12 post">
                        <div>
                            <input class="forum-thread-input"/>
                            <button class="btn btn-primary float-right">{_`Send`}</button>
                        </div>
                    </Card>
                </div>
            );
        }
    };
}
