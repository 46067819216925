import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';

import './main.scss';

export function Article({ attrs }) {
    const listeners = [];

    const article = new Observable(attrs.article)
        .filter((value, oldValue) => !compareObjects(value, oldValue));

    return {
        oncreate() {
            Bus.secure('GetAccount');
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { setArticle } = attrs;
            article(attrs.article);
            return (
                <div class="content">
                    <div class="columns">
                        <button class="btn btn-sm btn-primary float-left" onclick={() => setArticle(null)}><i class="icon icon-arrow-left"></i></button>
                        <div class="column col-8">
                            <img class="article-img" src="https://www.w3.org/blog/wp-content/uploads/2018/09/wcag_blog-1.png" />
                            <h4>Förstå skillnaderna mellan WCAG 2.2 och WCAG 3.0</h4>
                            <h6>12 December 2021 - 7 min läsning</h6>
                            <div><div class="bg-success">webbtillgänglighet</div></div>
                            <p>
                                Digital tillgänglighet kan vara överväldigande till en början. Lyckligtvis publicerar World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG), en uppsättning officiella rekommendationer som ger webbansvariga de verktyg de behöver för att tillgodose personer med funktionshinder. <br />
                                Inom de närmaste månaderna kommer W3C officiellt att publicera WCAG version 2.2. Den senaste versionen av dokumentet kommer att innehålla 9 ytterligare framgångskriterier, och webbplatser som överensstämmer med standarderna kan skapa mer tillgängligt innehåll för personer med funktionsnedsättning. W3C förbereder dock också en annan revidering av WCAG: Version 3.0 förväntas introducera en ny betygsskala för tillgänglighet, tillsammans med ett antal andra större förändringar. <br />
                                Båda dokumenten tjänar viktiga syften, men om du är ny på tillgänglighet kan denna utveckling verka förvirrande. I den här artikeln ska vi försöka ge lite klarhet genom att förklara de stora skillnaderna mellan WCAG version 2.2 och 3.0. <br />
                            </p>
                            <h5>Både WCAG 2.2 och WCAG 3.0 är utkast, inte officiella rekommendationer.</h5>
                            <p>
                                I skrivande stund innehåller WCAG 2.1 W3C:s officiella rekommendationer för digital tillgänglighet. Även om WCAG 2.2 och 3.0 innehåller nya riktlinjer, fungerar de fortfarande på utkast, och de kan ändras avsevärt före officiell publicering.<br />
                                Innan de publicerar nya rekommendationer söker W3C input från allmänheten och tillgänglighetsexperter. Denna "utkast"-perioden och flera arbetsutkast kan införas innan riktlinjerna är slutgiltiga. WCAG-författare kan behöva göra betydande ändringar i formulering, klassificering av riktlinjer och dokumentstruktur.<br />
                                Varje dokument har sin egen publiceringstidslinje:<br />
                                Författarna planerar att publicera WCAG 2.2 inom de närmaste månaderna. Releasen var ursprungligen planerad till slutet av 2021, men WCAG 2.2 kan bli officiell vägledning i början av 2022. I skrivande stund släpptes den senaste versionen av WCAG 2.2-arbetsutkastet den 21 maj 2021.<br />
                            </p>
                            <p>
                                W3C har inte meddelat något planerat publiceringsdatum för WCAG 3.0. I skrivande stund släpptes den senaste versionen av WCAG 3.0-arbetsutkastet den 8 juni 2021.<br />
                                W3C publicerar också rekommendationer för ändringar av arbetsutkast. Dessa rekommendationer införlivas ofta i nästa arbetsutkast. Läsare kan hitta dessa rekommendationer länkade överst i varje arbetsutkast.<br />
                            </p>
                            <p>
                                Så varför arbetar W3C på två versioner av WCAG samtidigt? Kort sagt har dokumenten väldigt olika tidslinjer. Som en större översyn av WCAG-ramverket kommer version 3.0 sannolikt att kräva många arbetsutkast och en längre period för offentlig input.<br />
                                WCAG 2.2 är mycket mer begränsad i omfattning. Det förväntas lägga till nya framgångskriterier till riktlinjerna, men det är helt bakåtkompatibelt med WCAG 2.1 och 2.0. Med andra ord, riktlinjer från tidigare versioner av dokumentet visas ordagrant (ord-för-ord) i WCAG 2.2.<br />
                            </p>
                            <p>
                                Läs: <a href="https://www.boia.org/blog/wcag-3.0-may-introduce-new-rating-scale-for-accessibility">WCAG 3.0 kan introducera ny betygsskala för tillgänglighet</a>
                            </p>
                            <h5>WCAG 3.0 kommer inte att fasa ut WCAG 2.2 eller andra tidigare versioner</h5>
                            <p>
                                I det här sammanhanget betyder "fasa ut" att förklara tidigare versioner av ett dokument som föråldrade. W3C kommer att fortsätta att rekommendera överensstämmelse med WCAG 2.2 efter lanseringen av WCAG 3.0 — precis som organisationen för närvarande rekommenderar överensstämmelse med alla 2.X-versioner av WCAG.
                            </p>
                            <p>
                                För webbplatsägare är det en viktig skillnad; en webbplats som uppfyller framgångskriterierna i WCAG 2.2 kan anses vara rimligt tillgänglig för de flesta användare, och den officiella utgåvan av WCAG 3.0 kommer inte att ändra på det.<br />
                                Dessutom kommer webbplatser som följer WCAG 2.2 (eller tidigare versioner) att vara väl positionerade för att uppnå överensstämmelse med WCAG 3.0. Många av riktlinjerna är i huvudsak identiska – men WCAG 3.0:s föreslagna betygsskala kommer att förändra sättet som webbplatser mäter sin tillgänglighet. Författarna till WCAG 3.0 har för avsikt att göra dokumentet mer begripligt och mycket mer omfattande än WCAG 2.X.<br />
                            </p>
                            <p>
                                Läs: <a href="https://www.boia.org/blog/what-content-creators-should-know-about-wcag-3.0">Vad innehållsskapare bör veta om WCAG 3.0</a>
                            </p>
                            <p>
                                Webbansvariga bör följa den senaste officiella versionen av WCAG
                            </p>
                            <p>
                                WCAG-arbetsutkast är oerhört viktiga för tillgänglighetsproffs, eftersom W3C:s författarprocess tillåter människor att förespråka förändringar och förbereda sig för ny vägledning före det officiella publiceringsdatumet. Men för organisationer och enskilda innehållsskapare är det säkraste sättet att följa officiella WCAG-rekommendationer.<br />
                                För närvarande är WCAG 2.1 den mest erkända standarden för digital tillgänglighet. När 2.2 tillkännages bör webbplatser som redan överensstämmer med WCAG 2.1 utan större problem kunna göra de nödvändiga förbättringarna för att överensstämma med de nya framgångskriterierna – och när WCAG 3.0 blir standard, ska webbplatser som är allmänt tillgängliga inte behöva göra enorma förändringar för att följa den nya överensstämmelsemodellen.<br />
                                Bureau of Internet Accessibility erbjuder ett antal resurser för att lära sig om principerna för tillgänglighet och skapa bättre innehåll. Om du är ny på digital tillgänglighet rekommenderar vi att du besöker vår Compliance Roadmap för att ta de första stegen.<br />
                            </p>
                            <p>
                                - Peter Nilsson,<br />
                                Testledare, Exceed<br />
                            </p>
                        </div>
                        <div class="column col-4">
                            <h4>Senaste nytt!</h4>
                            <h6>Läs fler artiklar om det senaste inom webbtillgänglghet och användbarhet</h6>
                            <div class="divider"></div>
                            <h6>Relaterade artiklar:</h6>
                            <div>
                                <ol>
                                    <li>Svenskarna med funktionsnedsättning och internet 2019</li>
                                    <li>”Skärpning, Arbetsförmedlingen – negligera inte det digitala utanförskapet”</li>
                                    <li>En översikt över de lagar, regler och överenskommelser som har koppling till digital tillgänglighet</li>
                                    <li>Tillgänglighetsdirektivet blir lag 2022 – är din organisation redo?</li>
                                </ol>
                            </div>
                            <div class="divider"></div>
                            <h6>Mest lästa artiklar:</h6>
                            <div>
                                <ol>
                                    <li>Svenskarna med funktionsnedsättning och internet 2019</li>
                                    <li>”Skärpning, Arbetsförmedlingen – negligera inte det digitala utanförskapet”</li>
                                    <li>En översikt över de lagar, regler och överenskommelser som har koppling till digital tillgänglighet</li>
                                    <li>Tillgänglighetsdirektivet blir lag 2022 – är din organisation redo?</li>
                                </ol>
                            </div>
                            <div class="divider"></div>
                            <h6>Populära kategorier:</h6>
                            <div>
                                <ol>
                                    <li>UX Design</li>
                                    <li>Rapport</li>
                                    <li>Teknik</li>
                                    <li>Lagar och regler</li>
                                </ol>
                            </div>
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>
            );
        }
    };
}
