import m from 'mithril';
import { Quill } from 'common/components/quill';

export function TextEditor({ attrs }) {
    const { component, saveComponent } = attrs;
    const listeners = [];

    const saveData = (value) => {
        saveComponent(value, component.id, 'editor');
    };
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { component, inEditMode, index } = attrs;
            return (
                <Quill data={component.editor} disabled={!inEditMode} onchange={saveData} index={index} />
            );
        }
    };
}
