import m from 'mithril';
import { isArray } from 'underscore';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';

export function ArticleComponent({ attrs }) {
    const listeners = [];

    const article = {
        id: 'a362d72d-c3d4-4093-93f9-e9b5ede952e5',
        title: 'Förstå skillnaderna mellan WCAG 2.2 och 3.0',
        description: 'Två nya versioner av Web Content Accessibility Guidelines (WCAG) är på väg. Här är en titt på skillnaderna mellan WCAG 2.2 och 3.0.',
        image: 'https://www.w3.org/blog/wp-content/uploads/2018/09/wcag_blog-1.png',
        url: ''
    };

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <Card class="article-card">
                    <div slot="image"><img src={article.image} /></div>
                    <div slot="header">
                        <h4>{article.title}</h4>
                        <h6>12 December 2021 - 7 min läsning</h6>
                        <div><div class="bg-success">webbtillgänglighet</div></div>
                    </div>
                    <div slot="content">{article.description}</div>
                    <div slot="footer">
                        <button class="btn btn-primary float-right"
                            onclick={() => Bus.emitLocal('ShowArticle', article)}>
                            OPEN
                        </button>
                    </div>
                </Card>
            );
        }
    };
}
