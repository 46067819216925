import m from 'mithril';

export function Options() {
    const getColor = (options, data) => {
        const option = options.find(option => option.option.toUpperCase() === data.toUpperCase());
        return option && option.colorType !== undefined ? option.colorType : '';
    };
    return {
        view({ attrs }) {
            const { options, data } = attrs;
            return (
                <div class={`main-table-chip color-option option-${getColor(options, data)}`}>
                    <div class="main-table-chip transbox" >
                        <p>{data}</p>
                    </div>
                </div>
            );
        }
    };
}

export function OptionsInput() {
    return {
        view({ attrs }) {
            const { cellId, data, save, options, disabled } = attrs;
            return (
                <select id={`cell-${cellId}`} class="form-select" value={data} oninput={e => save(e, cellId)} disabled={disabled} >
                    {options.map(option => <option key={option.id}>{option.option}</option>)}
                </select>
            );
        }
    };
}
