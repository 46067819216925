import m from 'mithril';

export const Loading = {
    view({ attrs }) {
        return (
            <div class={`panel loading-container ${attrs.class || ''}`}>
                <div class="loading loading-lg"><div class="gears"></div></div>
            </div>
        );
    }
};
