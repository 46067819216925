import m from 'mithril';

export const Undo = {
    view({ attrs }) {
        return (
            <span {...attrs}>
                <svg style="width: 100%;" viewbox="0 0 18 18">
                    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                </svg>
            </span>
        );
    }
};
