import m from 'mithril';
import { Modal } from 'common/components/modal';
import { _ } from 'common/utils/localization';
import './main.scss';

export function ConfirmDeletion({ attrs }) {
    const { modalIsOpen, confirmEvent } = attrs;
    const confirm = (e) => {
        e.stopPropagation();
        confirmEvent();
        modalIsOpen(false);
    };
    return {
        view() {
            const { text } = attrs;
            return (
                <Modal class="modal-sm confirm-deletion-modal" active={modalIsOpen} title={(<div>{_`Confirm Delete`}</div>)}>
                    <div slot="content">
                        {text}
                    </div>
                    <div class="confirm-buttons" slot="footer">
                        <button class="cancel-button tooltip mb-2" data-tooltip={_`Cancel`} onclick={() => modalIsOpen(false)} >
                            <i class="icon icon-cross" />
                        </button>
                        <button class="save-button tooltip ml-2 mb-2" data-tooltip={_`Confirm`} onclick={confirm} >
                            <i class="icon icon-check" />
                        </button>
                    </div>
                </Modal>
            );
        }
    };
}
