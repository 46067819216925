import m from 'mithril';
import { negativeRandomNumber } from 'common/utils/uuid';

export function AnchortagMenuComponent({ attrs }) {
    const { component, saveComponent } = attrs;
    const listeners = [];

    const addAnchortag = (e, anchortags) => {
        e.preventDefault();
        anchortags.push({
            id: negativeRandomNumber(),
            title: '',
            tagId: 0
        });
        saveComponent(anchortags, component.id, 'anchortags');
    };

    const saveTitle = (e, anchortags, anchortagId) => {
        e.preventDefault();
        anchortags.find(x => x.id === anchortagId).title = e.target.value;
        saveComponent(anchortags, component.id, 'anchortags');
    };

    const saveId = (e, anchortags, anchortagId) => {
        anchortags.find(x => x.id === anchortagId).tagId = parseInt(e.target.value, 10);
        saveComponent(anchortags, component.id, 'anchortags');
    };

    const scroll = (e, elements, id) => {
        e.preventDefault();
        if (parseInt(id, 10)) {
            try {
                elements.find(x => x.id === parseInt(id, 10)).element.scrollIntoView();
            } catch (err) {
                console.error('could not find element', err);
            }
        } else {
            console.error('id was not an int', id);
        }
    };

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { component, inEditMode, elements } = attrs;
            if (!component) {
                return <div>loading</div>;
            }
            if (!inEditMode) {
                return (
                    <ul class="nav">
                        {component.anchortags.map(anchortag => (
                            <li key={`anchor-${anchortag.tagId}`} class="nav-item">
                                <a onclick={e => scroll(e, elements.value, anchortag.tagId)}>{anchortag.title}</a>
                            </li>
                        ))}
                    </ul>
                );
            }
            return (
                <div>
                    <div class="text-right">
                        <button class="btn btn-primary" onclick={e => addAnchortag(e, component.anchortags)}>
                            <i class="icon icon-plus"></i>
                        </button>
                    </div>
                    {
                        component.anchortags.map((item, index) => (
                            <div key={item.id}>
                                <div class="form-group">
                                    <label class="form-label" for={`title-${item.id}`}>Title</label>
                                    <input class="form-input" type="text" id={`title-${item.id}`} placeholder="Title"
                                        value={item.title} oninput={e => saveTitle(e, component.anchortags, item.id)} />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for={`id-${item.id}`}>Id</label>
                                    <input class="form-input" type="number" id={`id-${item.id}`}
                                        value={item.tagId} oninput={e => saveId(e, component.anchortags, item.id)} />
                                </div>
                                {component.anchortags.length > (index + 1) ? (
                                    <div class="divider"></div>
                                ) : null}
                            </div>
                        ))
                    }
                </div>
            );
        }
    };
}
