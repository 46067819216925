import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';
import { ProfileInformation } from './profile-information';
import { ProfilePassword } from './profile-password';
import { ProfileSettings } from './profile-settings';

import './main.scss';

export function Profile() {
    const listeners = [];

    const account = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .map((value, oldValue) => ({ ...oldValue, ...value }))
        .each(m.redraw);

    listeners.push(Bus.observe('Account', account));

    return {
        oncreate() {
            Bus.secure('GetAccount');
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            if (!account.value) {
                return <Loading class="loading loading-lg" />;
            }
            return (
                <div class="content">
                    <h4>{_`Account Overview`}</h4>
                    <div class="columns">
                        <ProfileInformation class="column col-5 profile-card" account={account.value} save={account} />
                        <ProfilePassword class="column col-5 profile-card" />
                        <ProfileSettings class="column col-11 profile-card" account={account.value} save={account} />
                    </div>
                </div>
            );
        }
    };
}
