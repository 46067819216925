import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { _ } from 'common/utils/localization';
import { negativeRandomNumber } from 'common/utils/uuid';
import { ColorPopover } from './color-popover';

export function ColorList({ attrs }) {
    const { header } = attrs;

    const newOption = new Observable('')
        .filter((value, oldValue) => (oldValue !== value));

    const selectedColor = new Observable(1)
        .filter((value, oldValue) => (oldValue !== value))
        .map(value => parseInt(value, 10));

    const addOption = (e) => {
        e.preventDefault();
        if (newOption.value.trim() !== '') {
            header.options.push({ id: negativeRandomNumber(), option: newOption.value, colorType: selectedColor.value });
            newOption('');
        }
    };

    const removeOption = (e, index) => {
        e.preventDefault();
        header.options.splice(index, 1);
    };

    return {
        view({ attrs }) {
            const { header } = attrs;
            return (
                <ul class="ml-0">
                    {header.options.map((option, i) => (
                        <li key={i}>
                            <div class="form-group d-flex">
                                <input class="form-input" value={option.option} oninput={e => option.option = e.target.value} />
                                <ColorPopover chosenColor={option.colorType} clickEvent={e => option.colorType = parseInt(e.target.value, 10)}
                                    alignment='left' />
                                <button class="tooltip remove-option-button" data-tooltip={_`Remove Option`}
                                    onclick={e => removeOption(e, i)}>
                                    <i class="icon icon-cross" />
                                </button>
                            </div>
                        </li>
                    ))
                    }
                    <li class="d-flex">
                        <input class="form-input" required value={newOption.value} oninput={e => newOption(e.target.value)} />
                        <ColorPopover chosenColor={selectedColor.value} clickEvent={e => selectedColor(e.target.value)} alignment='left'/>
                        <button class="add-option-button" onclick={addOption}>{_`Add Option`}</button>
                    </li>
                </ul>
            );
        }
    };
}
