import m from 'mithril';
import { Card } from 'common/components/card';

const colorOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export function ColorPopover() {
    return {
        view({ attrs }) {
            const { chosenColor, clickEvent, alignment } = attrs;
            return (
                <div class={`popover popover-small ${`popover-${alignment}` || ''}`}>
                    <button class={`btn color-option option-${chosenColor} color-selection-button`} />
                    <div class="popover-container">
                        <Card>
                            {colorOptions.map(color => (
                                <button key={color} value={color} onclick={clickEvent}
                                    class={`popover-color-chip color-option option-${color} ${color === chosenColor ? 'selected-color' : ''}`} />
                            ))}
                        </Card>
                    </div>
                </div>
            );
        }
    };
}
