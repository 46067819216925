import m from 'mithril';
import { _ } from 'common/utils/localization';
import { Countryflags } from 'common/utils/country-flags';
import { Bus } from 'common/utils/bus';
import { Observable } from 'common/utils/observable';
import { ChangeLanguage } from './change-language';
import { Logo } from '../logo';
import './main.scss';

export function Menu() {
    const listeners = [];
    const menuItems = [];
    const companyName = new Observable('');
    listeners.push(Bus.listen('CompanyName', companyName));
    const customization = new Observable({});
    listeners.push(Bus.listen('CompanyCustomization', customization));
    listeners.push(Bus.listen('SetMenuData', (menuData) => {
        menuItems.length = 0;
        menuData.forEach(item => menuItems.push(item));
        menuItems.push({ name: _`Logout`, clickFunction: () => Bus.emit('Logout') });
        Bus.emitLocal('Redraw');
    }));
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { homeComponent } = attrs;
            const flag = Countryflags[_.locale];
            return (
                <header class="navbar columns"
                    style={`
                ${customization.value && customization.value.navbarTextColor && customization.value.navbarTextColor.trim() !== ''
                            ? `color: ${customization.value.navbarTextColor} !important;` : ''}
                ${customization.value && customization.value.navBarColor && customization.value.navBarColor.trim() !== ''
                            ? `background-color: ${customization.value.navBarColor} !important;` : ''}
                `}
                >
                    {/* {customization.value && customization.value.logoURL && customization.value.logoURL.trim() !== ''
                        ? (
                            <div class="col-2 columns">
                                <img class="company-logo col-2"
                                    src={`${window.location.href}file/downloadcompanylogofile/${customization.value.logoURL}`}
                                    onclick={e => Bus.emitLocal('ChangeView', homeComponent)} />
                                <div class="col-auto logo-text"
                                    onclick={e => Bus.emitLocal('ChangeView', homeComponent)}>
                                    <p class="logo-text-primary"
                                        style={`
                                                ${customization.value && customization.value.navbarTextColor && customization.value.navbarTextColor.trim() !== ''
                                                ? `color: ${customization.value.navbarTextColor} !important;` : ''}
                                            `}
                                    >
                                        {companyName.value}
                                    </p>
                                    <p class="logo-text-secondary"
                                        style={`
                                                ${customization.value && customization.value.navbarTextColor && customization.value.navbarTextColor.trim() !== ''
                                                ? `color: ${customization.value.navbarTextColor} !important;` : ''}
                                            `}>
                                        DUCTUM
                                    </p>
                                </div>
                            </div>
                            // <div class="col-2 columns">
                            //     <img class="col-2" src={`${window.location.href}file/downloadcompanylogofile/${customization.value.logoURL}`}
                            //         alt="logga">
                            //     </img>
                            // </div>
                        ) : <Logo homeComponent={homeComponent} />
                    } */}
                    <Logo homeComponent={homeComponent} />
                    <div class="column col-10 move-left columns">
                        <div class="hide-lg column col-11">
                            <div class="menu-items col-ml-auto">
                                <MenuData list={menuItems} class="text-right" flag={flag} customization={customization.value} />
                            </div>
                        </div>
                        <div class="show-lg column col-11 text-right">
                            <div class="dropdown dropdown-right">
                                <a href="#" class="btn btn-link dropdown-toggle" tabindex="0">
                                    <i class="icon icon-menu" />
                                </a>
                                <ul class="menu text-center">
                                    <MenuData list={menuItems} class='' flag={flag} customization={customization.value} />
                                </ul>
                            </div>
                        </div>
                        <div class="change-lang column col-1">
                            <ChangeLanguage flag={attrs.flag} />
                        </div>
                    </div>
                </header>
            );
        }
    };
}

function MenuData() {
    return {
        view({ attrs }) {
            const { customization } = attrs;
            return (
                <div class={attrs.class}>
                    {attrs.list.map((item, i) => (
                        <div key={item.name} class={attrs.class !== '' ? 'd-inline-block' : ''}>
                            <button
                                class={`btn btn-link menu-button ${item.class}`}
                                onclick={ev => item.clickFunction(ev)}
                                style={`
                                    ${customization && customization.navbarTextColor && customization.navbarTextColor.trim() !== ''
                                        ? `color: ${customization.navbarTextColor} !important;` : ''}
                                    `}>
                                {display(item)}
                            </button>
                            {attrs.class === '' && i !== attrs.list.length - 1 ? <div class="divider"></div> : null}
                        </div>
                    ))}
                </div>
            );
        }
    };
}

function display(item) {
    if (item.display === undefined) {
        return item.name;
    }
    if (typeof item.display === 'function') {
        return item.display();
    }
    return item.display;
}
