import m from 'mithril';
import { Observable } from 'common/utils/observable';

export function InputCompanyDataField({ attrs }) {
    const { company, property, subproperty, label, isEditable } = attrs;
    const save = (e) => {
        if (subproperty) {
            Object.keys(company.value).forEach((key) => {
                if (key === property) {
                    const newProperty = { [subproperty]: e.target.value };
                    attrs.save({ [property]: { ...company.value[key], ...newProperty } });
                }
            });
        } else {
            attrs.save({ [property]: e.target.value });
        }
    };
    return {
        view({ attrs }) {
            const { data, pattern, type, required, title } = attrs;
            const attributeClass = attrs.class !== undefined ? `${attrs.class} ` : '';
            return (
                <div class={isEditable.value ? `${attributeClass}label-and-input-edit` : `${attributeClass}label-and-input`}>
                    <div class="label-name">{label}</div>
                    {isEditable.value
                        ? <input class={`${attributeClass}form-input`} type={type || 'text'} pattern={pattern} value={data}
                            required={required} oninput={save} title={title} />
                        : <p class="datafield">{data} </p>
                    }
                </div>
            );
        }
    };
}

export function InputCompanyColorDataField({ attrs }) {
    const { company, property, subproperty, label, isEditable } = attrs;
    const save = (e) => {
        if (subproperty) {
            Object.keys(company.value).forEach((key) => {
                if (key === property) {
                    const newProperty = { [subproperty]: e.target.value };
                    attrs.save({ [property]: { ...company.value[key], ...newProperty } });
                }
            });
        } else {
            attrs.save({ [property]: e.target.value });
        }
    };
    return {
        view({ attrs }) {
            const { data, pattern, type, required, title } = attrs;
            const attributeClass = attrs.class !== undefined ? `${attrs.class} ` : '';
            return (
                <div class={isEditable.value ? `${attributeClass}label-and-input-color-edit` : `${attributeClass}label-and-input-color`}>
                    <div class="label-name">{label}</div>
                    <div class="color-example-box" style={`background-color: ${data};`}></div>
                    {isEditable.value
                        ? <input class={`${attributeClass}form-input`} type={type || 'text'} pattern={pattern} value={data}
                            required={required} oninput={save} title={title} />
                        : <p class="datafield">{data} </p>
                    }
                </div>
            );
        }
    };
}

export function InputCompanyImageDataField({ attrs }) {
    const { company, property, subproperty, isEditable } = attrs;
    const save = (value) => {
        if (subproperty) {
            Object.keys(company.value).forEach((key) => {
                if (key === property) {
                    const newProperty = { [subproperty]: value };
                    attrs.save({ [property]: { ...company.value[key], ...newProperty } });
                }
            });
        } else {
            attrs.save({ [property]: value });
        }
    };
    const uploadFiles = async (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        const url = `${window.location.href}file/uploadcompanylogofile`;

        const resp = await fetch(url, {
            method: 'POST',
            body: formData,
        });
        const response = await resp.json();

        save(response[0]);
    };
    const files = new Observable(null)
        .each(value => uploadFiles(value))
        .each(() => m.redraw);
    const options = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*';
    return {
        view({ attrs }) {
            const { data, label, width, height } = attrs;
            let fileName = '';
            fileName = data.substring(data.lastIndexOf('/') + 1);
            fileName = fileName.substring(fileName.lastIndexOf('\\') + 1);
            return (
                <div class={isEditable.value ? 'label-and-input-edit' : 'label-and-input'}>
                    <div class="label-name">{label}</div>
                    <label class="input-type-file-label" for={`company-${label}`}>
                        {files.value !== null ? files.value[0].name : fileName.trim() !== '' ? fileName : 'Upload a file:'}
                        <i class="icon icon-upload"></i>
                    </label>
                    <input type="file" id={`company-${label}`} name="files" size="1" accept={options}
                        oninput={e => files([...e.srcElement.files])} class="input-type-file" />
                    {
                        fileName !== ''
                            ? <img src={`${window.location.href}file/downloadcompanylogofile/${data}`} width={width} height={height}/>
                            : null
                    }
                </div>
            );
        }
    };
}
