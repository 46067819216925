import debug from 'debug';

const Log = debug('{observable}');

export class Observable {
    constructor(value, factory = () => true) {
        this._subscribers = [];
        this._valueMappers = [];
        this._mappers = [];
        this._filters = [];
        this._afterFilters = [];
        this._value = value;
        const res = (value) => {
            if (value !== undefined) {
                const filter = this._filters.reduce((current, filter) => (current && filter(value, this._value)), true);
                if (filter) {
                    const oldValue = this._value;
                    const newValue = this._mappers.reduce((current, map) => map(current, oldValue), value);
                    const filter = this._afterFilters.reduce((current, filter) => (current && filter(newValue, this._value)), true);
                    if (filter) {
                        this._value = newValue;
                        res.notify(this._value, oldValue);
                    }
                }
            }
            return res.value;
        };
        factory(this, res);
        this.defineProperty(res, 'value', { get: () => this.value(), enumerable: true });
        this.defineProperty(res, 'isObservable', { value: true, enumerable: true });
        this.defineProperty(res, 'subscribe', { value: (...args) => this.subscribe(...args), enumerable: true });
        this.defineProperty(res, 'map', { value: (...args) => this.map(res, ...args), enumerable: true });
        this.defineProperty(res, 'each', { value: (...args) => this.each(res, ...args), enumerable: true });
        this.defineProperty(res, 'filter', { value: (...args) => this.filter(res, ...args), enumerable: true });
        this.defineProperty(res, 'toString', { value: () => this.toString() });
        this.defineProperty(res, 'notify', { value: (...args) => this.notify(...args) });
        return res;
    }

    defineProperty(object, property, attributes) {
        if (!object.hasOwnProperty(property)) {
            Object.defineProperty(object, property, attributes);
        }
        return this;
    }

    value() {
        return this._valueMappers.reduce((current, map) => map(current), this._value);
    }

    subscribe(observer) {
        if (typeof observer === 'function') {
            this._subscribers.push(observer);
            return () => this._subscribers.splice(this._subscribers.indexOf(observer), 1);
        }
        return () => {};
    }

    map(res, map, forValue = false) {
        if (forValue) {
            this._valueMappers.push(map);
        } else {
            this._mappers.push(map);
        }
        return res;
    }

    each(res, listener) {
        this._subscribers.push(listener);
        return res;
    }

    filter(res, filter, after = false) {
        if (after) {
            this._afterFilters.push(filter);
        } else {
            this._filters.push(filter);
        }
        return res;
    }

    notify(value, oldValue) {
        if (value === undefined) {
            // eslint-disable-next-line no-param-reassign
            value = this._value;
        }
        if (oldValue === undefined) {
            // eslint-disable-next-line no-param-reassign
            oldValue = this._value;
        }
        this._subscribers.filter(observer => typeof observer === 'function').forEach(observer => observer(value, oldValue));
        return this;
    }

    toString() {
        if (this._value == null) {
            return '';
        }
        return this._value.toString();
    }
}

Observable.unwrap = function (observable, map = item => item, key = null) {
    if (!observable) {
        return observable;
    }
    Log('unwrap %o %o', observable, observable.isObservable);
    if (observable.isObservable) {
        Log('Observable');
        return map(observable.value, key);
    }
    if (observable && typeof observable === 'object') {
        return Object.keys(observable).reduce((result, key) => {
            result[key] = Observable.unwrap(observable[key], map, key);
            return result;
        }, Array.isArray(observable) ? [] : {});
    }
    return observable;
};
