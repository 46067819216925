import m from 'mithril';
import { MultiSelect } from 'common/components/multi-select/index';

export function EditUserDataField({ attrs }) {
    const { property, type, save } = attrs;
    const saveProperty = (e) => {
        e.preventDefault();
        save({ [property]: type === 'select' ? parseInt(e.target.value, 10) : e.target.value });
    };

    return {
        view({ attrs }) {
            const { label, type, multiple, options, data, required, pattern, title } = attrs;
            return (
                <div class="label-and-input">
                    <p class="label-name"> {label} </p>
                    { type === 'select'
                        ? multiple ? (
                            <MultiSelect options={options.value} save={options} />
                        ) : (
                            <select class="form-select" required={required} value={data} onchange={saveProperty}>
                                {options.map((type, i) => <option value={type.id} key={i}>{type.name}</option>)}
                            </select>
                        ) : (
                            <input type={type || 'text'} class={`form-${type === 'checkbox' ? type : 'input'}`}
                                defaultValue={data} required={required} value={data || null} pattern={pattern} oninput={saveProperty}
                                title={title}/>
                        )}
                </div>
            );
        }
    };
}

export function DisplayUserDataField() {
    return {
        view({ attrs }) {
            const { label, data } = attrs;
            return (
                <div class="label-and-input">
                    <p class="label-name"> {label} </p>
                    <p class="datafield">{data}</p>
                </div>
            );
        }
    };
}

export function EditUserPasswordField({ attrs }) {
    const { save, property } = attrs;
    const saveProperty = (e) => {
        if (property === undefined) {
            save(e.target.value);
        } else {
            save({ [property]: e.target.value });
        }
    };
    return {
        view({ attrs }) {
            const { field, label, data, required } = attrs;
            return (
                <div class={`label-and-input ${attrs.class}`}>
                    <p class="label-name">{label}</p>
                    <input autocomplete="off" type="password" class="form-input" required={required} value={data} id={field} oninput={saveProperty} />
                </div>
            );
        }
    };
}
