import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { compareObjects } from 'common/utils/object-utils';

import './main.scss';

export function Support() {
    const listeners = [];

    return {
        oncreate() {
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            return (
                <div class="content">
                    <h2>{_`Support`}</h2>
                    <div class="columns body-content">
                        <div class="column col-7">
                            <div class="accordion">
                                <input type="checkbox" id="accordion-1" name="accordion-checkbox" hidden />
                                <label class="accordion-header" for="accordion-1">
                                    <i class="icon icon-arrow-right mr-1"></i>
                                    Question 1
                                </label>
                                <div class="accordion-body">
                                    Answer 1
                                </div>
                            </div>
                            <div class="accordion">
                                <input type="checkbox" id="accordion-2" name="accordion-checkbox" hidden />
                                <label class="accordion-header" for="accordion-2">
                                    <i class="icon icon-arrow-right mr-1"></i>
                                    Question 2
                                </label>
                                <div class="accordion-body">
                                    Answer 2
                                </div>
                            </div>
                            <div class="accordion">
                                <input type="checkbox" id="accordion-3" name="accordion-checkbox" hidden />
                                <label class="accordion-header" for="accordion-3">
                                    <i class="icon icon-arrow-right mr-1"></i>
                                    Question 3
                                </label>
                                <div class="accordion-body">
                                    Answer 3
                                </div>
                            </div>
                            <div class="accordion">
                                <input type="checkbox" id="accordion-4" name="accordion-checkbox" hidden />
                                <label class="accordion-header" for="accordion-4">
                                    <i class="icon icon-arrow-right mr-1"></i>
                                    Question 4
                                </label>
                                <div class="accordion-body">
                                    Answer 4
                                </div>
                            </div>
                        </div>
                        <div class="column col-1 divider-vert"></div>
                        <div class="column col-4">
                            <div class="contanct-info">
                                <label>{_`If you have any problems and want to talk to us`}</label>
                                <button class="btn btn-success" onclick={() => window.Tawk_API.toggle()}>{_`CLICK ME`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
}
