import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Observable } from 'common/utils/observable';
import { Card } from 'common/components/card';
// eslint-disable-next-line import/no-cycle
import { Board } from './board';

// Demo not working as intended
export function Topic() {
    const listeners = [];

    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { changeContent } = attrs;
            return (
                <div class="columns forum-topic-block">
                    <Card class="column col-12" onclick={() => changeContent(Board)}>
                        <div slot="header"><h6>Webbtillgänglighet</h6></div>
                        <div slot="content">
                            <p class="body-text">{_`Active threads`}: 25</p>
                            <p class="body-text">{_`Posts last month`}: 372</p>
                            <p class="body-text last">{_`Latest thread`}: Skapa en bra användarupplevelse för synskadade</p>
                            <div class="footer-text">
                                {_`Latest post`}: 2021-12-01 14:34
                            </div>
                        </div>
                    </Card>
                    <Card class="column col-12">
                        <div slot="header"><h6>Go Sustainable</h6></div>
                        <div slot="content">
                            <p class="body-text">{_`Active threads`}: 42</p>
                            <p class="body-text">{_`Posts last month`}: 631</p>
                            <p class="body-text last">{_`Latest thread`}: Att arbeta hållbart på en lokal nivå</p>
                            <div class="footer-text">
                                {_`Latest post`}: 2021-12-01 11:21
                            </div>
                        </div>
                    </Card>
                    <Card class="column col-12">
                        <div slot="header"><h6>Ductum</h6></div>
                        <div slot="content">
                            <p class="body-text">{_`Active threads`}: 4</p>
                            <p class="body-text">{_`Posts last month`}: 42</p>
                            <p class="body-text last">{_`Latest thread`}: Förbättring's förslag till Ductum</p>
                            <div class="footer-text">
                                {_`Latest post`}: 2021-11-29 10:02
                            </div>
                        </div>
                    </Card>
                </div>
            );
        }
    };
}
