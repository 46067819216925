import m from 'mithril';
import { isArray } from 'underscore';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Observable } from 'common/utils/observable';
import { Loading } from 'common/components/loading';

export function ArticlesComponent({ attrs }) {
    const listeners = [];
    let timer = null;

    const listOfArticles = [
        'https://computersweden.idg.se/2.2683/1.715348/skarpning-arbetsformedlingen',
        'https://www.mynewsdesk.com/se/digg-myndigheten-foer-digital-foervaltning/pressreleases/nu-gaeller-lagen-om-tillgaenglighet-alla-offentliga-webbplatser-3036261',
        'https://www.dagenssamhalle.se/offentlig-ekonomi/upphandling/har-ar-kommunerna-som-ar-bast-pa-webben/'
    ];

    const openIndex = new Observable('')
        .map((value, oldValue) => (value === oldValue ? '' : value))
        .each(m.redraw);

    const openAccordion = (id) => {
        openIndex(id);
        if (timer) {
            clearTimeout(timer);
        }
        // eslint-disable-next-line no-use-before-define
        timer = setTimeout(rotateAccordion, 5000);
    };

    const scrapedArticles = new Observable()
        .filter((value, oldValue) => value !== oldValue)
        .each(val => openAccordion(val[0].id))
        .each(m.redraw);

    listeners.push(Bus.observe('ArticleMeta', (articles) => {
        const articlez = [
            {
                id: 'a362d72d-c3d4-4093-93f9-e9b5ede952e5',
                title: 'Förstå skillnaderna mellan WCAG 2.2 och 3.0',
                description: 'Två nya versioner av Web Content Accessibility Guidelines (WCAG) är på väg. Här är en titt på skillnaderna mellan WCAG 2.2 och 3.0.',
                image: 'https://www.w3.org/blog/wp-content/uploads/2018/09/wcag_blog-1.png',
                url: ''
                // url: `${window.location.href}${window.location.href.charAt(window.location.href.length - 1) === '/' ? '' : '/'}article/10`
            },
            {
                id: 'b362d72d-c3d4-4093-93f9-e9b5eve952e5',
                title: 'Ordlista över digital tillgänglighet',
                description: 'Här finner du en ordlista som innehåller definitioner av olika begrepp som används inom digital tillgänglighet. Vissa av begreppen kan ha andra betydelser i andra sammanhang.',
                image: 'https://images.pexels.com/photos/415071/pexels-photo-415071.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                url: ''
                // url: `${window.location.href}${window.location.href.charAt(window.location.href.length - 1) === '/' ? '' : '/'}article/20`
            }
        ];
        articles.forEach(item => articlez.push(item));
        scrapedArticles(articlez);
    }));

    const rotateAccordion = () => {
        const ids = scrapedArticles.value.map(x => x.id);
        const nextIndex = ids.indexOf(openIndex.value) + 1;
        if (nextIndex >= ids.length) {
            openAccordion(ids[0]);
        } else {
            openAccordion(ids[nextIndex]);
        }
    };

    return {
        oncreate() {
            Bus.secure('GetArticleData', listOfArticles);
        },
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view() {
            if (scrapedArticles.value === null || !isArray(scrapedArticles.value)) {
                return <Loading />;
            }
            return (
                <div>
                    {scrapedArticles.value.map(article => [
                        <div key={article.id} class="accordion">
                            <input id={`accordion-${article.id}`} type="radio" name="accordion-radio" hidden
                                onclick={() => openAccordion(article.id)} checked={article.id === openIndex.value} />
                            <label class="accordion-header c-hand article-title" for={`accordion-${article.id}`}>
                                <i class="icon icon-arrow-right mr-1"></i>{article.title}
                            </label>
                            <div class="accordion-body columns">
                                <img class="article-img column col-12" src={article.image} />
                                <p class="article-body column col-10">{article.description}</p>
                                <button class="btn btn-small btn-primary article-button column col-2"
                                    onclick={() => (article.url === '' ? Bus.emitLocal('ShowArticle', article) : window.open(article.url, '_blank'))}>
                                    {_`Open`}
                                </button>
                            </div>
                        </div>
                    ])}
                </div>
            );
        }
    };
}
