import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { cloneObject } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Modal } from 'common/components/modal';
import { ModalCellList } from './modal-cell-list';
import { tableSorting } from '../extra';
import './main.scss';

export function RowModal({ attrs }) {
    const listeners = [];
    const { save } = attrs;

    const table = new Observable(attrs.table)
        .filter((value, oldValue) => (oldValue !== value));

    const modalIsOpen = new Observable(false)
        .filter((value, oldValue) => (oldValue !== value && typeof value === 'boolean'))
        .each(m.redraw);

    const cellList = new Observable(table.value.cells.filter(cell => cell.row === 0))
        .map(value => cloneObject(value))
        .each(value => (value.length !== 0 ? modalIsOpen(true) : null))
        .each(m.redraw);

    const selectedRow = attrs.selectedRow
        .each(value => cellList(table.value.cells.filter(cell => cell.row === value)))
        .each(m.redraw);

    const saveData = () => {
        const tempTable = cloneObject(table.value);
        tempTable.cells = tempTable.cells.map(obj => cellList.value.find(o => o.id === obj.id) || obj);
        tempTable.cells.sort(tableSorting);

        save(tempTable);
        modalIsOpen(false);
    };
    return {
        onremove() {
            listeners.forEach(dispose => dispose());
        },
        view({ attrs }) {
            const { canAddDelete, inEditMode } = attrs;
            table(attrs.table);
            return (
                <Modal class="modal-lg table-modal" active={modalIsOpen}
                    title={(<div class="table-view header text-center">{_`Row`} - {selectedRow.value + 1}</div>)}>
                    <div class="form-horizontal table-modal-body" slot="content">
                        <ModalCellList canAddDelete={canAddDelete} table={table.value} cellList={cellList} inEditMode={inEditMode} save={saveData} />
                    </div>
                    <div slot="footer">
                        <button class="cancel-button tooltip" data-tooltip={_`Close`} onclick={() => modalIsOpen(false)} >
                            <i class="icon icon-cross" />
                        </button>
                        {inEditMode
                            ? (
                                <button class="save-button tooltip" data-tooltip={_`Save & close`} onclick={saveData} >
                                    <i class="icon icon-check" />
                                </button>
                            )
                            : null}
                    </div>
                </Modal>
            );
        }
    };
}
