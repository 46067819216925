import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';
import { Card } from 'common/components/card';
import { MultiSelect } from './multi-select';

export function NewThread({ attrs }) {
    const listeners = [];
    const subject = new Observable('')
        .map(value => (value.target ? value.target.value : value));
    const recipients = new Observable([], (obs, res) => {
        Object.defineProperty(res, 'empty', {
            get() {
                if (Array.isArray(res.value)) {
                    return res.value.length === 0;
                }
                return !res.value;
            },
            enumerable: true,
        });
    })
        .filter(value => Array.isArray(value))
        .map(value => value.map(item => (item.id ? item.id : item)));
    const message = new Observable('')
        .map(value => (value.target ? value.target.value : value));
    const loading = new Observable(null)
        .map(value => (value ? <Loading class="loading loading-lg" /> : null));
    const submit = (ev) => {
        ev.preventDefault();
        const thread = Observable.unwrap({ subject, recipients, message });
        loading(true);
        Bus.secure('SendThread', thread);
    };
    listeners.push(Bus.observe('ReloadMessages', () => {
        attrs.close();
        m.redraw();
    }));
    return {
        oncreate({ dom }) {
            const focus = dom.querySelector('.focus');
            if (focus) {
                focus.focus();
            }
        },
        onremove() {
            listeners
                .filter(item => typeof item === 'function')
                .forEach(dispose => dispose());
        },
        view({ attrs }) {
            return (
                <Card class="new thread">
                    <form onsubmit={submit}>
                        <h5 class="card-title">
                            <input class="message-input form-input focus" placeholder={_`Subject...`} value={subject.value} oninput={subject} />
                        </h5>
                        <div class="message-recipients form-group">
                            <label>{_`Recipients`}</label>
                            <MultiSelect data={recipients.value} save={recipients} />
                        </div>
                        <div class="message-content">
                            <textarea class="message-input form-input" placeholder={_`Message...`} oninput={message}>{message.value}</textarea>
                        </div>
                        <div class="toolbar">
                            <button class="btn btn-primary tooltip" data-tooltip={_`Send`} disabled={recipients.empty}>
                                <i class="icon icon-arrow-right"></i>
                            </button>
                            <button class="btn tooltip" data-tooltip={_`Cancel`} onclick={attrs.close}><i class="icon icon-cross"></i></button>
                        </div>
                    </form>
                    {loading.value}
                </Card>
            );
        }
    };
}
