import m from 'mithril';
import { Observable } from 'common/utils/observable';
import { cloneObject, compareObjects } from 'common/utils/object-utils';
import { _ } from 'common/utils/localization';
import { Loading } from 'common/components/loading';

export function EditTable({ attrs }) {
    const { update, reference, removeReference } = attrs;

    const headerReference = new Observable(reference)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(value => (value !== 0 ? update(value) : null))
        .each(m.redraw);

    const chosenTable = new Observable(0)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each(m.redraw);

    const tableList = new Observable(null)
        .filter((value, oldValue) => !compareObjects(value, oldValue))
        .each((value) => {
            if (value.length > 0) {
                if (headerReference.value.referenceId !== 0) {
                    const table = value.find(x => x.headers.filter(c => c.id === headerReference.value.referenceId).length > 0);
                    if (table === undefined) {
                        chosenTable(value[0].id);
                    } else {
                        chosenTable(table.id);
                    }
                } else {
                    chosenTable(value[0].id);
                }
            }
        })
        .each(m.redraw);

    const setHeaderData = (e) => {
        const referenceId = e.target ? e.target.value : e;
        const tempReference = cloneObject(headerReference.value);
        tempReference.referenceId = parseInt(referenceId, 10);
        headerReference(tempReference);
    };

    const changeTable = (e) => {
        chosenTable(e.target ? e.target.value : e);
        setHeaderData(tableList.value.find(x => x.id === parseInt(chosenTable.value, 10)).headers[0].id);
    };

    return {
        onupdate() {
            if (chosenTable.value !== 0 && (headerReference.value.referenceId === 0
                || !tableList.value.find(x => x.headers.filter(c => c.id === headerReference.value.referenceId).length > 0))) {
                setHeaderData(tableList.value.find(x => x.id === parseInt(chosenTable.value, 10)).headers[0].id);
            }
        },
        view({ attrs }) {
            tableList(attrs.tables);
            if (!tableList.value) {
                return <Loading />;
            }
            return (
                <div>
                    <div class="divider divider-custom"></div>

                    <div class="form-group">
                        <label class="form-label float-left" for="select-table">{_`Table`}</label>
                        <button class="btn-icon float-right tooltip tooltip-left" data-tooltip={_`Remove data`}
                            onclick={() => removeReference(reference.id)}>
                            <i class="icon icon-minus"></i>
                        </button>

                        <select id="select-table" class="form-select" onchange={changeTable}>
                            {tableList.value.map((table, index) => (table.id === parseInt(chosenTable.value, 10)
                                ? <option key={`table-${index}`} value={table.id} selected>{table.name}</option>
                                : <option key={`table-${index}`} value={table.id}>{table.name}</option>
                            ))}
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="form-label" for="select-column">{_`Column`}</label>
                        <select id="select-column" class="form-select" onchange={e => setHeaderData(e)}>
                            {tableList.value.find(x => x.id === parseInt(chosenTable.value, 10))
                                ? tableList.value.find(x => x.id === parseInt(chosenTable.value, 10)).headers.map((header, index) =>
                                (headerReference.value.referenceId === parseInt(header.id, 10)
                                    ? <option key={`table-${index}`} value={header.id} selected>{header.data}</option>
                                    : <option key={`table-${index}`} value={header.id}>{header.data}</option>
                                )) : null
                            }
                        </select>
                    </div>
                </div>
            );
        }
    };
}
