import m from 'mithril';

export function ProfileDataField({ attrs }) {
    const save = (e) => {
        e.preventDefault();
        attrs.save({ [attrs.property]: e.target.value });
    };
    return {
        view({ attrs }) {
            const { required, data, pattern, type, label, editable, autocomplete, title } = attrs;
            return (
                <div class={`${attrs.class} label-and-input`}>
                    <div class="label-name"> {label} </div>
                    {editable || editable === undefined
                        ? <input class="form-input" autocomplete={autocomplete} required={required} value={data}
                            type={type} pattern={pattern} oninput={save} title={title} />
                        : <p class="datafield">{data}</p>}
                </div>
            );
        }
    };
}
