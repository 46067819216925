import m from 'mithril';
import { Bus } from 'common/utils/bus';
import { _ } from 'common/utils/localization';

export function EmptyList() {
    return {
        view({ attrs }) {
            const { isActive } = attrs;
            return (
                <div class="empty">
                    <p class="empty-title h5">{_`No modules found.`}</p>
                    {isActive.value ? [
                        <p key="subtitle" class="empty-subtitle">
                            {_`Activate an inactive module or create a new one`}
                        </p>,
                        <div key="button" class="empty-action">
                            <button class="btn btn-primary">{_`Create a module`}</button>
                        </div>
                    ] : [
                        <p key="subtitle" class="empty-subtitle">
                            {_`View active modules or create a new one`}
                        </p>,
                        <div key="button" class="empty-action">
                            <button class="btn btn-primary" onclick={() => Bus.secure('GetEmptyModule')}>{_`Create a module`}</button>
                        </div>
                    ]}
                </div>
            );
        }
    };
}
